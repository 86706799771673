export const airportLocations = [
  {
    city: "Warri",
    airport: "Warri Airport",
    country: "Nigeria",
    iata: "QRW",
  },
  {
    city: "Minna",
    airport: "Minna Airport",
    country: "Nigeria",
    iata: "MXJ",
  },
  {
    city: "Makurdi",
    airport: "Makurdi Airport",
    country: "Nigeria",
    iata: "MDI",
  },
  {
    city: "Gombe",
    airport: "Gombe Lawanti International Airport",
    country: "Nigeria",
    iata: "GMO",
  },
  {
    city: "Akure",
    airport: "Akure Airport",
    country: "Nigeria",
    iata: "AKR",
  },
  {
    city: "Owerri",
    airport: "Sam Mbakwe International Cargo Airport",
    country: "Nigeria",
    iata: "QOW",
  },
  {
    city: "Maiduguri",
    airport: "Maiduguri International Airport",
    country: "Nigeria",
    iata: "MIU",
  },
  {
    city: "Katsina",
    airport: "Umar Musa Yaradua Airport",
    country: "Nigeria",
    iata: "DKA",
  },
  {
    city: "Enugu",
    airport: "Akanu Ibiam International Airport",
    country: "Nigeria",
    iata: "ENU",
  },
  {
    city: "Umuleri",
    airport: "Chinụa Achebe International Cargo Passenger Airport, Umuleri",
    country: "Nigeria",
    iata: "ANA",
  },
  {
    city: "Kaduna",
    airport: "Kaduna International Airport.",
    country: "Nigeria",
    iata: "KAD",
  },
  {
    city: "Sokoto",
    airport: "Sultan Abubakar III International Airport Sokoto",
    country: "Nigeria",
    iata: "SKO",
  },
  {
    city: "Asaba",
    airport: "Asaba International Airport",
    country: "Nigeria",
    iata: "ABB",
  },
  {
    city: "Calabar",
    airport: "Margaret Ekpo International Airport",
    country: "Nigeria",
    iata: "CBQ",
  },
  {
    city: "Aalborg",
    airport: "Aalborg Airport",
    country: "Denmark",
    iata: "AAL",
  },
  {
    city: "Aalesund",
    airport: "Ålesund Airport, Vigra",
    country: "Norway",
    iata: "AES",
  },
  {
    city: "Aarhus",
    airport: "Aarhus Airport",
    country: "Denmark",
    iata: "AAR",
  },
  {
    city: "Beijing",
    airport: "Beijing Daxing International Airport",
    country: "China",
    iata: "PKX",
  },
  {
    city: "Abadan",
    airport: "Abadan International Airport",
    country: "Iran",
    iata: "ABD",
  },
  {
    city: "Abbotsford",
    airport: "Abbotsford International",
    country: "Canada (BC)",
    iata: "YXX",
  },
  {
    city: "Abeche",
    airport: "Abeche Airport",
    country: "Chad",
    iata: "AEH",
  },
  {
    city: "Aberdeen",
    airport: "Aberdeen International Airport",
    country: "Scotland, UK",
    iata: "ABZ",
  },
  {
    city: "Aberdeen (SD)",
    airport: " Aberdeen Regional Airport",
    country: "USA",
    iata: "ABR",
  },
  {
    city: "Abidjan",
    airport: "Félix-Houphouët-Boigny International",
    country: "Cote d'Ivoire",
    iata: "ABJ",
  },
  {
    city: "Abilene ",
    airport: " Abilene Regional Airport",
    country: "USA",
    iata: "ABI",
  },
  {
    city: "Abu Dhabi",
    airport: "Abu Dhabi International",
    country: "United Arab Emirates",
    iata: "AUH",
  },
  {
    city: "Abuja",
    airport: "Nnamdi Azikiwe International Airport",
    country: "Nigeria",
    iata: "ABV",
  },
  {
    city: "Abu Rudeis",
    airport: "Abu Rudeis Airport",
    country: "Egypt",
    iata: "AUE",
  },
  {
    city: "Abu Simbel",
    airport: "Abu Simbel Airport",
    country: "Egypt",
    iata: "ABS",
  },
  {
    city: "Acapulco",
    airport: "Acapulco International Airport",
    country: "Mexico",
    iata: "ACA",
  },
  {
    city: "Accra",
    airport: "Kotoka International Airport",
    country: "Ghana",
    iata: "ACC",
  },
  {
    city: "A Coruna (Corunna)",
    airport: "A Coruña Airport",
    country: "Spain",
    iata: "LCG",
  },
  {
    city: "Adana",
    airport: " Adana Sakirpasa Airport",
    country: "Turkey",
    iata: "ADA",
  },
  {
    city: "Addis Ababa",
    airport: "Addis Ababa Bole International Airport",
    country: "Ethiopia",
    iata: "ADD",
  },
  {
    city: "Adelaide",
    airport: "Adelaide Airport",
    country: "Australia",
    iata: "ADL",
  },
  {
    city: "Aden",
    airport: "Aden International Airport",
    country: "Yemen",
    iata: "ADE",
  },
  {
    city: "Adiyaman",
    airport: " Adıyaman Airport",
    country: "Turkey",
    iata: "ADF",
  },
  {
    city: "Adler/Sochi",
    airport: "Adler-Sochi International Airport",
    country: "Russia",
    iata: "AER",
  },
  {
    city: "Agadez",
    airport: " Mano Dayak International Airport",
    country: "Niger",
    iata: "AJY",
  },
  {
    city: "Agadir (Amazigh)",
    airport: " Agadir Massira Airport",
    country: "Morocco",
    iata: "AGA",
  },
  {
    city: "Agana (Hagåtña)",
    airport: "Antonio B. Won Pat International Airport",
    country: "Guam",
    iata: "GUM",
  },
  {
    city: "Aggeneys",
    airport: " Aggeneys Airport",
    country: "South Africa",
    iata: "AGZ",
  },
  {
    city: "Agri (Ağrı)",
    airport: "Agri Airport",
    country: "Turkey",
    iata: "AJI",
  },
  {
    city: "Aguadilla",
    airport: "Rafael Hernández Airport",
    country: "Puerto Rico",
    iata: "BQN",
  },
  {
    city: "Aguascalientes",
    airport: "Aguascalientes International Airport",
    country: "Mexico",
    iata: "AGU",
  },
  {
    city: "Ahmedabad",
    airport: "Ahmedabad International Airport",
    country: "Gujarat, India",
    iata: "AMD",
  },
  {
    city: "Aiyura",
    airport: " Aiyura Airport",
    country: "Papua New Guinea",
    iata: "AYU",
  },
  {
    city: "Ajaccio",
    airport: "Ajaccio Napoleon Bonaparte Airport",
    country: "Corsica, France",
    iata: "AJA",
  },
  {
    city: "Akita",
    airport: "Akita Airport",
    country: "Japan",
    iata: "AXT",
  },
  {
    city: "Akron (OH)",
    airport: " Akron-Canton Regional Airport",
    country: "USA",
    iata: "CAK",
  },
  {
    city: "Akrotiri (British Overseas Territory)",
    airport: "Royal Air Force Akrotiri",
    country: "Cyprus",
    iata: "AKT",
  },
  {
    city: "Al Ain",
    airport: "Al Ain International Airport",
    country: "United Arab Emirates",
    iata: "AAN",
  },
  {
    city: "Al Arish",
    airport: " Arish International Airport",
    country: "Egypt",
    iata: "AAC",
  },
  {
    city: "Al Hoceima",
    airport: " Sharif Al ldrissi Airport",
    country: "Morocco",
    iata: "AHU",
  },
  {
    city: "Albany",
    airport: "Albany Airport",
    country: "Australia",
    iata: "ALH",
  },
  {
    city: "Albany (GA)",
    airport: " Southwest Georgia Regional Airport",
    country: "USA",
    iata: "ABY",
  },
  {
    city: "Albany ",
    airport: "Albany International Airport",
    country: "USA",
    iata: "ALB",
  },
  {
    city: "Albi",
    airport: "Le Sequestre Airport  (GA)",
    country: "France",
    iata: "LBI",
  },
  {
    city: "Albuquerque (NM)",
    airport: "Albuquerque Sunport Airport",
    country: "USA",
    iata: "ABQ",
  },
  {
    city: "Albury",
    airport: "Albury Airport",
    country: "Australia",
    iata: "ABX",
  },
  {
    city: "Alderney",
    airport: " Alderney Airport",
    country: "Channel Islands (UK)",
    iata: "ACI",
  },
  {
    city: "Gerald's",
    airport: "John A. Osborne Airport",
    country: "Monsterrat",
    iata: "MNI",
  },
  {
    city: "Aleppo",
    airport: " Aleppo International Airport",
    country: "Syria",
    iata: "ALP",
  },
  {
    city: "Alexander Bay",
    airport: "Kortdoorn Airport",
    country: "South Africa",
    iata: "ALJ",
  },
  {
    city: "Alexandria",
    airport: "Borg El Arab Airport",
    country: "Egypt",
    iata: "HBE",
  },
  {
    city: "Alexandria",
    airport: "El Nhouza Airport",
    country: "Egypt",
    iata: "ALY",
  },
  {
    city: "Alexandria (LA)",
    airport: "Alexandria International Airport",
    country: "USA",
    iata: "AEX",
  },
  {
    city: "Alexandria (LA)",
    airport: "Esler Field",
    country: "USA",
    iata: "ESF",
  },
  {
    city: "Alfujairah (Fujairah)",
    airport: "Fujairah International Airport",
    country: "United Arab Emirates",
    iata: "FJR",
  },
  {
    city: "Alghero Sassari",
    airport: "Fertilia Airport (Alghero Airport)",
    country: "Sardinia, Italy",
    iata: "AHO",
  },
  {
    city: "Algiers",
    airport: "Houari Boumediene Airport",
    country: "Algeria",
    iata: "ALG",
  },
  {
    city: "Alicante",
    airport: "Alicante-Elche Airport",
    country: "Spain",
    iata: "ALC",
  },
  {
    city: "Alice Springs",
    airport: " Alice Springs Airport",
    country: "Australia",
    iata: "ASP",
  },
  {
    city: "Alldays",
    airport: " Alldays Airport",
    country: "Limpopo, South Africa",
    iata: "ADY",
  },
  {
    city: "Allentown (PA)",
    airport: "Lehigh Valley International Airport",
    country: "USA",
    iata: "ABE",
  },
  {
    city: "Almaty",
    airport: "Almaty International Airport",
    country: "Kazakhstan",
    iata: "ALA",
  },
  {
    city: "Almeria",
    airport: " Almería International Airport",
    country: "Spain",
    iata: "LEI",
  },
  {
    city: "Alta",
    airport: " Alta Airport",
    country: "Norway",
    iata: "ALF",
  },
  {
    city: "Altay",
    airport: "Altay Airport",
    country: "Xinjiang, China",
    iata: "AAT",
  },
  {
    city: "Altenrhein, St. Gallen",
    airport: " People´s Business Airport",
    country: "Switzerland",
    iata: "ACH",
  },
  {
    city: "Altoona (PA)",
    airport: "Altoona-Blair County Airport",
    country: "USA",
    iata: "AOO",
  },
  {
    city: " Altus (OK)",
    airport: "Altus/Quartz Mountain Regional Airport",
    country: "USA",
    iata: "AXS",
  },
  {
    city: "Amami",
    airport: "Amami Airport Amami Island",
    country: "Japan",
    iata: "ASJ",
  },
  {
    city: "Amarillo ",
    airport: "Rick Husband Amarillo Airport",
    country: "USA",
    iata: "AMA",
  },
  {
    city: "Amazon Bay/Deba",
    airport: " Amazon Bay Airport",
    country: "Deba, Papua New Guinea",
    iata: "AZB",
  },
  {
    city: "Amman",
    airport: "Queen Alia International Airport",
    country: "Jordan",
    iata: "AMM",
  },
  {
    city: "Amman",
    airport: "Amman Civil Airport",
    country: "Jordan",
    iata: "ADJ",
  },
  {
    city: "Amritsar",
    airport: "Sri Guru Ram Dass Jee International Airport",
    country: "Punjab, India",
    iata: "ATQ",
  },
  {
    city: "Amsterdam",
    airport: "Amsterdam Airport Schiphol  (Luchthaven Schiphol)",
    country: "Netherlands",
    iata: "AMS",
  },
  {
    city: "Anchorage (AK)",
    airport: "Ted Stevens Anchorage International ",
    country: "USA",
    iata: "ANC",
  },
  {
    city: "Ancona",
    airport: "Ancona Falconara Airport",
    country: "Italy",
    iata: "AOI",
  },
  {
    city: "Andorra La Vella",
    airport: "Heliport",
    country: "Andorra",
    iata: "ALV",
  },
  {
    city: "Anguilla/The Valley",
    airport: "Wallblake Airport",
    country: "Anguilla",
    iata: "AXA",
  },
  {
    city: "Anjouan",
    airport: "Anjouan Airport",
    country: "Comoros (Comores)",
    iata: "AJN",
  },
  {
    city: "Ankara",
    airport: "Metropolitan Area",
    country: "Turkey",
    iata: "ANK",
  },
  {
    city: "Ankara",
    airport: "Ankara Esenboğa Airport",
    country: "Turkey",
    iata: "ESB",
  },
  {
    city: "Annaba",
    airport: "Rabah Bitat Airport (Les Salines Airport)",
    country: "Algeria",
    iata: "AAE",
  },
  {
    city: "Ann Arbor",
    airport: "Ann Arbor Municipal Airport",
    country: "MI, USA",
    iata: "ARB",
  },
  {
    city: "Annecy",
    airport: "Meythet Airport",
    country: "France",
    iata: "NCY",
  },
  {
    city: "Anniston ",
    airport: "Anniston Metropolitan Airport",
    country: "USA",
    iata: "ANB",
  },
  {
    city: "Antalya",
    airport: "Antalya Airport",
    country: "Turkey",
    iata: "AYT",
  },
  {
    city: "Antananarivo",
    airport: "Ivato International Airport",
    country: "Madagascar",
    iata: "TNR",
  },
  {
    city: "Antigua",
    airport: "V.C. Bird International Airport",
    country: "Antigua and Barbuda",
    iata: "ANU",
  },
  {
    city: "Antofagasta",
    airport: "Cerro Moreno International Airport  (es)",
    country: "Chile",
    iata: "ANF",
  },
  {
    city: "Antwerp",
    airport: "Antwerp International Airport",
    country: "Belgium",
    iata: "ANR",
  },
  {
    city: "Aomori",
    airport: "Aomori Airport",
    country: "Japan",
    iata: "AOJ",
  },
  {
    city: "Apia",
    airport: "Faleolo International Airport",
    country: "Samoa",
    iata: "APW",
  },
  {
    city: "Appelton/Neenah/Menasha",
    airport: "Outagamie County Regional Airport",
    country: "WI, USA",
    iata: "ATW",
  },
  {
    city: "Aqaba",
    airport: " King Hussein International Airport",
    country: "Jordan",
    iata: "AQJ",
  },
  {
    city: "Aracaju",
    airport: "Santa Maria Airport",
    country: "Brazil",
    iata: "AJU",
  },
  {
    city: "Arequipa",
    airport: "Rodriguez Ballon International Airport",
    country: "Peru",
    iata: "AQP",
  },
  {
    city: "Arkhangelsk",
    airport: "Talagi Airport",
    country: "Russia",
    iata: "ARH",
  },
  {
    city: "Arusha",
    airport: "Arusha Airport",
    country: "Tanzania",
    iata: "ARK",
  },
  {
    city: "Araxos/Patras",
    airport: " Araxos Airport",
    country: "Greece",
    iata: "GPA",
  },
  {
    city: "Arlit",
    airport: " Arlit Airport",
    country: "Niger",
    iata: "RLT",
  },
  {
    city: "Arrecife/Lanzarote",
    airport: "Lanzarote Airport",
    country: "Spain",
    iata: "ACE",
  },
  {
    city: "Aruba",
    airport: "Queen Beatrix International, Oranjestad",
    country: "Aruba",
    iata: "AUA",
  },
  {
    city: "Asheville",
    airport: "Asheville Regional Airport",
    country: "NC, USA",
    iata: "AVL",
  },
  {
    city: "Ashgabat",
    airport: "Ashgabat International Airport",
    country: "Turkmenistan",
    iata: "ASB",
  },
  {
    city: "Asmara",
    airport: "Asmara International Airport",
    country: "Eritrea",
    iata: "ASM",
  },
  {
    city: "Aspen (CO)",
    airport: "Aspen-Pitkin County Airport ",
    country: "USA",
    iata: "ASE",
  },
  {
    city: "Assiut",
    airport: " Assiut Airport",
    country: "Egypt",
    iata: "ATZ",
  },
  {
    city: "Astana",
    airport: "Astana International Airport",
    country: "Kazakhstan",
    iata: "TSE",
  },
  {
    city: "Asuncion",
    airport: "Asunción International Airport",
    country: "Paraguay",
    iata: "ASU",
  },
  {
    city: "Aswan",
    airport: "Daraw Airport",
    country: "Egypt",
    iata: "ASW",
  },
  {
    city: "Athens",
    airport: "Athens International Airport",
    country: "Greece",
    iata: "ATH",
  },
  {
    city: "Athens",
    airport: "Hellinikon International Airport (closed)",
    country: "Greece",
    iata: "HEW",
  },
  {
    city: "Athens (GA)",
    airport: "Athens-Ben Epps Airport",
    country: "USA",
    iata: "AHN",
  },
  {
    city: "Athens (OH)",
    airport: "Ohio University Airport (Snyder Field)",
    country: "USA",
    iata: "ATO",
  },
  {
    city: "Atlanta (GA)",
    airport: "Hartsfield-Jackson Atlanta International ",
    country: "USA",
    iata: "ATL",
  },
  {
    city: "Atlantic City ",
    airport: "Atlantic City International Airport",
    country: "USA",
    iata: "ACY",
  },
  {
    city: "Attawapiskat",
    airport: "Attawapiskat Airport",
    country: "NT, Canada",
    iata: "YAT",
  },
  {
    city: "Auckland",
    airport: "Auckland International Airport",
    country: "New Zealand",
    iata: "AKL",
  },
  {
    city: "Augsburg",
    airport: "Augsburg Airport",
    country: "Germany",
    iata: "AGB",
  },
  {
    city: "Augusta (GA)",
    airport: "Augusta Regional Airport at Bush Field",
    country: "USA",
    iata: "AGS",
  },
  {
    city: "Augusta (ME)",
    airport: "Augusta State Airport",
    country: "USA",
    iata: "AUG",
  },
  {
    city: "Aurillac",
    airport: "Aurillac Airport",
    country: "France",
    iata: "AUR",
  },
  {
    city: "Austin",
    airport: "Austin-Bergstrom International Airport",
    country: "  USA",
    iata: "AUS",
  },
  {
    city: "Ayawasi",
    airport: "Ayawasi Airport",
    country: "Indonesia",
    iata: "AYW",
  },
  {
    city: "Ayers Rock",
    airport: "Ayers Rock Airport (Connellan)",
    country: "Australia",
    iata: "AYQ",
  },
  {
    city: "Ayr",
    airport: " Ayr Airport",
    country: "Australia",
    iata: "AYR",
  },
  {
    city: "Bacolod",
    airport: "Bacolod–Silay International Airport",
    country: "Philippines",
    iata: "BCD",
  },
  {
    city: "Bacău",
    airport: "Bacău International Airport",
    country: "Romania",
    iata: "BCM",
  },
  {
    city: "Badajoz",
    airport: "Badajoz Airport",
    country: "Spain",
    iata: "BJZ",
  },
  {
    city: "Bagdad",
    airport: "Baghdad International Airport",
    country: "Iraq",
    iata: "BGW",
  },
  {
    city: "Bagdogra",
    airport: "Bagdogra Airport",
    country: "India",
    iata: "IXB",
  },
  {
    city: "Bahamas",
    airport: "Lynden Pindling International Airport ",
    country: "The Bahamas",
    iata: "NAS",
  },
  {
    city: "Bahawalpur",
    airport: " Bahawalpur Airport",
    country: "Pakistan",
    iata: "BHV",
  },
  {
    city: "Bahrain",
    airport: "Bahrain International Airport",
    country: "Bahrain",
    iata: "BAH",
  },
  {
    city: "Bakersfield (CA)",
    airport: " Meadows Field",
    country: "USA",
    iata: "BFL",
  },
  {
    city: "Baku",
    airport: "Baku International Airport",
    country: "Azerbaijan",
    iata: "BAK",
  },
  {
    city: "Balikpapan",
    airport: "Sepinggan Airport",
    country: "Kalimantan, Indonesia",
    iata: "BPN",
  },
  {
    city: "Ballina",
    airport: "Ballina Airport",
    country: "Australia",
    iata: "BNK",
  },
  {
    city: "Baltimore (MD)",
    airport: "Baltimore–Washington International Airport",
    country: "USA",
    iata: "BWI",
  },
  {
    city: "Bamaga",
    airport: "Bamaga Injinoo Airport",
    country: "Australia",
    iata: "ABM",
  },
  {
    city: "Bamako",
    airport: "Bamako-Sénou International Airport",
    country: "Mali",
    iata: "BKO",
  },
  {
    city: "Bambari",
    airport: "Bambari Airport",
    country: "Central African Republic",
    iata: "BBY",
  },
  {
    city: "Banda Aceh",
    airport: "Sultan Iskandar Muda International Airport",
    country: "Indonesia",
    iata: "BTJ",
  },
  {
    city: "Bandar Abbas",
    airport: "Bandar Abbas International Airport  (fa)",
    country: "Iran",
    iata: "BND",
  },
  {
    city: "Bandar Seri Begawan",
    airport: "Brunei International Airport",
    country: "Brunei",
    iata: "BWN",
  },
  {
    city: "Bandung",
    airport: "Husein Sastranegara International Airport",
    country: "Indonesia",
    iata: "BDO",
  },
  {
    city: "Bangalore",
    airport: "Bengaluru International Airport",
    country: "India",
    iata: "BLR",
  },
  {
    city: "Bangassou",
    airport: "Bangassou Airport",
    country: "Central African Republic",
    iata: "BGU",
  },
  {
    city: "Bangkok",
    airport: "Metropolitan Area",
    country: "Thailand",
    iata: "BKK",
  },
  {
    city: "Bangkok",
    airport: "Don Mueang International Airport",
    country: "Thailand",
    iata: "DMK",
  },
  {
    city: "Bangor (ME)",
    airport: "Bangor International Airport",
    country: "USA",
    iata: "BGR",
  },
  {
    city: "Bangui",
    airport: "Bangui M'Poko International Airport",
    country: "Central African Republic",
    iata: "BGF",
  },
  {
    city: "Banja Luka",
    airport: " Banja Luka Airport",
    country: "Bosnia and Herzegovina",
    iata: "BNX",
  },
  {
    city: "Banjarmasin",
    airport: "Syamsudin Noor Airport",
    country: "Kalimantan, Indonesia",
    iata: "BDJ",
  },
  {
    city: "Banjul",
    airport: "Banjul Yundum International Airport",
    country: "The Gambia",
    iata: "BJL",
  },
  {
    city: "Bannu",
    airport: "Bannu Airport",
    country: "Pakistan",
    iata: "BNP",
  },
  {
    city: "Baotou",
    airport: "Baotou Erliban Airport",
    country: "Inner Mongolia, China",
    iata: "BAV",
  },
  {
    city: "Barcelona",
    airport: "Barcelona El Prat Airport",
    country: "Spain",
    iata: "BCN",
  },
  {
    city: "Barcelona",
    airport: "José Antonio Anzoátegui International",
    country: "Venezuela",
    iata: "BLA",
  },
  {
    city: "Bardufoss",
    airport: "Bardufoss Airport",
    country: "Norway",
    iata: "BDU",
  },
  {
    city: "Bari",
    airport: "Puglia Airport",
    country: "Italy",
    iata: "BRI",
  },
  {
    city: "Barisal",
    airport: "Barisal Airport",
    country: "Bangladesh",
    iata: " BZL",
  },
  {
    city: "Baroda (Vadodara)",
    airport: "Vadodara Airport",
    country: "India",
    iata: "BDQ",
  },
  {
    city: "Barra",
    airport: " Barra Airport (Northbay Airport)",
    country: "Scotland, United Kingdom",
    iata: "BRR",
  },
  {
    city: "Barranquilla",
    airport: "Ernesto Cortissoz International Airport",
    country: "Colombia",
    iata: "BAQ",
  },
  {
    city: "Basel",
    airport: "Metropolitan Area",
    country: "Switzerland",
    iata: " BSL",
  },
  {
    city: "Basel",
    airport: "EuroAirport Basel-Mulhouse-Freiburg",
    country: "Switzerland",
    iata: "EAP",
  },
  {
    city: "Basra - (Basrah)",
    airport: " Basrah International Airport",
    country: "Iraq",
    iata: "BSR",
  },
  {
    city: "Basse-Terre",
    airport: "Pointe-à-Pitre International Airport",
    country: "Guadeloupe",
    iata: "PTP",
  },
  {
    city: "Basseterre",
    airport: " Robert L. Bradshaw International Airport",
    country: "Saint Kitts and Nevis",
    iata: "SKB",
  },
  {
    city: "Bastia",
    airport: " Bastia - Poretta Airport",
    country: "France",
    iata: "BIA",
  },
  {
    city: "Batam",
    airport: "Hang Nadim International Airport",
    country: "Riau Islands, Indonesia",
    iata: "BTH",
  },
  {
    city: "Baton Rouge (LA)",
    airport: "Baton Rouge Metropolitan Airport",
    country: "USA",
    iata: "BTR",
  },
  {
    city: "Battambang",
    airport: "Battambang Airport",
    country: "Cambodia",
    iata: "BBM",
  },
  {
    city: "Bauchi",
    airport: "Bauchi State Airport",
    country: "Nigeria",
    iata: "BCU",
  },
  {
    city: "Bayreuth",
    airport: "Bindlacher Berg Airport (GA)",
    country: "Germany",
    iata: "BYU",
  },
  {
    city: "Beaumont/Pt. Arthur ",
    airport: "Jack Brooks Regional Airport",
    country: "USA",
    iata: "BPT",
  },
  {
    city: "Beauvais",
    airport: "Beauvais–Tillé Airport",
    country: "France",
    iata: "BVA",
  },
  {
    city: "Beckley (WV)",
    airport: "Raleigh County Memorial Airport  (GA)",
    country: "USA",
    iata: "BKW",
  },
  {
    city: "Beef Island",
    airport: "Terrance B. Lettsome Airport",
    country: "Virgin Islands (British)",
    iata: "EIS",
  },
  {
    city: "Beihai",
    airport: "Beihai Fucheng Airport",
    country: "Guangxi, China",
    iata: "BHY",
  },
  {
    city: "Beijing",
    airport: "Beijing",
    country: "PR China",
    iata: "PEK",
  },
  {
    city: "Beijing",
    airport: "Beijing Capital International Airport",
    country: "China",
    iata: "BJS",
  },
  {
    city: "Beijing",
    airport:
      "Beijing Daxing International Airport (future airport under construction)",
    country: "China",
    iata: "---",
  },
  {
    city: "Beijing",
    airport: "Beijing Nanyuan Airport",
    country: "China",
    iata: "NAY",
  },
  {
    city: "Beira",
    airport: "Beira Airport",
    country: "Mozambique",
    iata: "BEW",
  },
  {
    city: "Beirut",
    airport: "Beirut Rafic Hariri International Airport",
    country: "Lebanon",
    iata: "BEY",
  },
  {
    city: "Belém",
    airport: " Val de Cans International Airport",
    country: "Brazil",
    iata: "BEL",
  },
  {
    city: "Belfast",
    airport: "Belfast International Airport",
    country: "Northern Ireland, UK",
    iata: "BFS",
  },
  {
    city: "Belfast",
    airport: "George Best Belfast City Airport",
    country: "United Kingdom",
    iata: "BHD",
  },
  {
    city: "Belgaum",
    airport: "Belgaum Airport",
    country: "India",
    iata: "IXG",
  },
  {
    city: "Belgrad (Beograd; capital city)",
    airport: "Belgrade Nikola Tesla International Airport",
    country: "Serbia",
    iata: "BEG",
  },
  {
    city: "Belize City",
    airport: "Philip S.W.Goldson International",
    country: "Belize",
    iata: "BZE",
  },
  {
    city: "Bellingham (WA)",
    airport: "Bellingham International Airport",
    country: "USA",
    iata: "BLI",
  },
  {
    city: "Belmopan",
    airport: "Hector Silva Airstrip",
    country: "Belize",
    iata: "BCV",
  },
  {
    city: "Belo Horizonte",
    airport: "Tancredo Neves International Airport",
    country: "Brazil",
    iata: "CNF",
  },
  {
    city: "Belo Horizonte",
    airport: "Belo Horizonte Pampulha Airport",
    country: "Brazil",
    iata: "PLU",
  },
  {
    city: "Bemidji (MN)",
    airport: "Bemidji Regional Airport",
    country: "USA",
    iata: "BJI",
  },
  {
    city: "Benbecula",
    airport: "Benbecula Airport",
    country: "United Kingdom",
    iata: "BEB",
  },
  {
    city: "Benghazi (Bengasi)",
    airport: "Benina International Airport",
    country: "Libya",
    iata: "BEN",
  },
  {
    city: "Benguela",
    airport: " Benguela Airport",
    country: "Angola",
    iata: "BUG",
  },
  {
    city: "Benin City",
    airport: "Benin City Airport",
    country: "Nigeria",
    iata: "BNI",
  },
  {
    city: "Benton Harbour ",
    airport: "Southwest Michigan Regional Airport  (GA)",
    country: "USA",
    iata: "BEH",
  },
  {
    city: "Berberati",
    airport: "Berberati Airport",
    country: "Central African Republic",
    iata: "BBT",
  },
  {
    city: "Bergamo/Milan",
    airport: "Orio al Serio Airport",
    country: "Italy",
    iata: "BGY",
  },
  {
    city: "Bergen",
    airport: " Bergen Flesland Airport",
    country: "Norway",
    iata: "BGO",
  },
  {
    city: "Bergerac",
    airport: "Bergerac Dordogne Périgord Airport",
    country: "France",
    iata: "EGC",
  },
  {
    city: "Berlin",
    airport: "Metropolitan Area",
    country: "Germany",
    iata: "BER",
  },
  {
    city: "Berlin",
    airport: "Berlin Schönefeld Airport",
    country: "Germany",
    iata: "SXF",
  },
  {
    city: "Berlin",
    airport: "Berlin Tegel Airport",
    country: "Germany",
    iata: "TXL",
  },
  {
    city: "Berlin",
    airport: "Berlin Tempelhof (closed)",
    country: "Germany",
    iata: "THF",
  },
  {
    city: "Bermuda",
    airport: "L.F. Wade International Airport",
    country: "Bermuda",
    iata: "BDA",
  },
  {
    city: "Berne",
    airport: "Bern Airport",
    country: "Switzerland",
    iata: "BRN",
  },
  {
    city: "Bethel (AK)",
    airport: "Bethel Airport",
    country: "USA",
    iata: "BET",
  },
  {
    city: "Bhamo",
    airport: "Bhamo Airport",
    country: "Myanmar",
    iata: "BMO",
  },
  {
    city: "Bharatpur",
    airport: "Bharatpur Airport",
    country: "Nepal",
    iata: "BHR",
  },
  {
    city: "Bhopal",
    airport: "Bhopal Airport",
    country: "India",
    iata: "BHO",
  },
  {
    city: "Bhubaneswar",
    airport: "Biju Patnaik International Airport",
    country: "India",
    iata: "BBI",
  },
  {
    city: "Biarritz",
    airport: "Biarritz - Anglet - Bayonne Airport",
    country: "France",
    iata: "BIQ",
  },
  {
    city: "Bilbao",
    airport: "Bilbao Airport",
    country: "Spain",
    iata: "BIO",
  },
  {
    city: "Billings (MT)",
    airport: "Billings Logan International Airport",
    country: "USA",
    iata: "BIL",
  },
  {
    city: "Billund",
    airport: "Billund Airport",
    country: "Denmark",
    iata: "BLL",
  },
  {
    city: "Bintulu",
    airport: "Bintulu Airport",
    country: "Malaysia",
    iata: "BTU",
  },
  {
    city: "Biraro",
    airport: " Birao Airport",
    country: "Central African Republic",
    iata: "IRO",
  },
  {
    city: "Birmingham",
    airport: "Birmingham Airport",
    country: "United Kingdom",
    iata: "BHX",
  },
  {
    city: "Birmingham ",
    airport: "Birmingham–Shuttlesworth International",
    country: "USA",
    iata: "BHM",
  },
  {
    city: "Bishkek",
    airport: "Manas International Airport  (ru)",
    country: "Kyrgyzstan",
    iata: "FRU",
  },
  {
    city: "Bismarck (ND)",
    airport: "Bismarck Municipal Airport",
    country: "USA",
    iata: "BIS",
  },
  {
    city: "Bissau",
    airport: "Osvaldo Vieira International Airport",
    country: "Guinea-Bissau",
    iata: "BXO",
  },
  {
    city: "Blacksburg (VA)",
    airport: " Virginia Tech Montgomery Airport  (GA)",
    country: "USA",
    iata: "BCB",
  },
  {
    city: "Blackpool",
    airport: "Blackpool Airport  (GA)",
    country: "United Kingdom",
    iata: "BLK",
  },
  {
    city: "Blackwater",
    airport: " Blackwater Airport (GA)",
    country: "Australia",
    iata: "BLT",
  },
  {
    city: "Blantyre",
    airport: "Chileka International Airport",
    country: "Malawi",
    iata: "BLZ",
  },
  {
    city: "Blenheim",
    airport: "Marlborough Airport",
    country: "New Zealand",
    iata: "BHE",
  },
  {
    city: "Bloemfontein (judicial capital)",
    airport: "Bloemfontein Airport",
    country: "South Africa",
    iata: "BFN",
  },
  {
    city: "Bloomington (IL)",
    airport: "Central Illinois Regional Airport",
    country: "USA",
    iata: "BMI",
  },
  {
    city: "Bloomington (IN)",
    airport:
      "Greater Rochester International Airport (formerly Monroe County Airport)",
    country: "USA",
    iata: "BMG",
  },
  {
    city: "Bluefield (WV)",
    airport: "Mercer County Airport",
    country: "USA",
    iata: "BLF",
  },
  {
    city: "Blytheville ",
    airport: "Arkansas International Airport",
    country: "USA",
    iata: "BYH",
  },
  {
    city: "Boa Vista",
    airport: " Boa Vista International Airport",
    country: "Brazil",
    iata: "BVB",
  },
  {
    city: "Boa Vista",
    airport: "Boavista Airport",
    country: "Boa Vista, Cape Verde",
    iata: "BVC",
  },
  {
    city: "Bobo Dioulasso",
    airport: "Bobo Dioulasso Airport",
    country: "Burkina Faso",
    iata: "BOY",
  },
  {
    city: "Bodø",
    airport: "Bodø Airport",
    country: "Norway",
    iata: "BOO",
  },
  {
    city: "Bodrum",
    airport: "Milas–Bodrum Airport",
    country: "Turkey",
    iata: "BJV",
  },
  {
    city: "Bogota",
    airport: "El Dorado International Airport",
    country: "Colombia",
    iata: "BOG",
  },
  {
    city: "Boise (ID)",
    airport: "Boise Airport",
    country: "USA",
    iata: "BOI",
  },
  {
    city: " Boké",
    airport: "Boké Baralande Airport",
    country: "Guinea",
    iata: "BKJ",
  },
  {
    city: "Bologna",
    airport: "Bologna Airport",
    country: "Italy",
    iata: "BLQ",
  },
  {
    city: "Bolzano",
    airport: "Bolzano Airport",
    country: "Italy",
    iata: "BZO",
  },
  {
    city: "Bombay (Mumbai)",
    airport: "Chhatrapati Shivaji International Airport",
    country: "India",
    iata: "BOM",
  },
  {
    city: "Bonaire",
    airport: " Flamingo International Airport",
    country: "Netherlands Antilles",
    iata: "BON",
  },
  {
    city: "Bonaventure",
    airport: "Bonaventure Airport",
    country: "Canada",
    iata: "YVB",
  },
  {
    city: "Bonthe",
    airport: "Sherbro Airport (abandoned)",
    country: "Sierra Leone",
    iata: "BTE",
  },
  {
    city: "Bora Bora",
    airport: "Bora Bora Airport  (Motu Mute Airport)",
    country: "French Polynesia",
    iata: "BOB",
  },
  {
    city: "Bordeaux",
    airport: "Bordeaux Airport",
    country: "France",
    iata: "BOD",
  },
  {
    city: "Borrego Springs (CA)",
    airport: " Borrego Valley Airport",
    country: "USA",
    iata: "BXS",
  },
  {
    city: "Bosaso",
    airport: "Bender Qassim International Airport",
    country: "Somalia",
    iata: "BSA",
  },
  {
    city: "Boston (MA)",
    airport: "Metropolitan Area",
    country: "USA",
    iata: "BOS",
  },
  {
    city: "Bouake",
    airport: "Bouake Airport",
    country: "Cote d'Ivoire",
    iata: "BYK",
  },
  {
    city: "Boulder City (NV)",
    airport: "Boulder City Municipal Airport  (GA)",
    country: "USA",
    iata: "BLD",
  },
  {
    city: "Bourgas/Burgas",
    airport: "Burgas Airport",
    country: "Bulgaria",
    iata: "BOJ",
  },
  {
    city: "Bournemouth",
    airport: "Bournemouth Airport ",
    country: "United Kingdom",
    iata: "BOH",
  },
  {
    city: "Bowen",
    airport: "Bowen Airport",
    country: "Australia",
    iata: "ZBO",
  },
  {
    city: "Bozeman (MT)",
    airport: " Gallatin Field Airport",
    country: "USA",
    iata: "BZN",
  },
  {
    city: "Bradford/Warren ",
    airport: " Bradford Regional Airport  (GA)",
    country: "USA",
    iata: "BFD",
  },
  {
    city: "Braga",
    airport: "Braga Airport",
    country: "Portugal",
    iata: "BGZ",
  },
  {
    city: "Brainerd (MN)",
    airport: "Brainerd Lakes Regional Airport",
    country: "USA",
    iata: "BRD",
  },
  {
    city: "Brampton Island",
    airport: " Brampton Island Airport",
    country: "Australia",
    iata: "BMP",
  },
  {
    city: "Brasilia",
    airport: "Brasilia Airport",
    country: "Brazil",
    iata: "BSB",
  },
  {
    city: "Bratislava",
    airport: "Bratislava Airport M. R. Štefánik",
    country: "Slovakia",
    iata: "BTS",
  },
  {
    city: "Brazzaville",
    airport: "Maya-Maya Airport",
    country: "Congo (ROC)",
    iata: "BZV",
  },
  {
    city: "Bremen",
    airport: "City Airport Bremen",
    country: "Germany",
    iata: "BRE",
  },
  {
    city: "Brescia",
    airport: "Brescia Airport",
    country: "Italy",
    iata: "VBS",
  },
  {
    city: "Brest",
    airport: "Brest Bretagne Airport",
    country: "France",
    iata: "BES",
  },
  {
    city: "Bria",
    airport: "Bria Airport",
    country: "Central African Republic",
    iata: "BIV",
  },
  {
    city: "Bridgeport (CT)",
    airport: "Sikorsky Memorial Airport (GA)",
    country: "USA",
    iata: "BDR",
  },
  {
    city: "Bridgetown",
    airport: "Grantley Adams International Airport",
    country: "Barbados",
    iata: "BGI",
  },
  {
    city: "Brindisi",
    airport: " Brindisi – Salento Airport",
    country: "Italy",
    iata: "BDS",
  },
  {
    city: "Brisbane",
    airport: "Brisbane Airport",
    country: "Australia",
    iata: "BNE",
  },
  {
    city: "Bristol",
    airport: "Bristol Airport",
    country: "United Kingdom",
    iata: "BRS",
  },
  {
    city: "Brno",
    airport: "Brno–Tuřany Airport",
    country: "Czech Republic",
    iata: "BRQ",
  },
  {
    city: "Broennoeysund",
    airport: "Brønnøysund Airport, Brønnøy",
    country: "Norway",
    iata: "BNN",
  },
  {
    city: "Broken Hill",
    airport: "Broken Hill Airport",
    country: "Australia",
    iata: "BHQ",
  },
  {
    city: "Brookings (SD)",
    airport: " Brookings Regional Airport",
    country: "USA",
    iata: "BKX",
  },
  {
    city: "Broome",
    airport: " Broome International Airport",
    country: "Australia",
    iata: "BME",
  },
  {
    city: "Brunswick (GA)",
    airport: "Brunswick Golden Isles Airport",
    country: "USA",
    iata: "BQK",
  },
  {
    city: "Brussels",
    airport: "Brussels Airport",
    country: "Belgium",
    iata: "BRU",
  },
  {
    city: "Bucaramanga",
    airport: "Palonegro Airport",
    country: "Colombia",
    iata: "BGA",
  },
  {
    city: "Bucharest",
    airport: "Metropolitan Area",
    country: "Romania",
    iata: "BUH",
  },
  {
    city: "Bucharest",
    airport: "Henri Coandă International Airport",
    country: "Romania",
    iata: "OTP",
  },
  {
    city: "Budapest",
    airport: "Budapest Ferihegy International Airport",
    country: "Hungary",
    iata: "BUD",
  },
  {
    city: "Buenos Aires",
    airport: "Metropolitan Area",
    country: "Argentina",
    iata: "BUE",
  },
  {
    city: "Buenos Aires",
    airport: "Buenos Aires International Airport  (Ezeiza)",
    country: "Argentina",
    iata: "EZE",
  },
  {
    city: "Buenos Aires",
    airport: "Aeroparque Jorge Newbery",
    country: "Argentina",
    iata: "AEP",
  },
  {
    city: "Buffalo Range",
    airport: "Buffalo Range Airport",
    country: "Zimbabwe",
    iata: "BFO",
  },
  {
    city: "Buffalo/Niagara Falls ",
    airport: "Buffalo Niagara International Airport",
    country: "USA",
    iata: "BUF",
  },
  {
    city: "Bujumbura",
    airport: "Bujumbura International Airport",
    country: "Burundi",
    iata: "BJM",
  },
  {
    city: "Bulawayo",
    airport: "Joshua Mqabuko Nkomo International",
    country: "Zimbabwe",
    iata: "BUQ",
  },
  {
    city: "Bundaberg",
    airport: "Bundaberg Airport",
    country: "Australia",
    iata: "BDB",
  },
  {
    city: "Burbank (CA)",
    airport: "Burbank Bob Hope Airport",
    country: "USA",
    iata: "BUR",
  },
  {
    city: "Burlington (IA)",
    airport: "Southeast Iowa Regional Airport  (GA)",
    country: "USA",
    iata: "BRL",
  },
  {
    city: "Burlington (VT)",
    airport: "Burlington International Airport",
    country: "USA",
    iata: "BTV",
  },
  {
    city: "Burnie (Wynyard)",
    airport: "Burnie Airport",
    country: "Australia",
    iata: "BWT",
  },
  {
    city: "Bushehr",
    airport: "Bushehr Airport",
    country: "Iran",
    iata: "BUZ",
  },
  {
    city: "Butte (MT)",
    airport: "Bert Mooney Airport",
    country: "USA",
    iata: "BTM",
  },
  {
    city: "Cabinda",
    airport: "Cabinda Airport",
    country: "Angola",
    iata: "CAB",
  },
  {
    city: "Cabo Frio",
    airport: "Cabo Frio International Airport",
    country: "Rio de Janeiro",
    iata: "Brazil",
  },
  {
    city: "Cagliari",
    airport: " Cagliari Airport",
    country: "Sardinia, Italy",
    iata: "CAG",
  },
  {
    city: "Cairns",
    airport: "Cairns Airport",
    country: "Australia",
    iata: "CNS",
  },
  {
    city: "Cairo",
    airport: "Cairo International Airport",
    country: "Egypt",
    iata: "CAI",
  },
  {
    city: "Calama",
    airport: "El Loa Airport",
    country: "Chile",
    iata: "CJC",
  },
  {
    city: "Calcutta (Kolkata)",
    airport: "Netaji Subhas Chandra Bose International Airport",
    country: "India",
    iata: "CCU",
  },
  {
    city: "Calgary",
    airport: "Calgary International Airport",
    country: "Canada",
    iata: "YYC",
  },
  {
    city: "Cali",
    airport: "Alfonso Bonilla Aragón International Airport",
    country: "Colombia",
    iata: "CLO",
  },
  {
    city: "Calicut (Kozhikode)",
    airport: "Calicut Airport",
    country: "India",
    iata: "CCJ",
  },
  {
    city: "Calvi",
    airport: "Calvi - Sainte-Catherine Airport",
    country: "Corsica, France",
    iata: "CLY",
  },
  {
    city: "Cambridge Bay",
    airport: "Cambridge Bay Airport",
    country: "Nunavut, Canada",
    iata: "YCB",
  },
  {
    city: "Cambrigde",
    airport: "Cambridge Airport",
    country: "United Kingdom",
    iata: "CBG",
  },
  {
    city: "Campbeltown",
    airport: "Campbeltown Airport",
    country: "United Kingdom",
    iata: "CAL",
  },
  {
    city: "Campo Grande",
    airport: "Campo Grande International Airport",
    country: "Brazil",
    iata: "CGR",
  },
  {
    city: "Canberra",
    airport: "Canberra International Airport",
    country: "Australia",
    iata: "CBR",
  },
  {
    city: "Cancun",
    airport: "Cancún International Airport",
    country: "Mexico",
    iata: "CUN",
  },
  {
    city: "Canouan (island)",
    airport: "Canouan Island Airport",
    country: "Saint Vincent & the Grenadines",
    iata: "CIW",
  },
  {
    city: "Cape Town",
    airport: "Cape Town International Airport",
    country: "South Africa",
    iata: "CPT",
  },
  {
    city: "Caracas",
    airport: "Simón Bolívar International Airport",
    country: "Venezuela",
    iata: "CCS",
  },
  {
    city: "Cardiff",
    airport: "Cardiff Airport",
    country: "United Kingdom",
    iata: "CWL",
  },
  {
    city: "Carlsbad (CA)",
    airport: "McClellan–Palomar Airport",
    country: "USA",
    iata: "CLD",
  },
  {
    city: "Carnarvon",
    airport: "Carnarvon Airport",
    country: "Australia",
    iata: "CVQ",
  },
  {
    city: "Carnot",
    airport: "Carnot Airport",
    country: "Central African Republic",
    iata: "CRF",
  },
  {
    city: "Carson City (NV)",
    airport: "Carson Airport  (GA)",
    country: "United States",
    iata: "CSN",
  },
  {
    city: "Cartagena",
    airport: "Rafael Núñez International Airport ",
    country: "Colombia",
    iata: "CTG",
  },
  {
    city: "La Romana",
    airport: "La Romana International Airport",
    country: "Dominican Republic",
    iata: "LRM",
  },
  {
    city: "Casablanca",
    airport: "Metropolitan Area",
    country: "Morocco",
    iata: "CAS",
  },
  {
    city: "Casablanca",
    airport: "Mohammed V International Airport",
    country: "Morocco",
    iata: "CMN",
  },
  {
    city: "Casino",
    airport: "Casino Airport",
    country: "Australia",
    iata: "CSI",
  },
  {
    city: "Casper (WY)",
    airport: "Natrona County International Airport",
    country: "USA",
    iata: "CPR",
  },
  {
    city: "Castries",
    airport: "George F. L. Charles Airport  (formerly Vigie Airport)",
    country: "Saint Lucia",
    iata: "SLU",
  },
  {
    city: "Catania",
    airport: "Catania–Fontanarossa Airport",
    country: "Italy",
    iata: "CTA",
  },
  {
    city: "Cayenne",
    airport: "Cayenne-Rochambeau Airport",
    country: "French Guiana",
    iata: "CAY",
  },
  {
    city: "Cedar City (UT)",
    airport: " Cedar City Regional Airport",
    country: "USA",
    iata: "CDC",
  },
  {
    city: "Cedar Rapids (IA)",
    airport: "The Eastern Iowa Airport",
    country: "USA",
    iata: "CID",
  },
  {
    city: "Ceduna",
    airport: "Ceduna Airport",
    country: "Australia",
    iata: "CED",
  },
  {
    city: "Cessnock",
    airport: "Cessnock Airport",
    country: "Australia",
    iata: "CES",
  },
  {
    city: "Chambery",
    airport: "Chambéry-Savoie Airport",
    country: "France",
    iata: "CMF",
  },
  {
    city: "Champaign (IL)",
    airport: "University of Illinois Willard Airport",
    country: "USA",
    iata: "CMI",
  },
  {
    city: "Chandigarh",
    airport: "Chandigarh International Airport",
    country: "India",
    iata: "IXC",
  },
  {
    city: "Changchun",
    airport: "Changchun Longjia International Airport",
    country: "Jilin, China",
    iata: "CGQ",
  },
  {
    city: "Chania",
    airport: "Chania International Airport",
    country: "Greece",
    iata: "CHQ",
  },
  {
    city: "Chaoyang",
    airport: "Chaoyang Airport",
    country: "Liaoning, China",
    iata: "CHG",
  },
  {
    city: "Charleston (SC)",
    airport: "Charleston International Airport",
    country: "USA",
    iata: "CHS",
  },
  {
    city: "Charleston (WV)",
    airport: "Yeager Airport",
    country: "USA",
    iata: "CRW",
  },
  {
    city: "Charlotte (NC)",
    airport: "Charlotte Douglas International Airport",
    country: "USA",
    iata: "CLT",
  },
  {
    city: "Charlottesville (VA)",
    airport: "Charlottesville-Albemarle Airport",
    country: "USA",
    iata: "CHO",
  },
  {
    city: "Charters Towers",
    airport: " Charters Towers Airport",
    country: "Australia",
    iata: "CXT",
  },
  {
    city: "Chattanooga (TN)",
    airport: "Chattanooga Metropolitan Airport",
    country: "USA",
    iata: "CHA",
  },
  {
    city: "Chengdu",
    airport: "Chengdu Shuangliu International Airport",
    country: "Sichuan, PR China",
    iata: "CTU",
  },
  {
    city: "Chennai (Madras)",
    airport: " Chennai International Airport",
    country: "India",
    iata: "MAA",
  },
  {
    city: "Cheyenne (WY)",
    airport: " Cheyenne Regional Airport",
    country: "USA",
    iata: "CYS",
  },
  {
    city: "Chiang Mai",
    airport: "Chiang Mai International Airport",
    country: "Thailand",
    iata: "CNX",
  },
  {
    city: "Chicago (IL)",
    airport: "Metropolitan Area",
    country: "USA",
    iata: "CHI",
  },
  {
    city: "Chicago (IL)",
    airport: "Chicago O'Hare International Airport",
    country: "USA",
    iata: "ORD",
  },
  {
    city: "Chicago (IL)",
    airport: "Chicago Midway Airport",
    country: "USA",
    iata: "MDW",
  },
  {
    city: "Chichen Itza",
    airport: "Chichen Itza International Airport",
    country: "Mexico",
    iata: "CZA",
  },
  {
    city: "Chico (CA)",
    airport: "Chico Municipal Airport",
    country: "USA",
    iata: "CIC",
  },
  {
    city: "Chihuahua",
    airport: "Chihuahua International Airport",
    country: "Mexico",
    iata: "CUU",
  },
  {
    city: "Chios Island",
    airport: "Chios Island National Airport",
    country: "Greece",
    iata: "JKH",
  },
  {
    city: "Chipata",
    airport: "Chipata Airport",
    country: "Zambia",
    iata: "CIP",
  },
  {
    city: "Chisinau",
    airport: "Chişinău International Airport",
    country: "Moldova",
    iata: "KIV",
  },
  {
    city: "Chita (Tschita)",
    airport: " Kadala Airport",
    country: "Russia",
    iata: "HTA",
  },
  {
    city: "Sapporo",
    airport: "New Chitose Airport",
    country: "Japan",
    iata: "CTS",
  },
  {
    city: "Chitral",
    airport: "Chitral Airport",
    country: "Pakistan",
    iata: "CJL",
  },
  {
    city: "Chittagong",
    airport: "Shah Amanat International Airport",
    country: "Bangladesh",
    iata: "CGP",
  },
  {
    city: "Chongqing",
    airport: "Chongqing Jiangbei International Airport",
    country: "Sichuan, PR China",
    iata: "CKG",
  },
  {
    city: "Christchurch",
    airport: "Christchurch International Airport",
    country: "New Zealand",
    iata: "CHC",
  },
  {
    city: "Chub Cay",
    airport: " Chub Cay International Airport",
    country: "Bahamas",
    iata: "CCZ",
  },
  {
    city: "Churchill",
    airport: "Churchill Airport",
    country: "Manitoba, Canada",
    iata: "YYQ",
  },
  {
    city: "Cienfuegos",
    airport: "Jaime González Airport",
    country: "Cuba",
    iata: "CFG",
  },
  {
    city: "Cincinnati (OH)",
    airport: "Northern Kentucky International Airport",
    country: "USA",
    iata: "CVG",
  },
  {
    city: "Ciudad Del Carmen",
    airport: "Ciudad del Carmen International Airport",
    country: "Mexico",
    iata: "CME",
  },
  {
    city: "Ciudad Guayana",
    airport: "General Manuel Carlos Piar Airport",
    country: "Venezuela",
    iata: "CGU",
  },
  {
    city: "Ciudad Juárez",
    airport: "Abraham González International Airport",
    country: "Mexico",
    iata: "CJS",
  },
  {
    city: "Ciudad Obregon",
    airport: "Ciudad Obregón International Airport",
    country: "Mexico",
    iata: "CEN",
  },
  {
    city: "Ciudad Victoria",
    airport: "General Pedro J. Méndez National Airport",
    country: "Mexico",
    iata: "CVM",
  },
  {
    city: "Clarksburg (WV)",
    airport: "North Central West Virginia Airport",
    country: "USA",
    iata: "CKB",
  },
  {
    city: "Clermont",
    airport: "Clermont Airport",
    country: "Australia",
    iata: "CMQ",
  },
  {
    city: "Clermont Ferrand",
    airport: "Clermont-Ferrand Auvergne Airport",
    country: "France",
    iata: "CFE",
  },
  {
    city: "Cleveland (OH)",
    airport: "Metropolitan Area",
    country: "USA",
    iata: "CLE",
  },
  {
    city: "Cleveland (OH)",
    airport: "Burke Lakefront Airport  (GA)",
    country: "USA",
    iata: "BKL",
  },
  {
    city: "Cochabamba",
    airport: " Jorge Wilstermann International Airport",
    country: "Bolivia",
    iata: "CBB",
  },
  {
    city: "Cochin",
    airport: "Cochin International",
    country: "Kerala, India",
    iata: "COK",
  },
  {
    city: "Cody (WY)",
    airport: "Yellowstone Regional Airport",
    country: "USA",
    iata: "COD",
  },
  {
    city: "Coffmann Cove (AK)",
    airport: "Coffman Cove Seaplane Base",
    country: "USA",
    iata: "KCC",
  },
  {
    city: "Coffs Harbour",
    airport: " Coffs Harbour Airport",
    country: "Australia",
    iata: "CFS",
  },
  {
    city: "Coimbatore",
    airport: "Coimbatore International Airport",
    country: "India",
    iata: "CJB",
  },
  {
    city: "Colima",
    airport: "Colima National Airport",
    country: "Mexico",
    iata: "CLQ",
  },
  {
    city: "College Station/Bryan ",
    airport: "Easterwood Airport",
    country: "USA",
    iata: "CLL",
  },
  {
    city: "Collinsville",
    airport: "Collinsville Airport",
    country: "Australia",
    iata: "KCE",
  },
  {
    city: "Cologne",
    airport: "Cologne International Airport  (Flughafen Köln/Bonn)",
    country: "Germany",
    iata: "CGN",
  },
  {
    city: "Colombo",
    airport: "Bandaranaike International Airport",
    country: "Sri Lanka",
    iata: "CMB",
  },
  {
    city: "Colorado Springs (CO)",
    airport: "Colorado Springs Airport",
    country: "USA",
    iata: "COS",
  },
  {
    city: "Columbia (SC)",
    airport: "Columbia Metropolitan Airport",
    country: "USA",
    iata: "CAE",
  },
  {
    city: "Columbus (GA)",
    airport: "Columbus Airport",
    country: "USA",
    iata: "CSG",
  },
  {
    city: "Columbus (OH)",
    airport: "Port Columbus International Airport",
    country: "USA",
    iata: "CMH",
  },
  {
    city: "Conakry",
    airport: " Conakry International (Gbessia Airport)",
    country: "Guinea",
    iata: "CKY",
  },
  {
    city: "Concepción",
    airport: "Concepción Airport",
    country: "Bolivia",
    iata: "CEP",
  },
  {
    city: "Concepción",
    airport: "Carriel Sur International Airport",
    country: "Chile",
    iata: "CCP",
  },
  {
    city: "Concord (CA)",
    airport: "Buchanan Field",
    country: "USA",
    iata: "CCR",
  },
  {
    city: "Concord (NH)",
    airport: "Concord Municipal Airport  (GA)",
    country: "USA",
    iata: "CON",
  },
  {
    city: "Constantine",
    airport: "Mohamed Boudiaf International Airport",
    country: "Algeria",
    iata: "CZL",
  },
  {
    city: "Constanta (Constanța)",
    airport: "Mihail Kogălniceanu International Airport",
    country: "Romania",
    iata: "CND",
  },
  {
    city: "Coober Pedy",
    airport: "Coober Pedy Airport",
    country: "Australia",
    iata: "CPD",
  },
  {
    city: "Cooktown",
    airport: "Cooktown Airport",
    country: "Australia",
    iata: "CTN",
  },
  {
    city: "Cooma",
    airport: "Cooma–Snowy Mountains Airport",
    country: "Australia",
    iata: "OOM",
  },
  {
    city: "Copenhagen",
    airport: "Copenhagen Airport",
    country: "Denmark",
    iata: "CPH",
  },
  {
    city: "Cordoba",
    airport: " Ingeniero Ambrosio L.V. Taravella International Airport",
    country: "Argentina",
    iata: "COR",
  },
  {
    city: "Cordoba",
    airport: "Córdoba Airport  (GA)",
    country: "Spain",
    iata: "ODB",
  },
  {
    city: "Cordova (AK)",
    airport: " Merle K. (Mudhole) Smith Airport",
    country: "USA",
    iata: "CDV",
  },
  {
    city: "Corfu (island)",
    airport: "Ioannis Kapodistrias International Airport",
    country: "Greece",
    iata: "CFU",
  },
  {
    city: "Cork",
    airport: "Cork Airport",
    country: "Ireland",
    iata: "ORK",
  },
  {
    city: "Corpus Christi ",
    airport: "Corpus Christi International Airport",
    country: "USA",
    iata: "CRP",
  },
  {
    city: "Cotonou",
    airport: "Cotonou Cadjehoun Airport",
    country: "Benin",
    iata: "COO",
  },
  {
    city: "Cottbus",
    airport: "Cottbus-Drewitz Airport  (GA)",
    country: "Germany",
    iata: "CBU",
  },
  {
    city: "Coventry - Baginton",
    airport: "Coventry Airport",
    country: "United Kingdom",
    iata: "CVT",
  },
  {
    city: "Cozumel (island)",
    airport: "Cozumel International Airport",
    country: "Mexico",
    iata: "CZM",
  },
  {
    city: "Craig (AK)",
    airport: "Craig Seaplane Base",
    country: "USA",
    iata: "CGA",
  },
  {
    city: "Crescent City (CA)",
    airport: "Jack McNamara Field",
    country: "USA",
    iata: "CEC",
  },
  {
    city: "Cuiaba",
    airport: "Marechal Rondon International Airport",
    country: "Brazil",
    iata: "CGB",
  },
  {
    city: "Culiacan",
    airport: "Culiacan International Airport",
    country: "Mexico",
    iata: "CUL",
  },
  {
    city: " Willemstad, Curacao",
    airport: "Curacao International Airport",
    country: "Netherlands Antilles",
    iata: "CUR",
  },
  {
    city: "Curitiba",
    airport: "Afonso Pena International Airport",
    country: "Brazil",
    iata: "CWB",
  },
  {
    city: "Cuyo",
    airport: "Cuyo Airport",
    country: "Palawan, Philippines",
    iata: "CYU",
  },
  {
    city: "Dakar",
    airport: "Léopold Sédar Senghor International Airport",
    country: "Senegal",
    iata: "DKR",
  },
  {
    city: "Dakar",
    airport: "Blaise Diagne International Airport",
    country: "Senegal",
    iata: "DSS",
  },
  {
    city: "Dakhla Oasis",
    airport: "Dakhla Oasis Airport",
    country: "Egypt",
    iata: "DAK",
  },
  {
    city: "Dalaman",
    airport: " Dalaman Airport",
    country: "Turkey",
    iata: "DLM",
  },
  {
    city: "Da Lat",
    airport: "Lien Khuong Airport",
    country: "Vietnam",
    iata: "DLI",
  },
  {
    city: "Dalby",
    airport: "Dalby Airport",
    country: "Australia",
    iata: "DBY",
  },
  {
    city: "Dali",
    airport: "Dali Airport",
    country: "Yunnan, China",
    iata: "DLU",
  },
  {
    city: "Dalian",
    airport: "Dalian Zhoushuizi International Airport  (cn)",
    country: "Liaoning,  China",
    iata: "DLC",
  },
  {
    city: "Dallas ",
    airport: "Dallas Love Field",
    country: "USA",
    iata: "DAL",
  },
  {
    city: "Dallas/Ft. Worth ",
    airport: " Dallas/Fort Worth International Airport",
    country: "USA",
    iata: "DFW",
  },
  {
    city: "Daloa",
    airport: "Daloa Airport",
    country: "Cote d'Ivoire",
    iata: "DJO",
  },
  {
    city: "Damascus",
    airport: "Damascus International Airport",
    country: "Syria",
    iata: "DAM",
  },
  {
    city: "Dammam",
    airport: "King Fahd International Airport",
    country: "Saudi Arabia",
    iata: "DMM",
  },
  {
    city: "Da Nang",
    airport: "Da Nang International Airport",
    country: "Vietnam",
    iata: "DAD",
  },
  {
    city: "Danville (VA)",
    airport: " Danville Regional Airport",
    country: "USA",
    iata: "DAN",
  },
  {
    city: "Daocheng",
    airport: "Daocheng Yading Airport",
    country: "Sichuan, China",
    iata: "DCY",
  },
  {
    city: "Dar es Salaam (Daressalam)",
    airport: "Julius Nyerere International Airport",
    country: "Tanzania",
    iata: "DAR",
  },
  {
    city: "Darwin",
    airport: "Darwin International Airport",
    country: "Australia",
    iata: "DRW",
  },
  {
    city: "Davao",
    airport: "Francisco Bangoy International Airport",
    country: "Philippines",
    iata: "DVO",
  },
  {
    city: "Davenport (IA)",
    airport: "Davenport Municipal Airport (GA)",
    country: "USA",
    iata: "DVN",
  },
  {
    city: "David",
    airport: "Enrique Malek International Airport",
    country: "Panama",
    iata: "DAV",
  },
  {
    city: "Dayton (OH)",
    airport: "Dayton International Airport",
    country: "USA",
    iata: "DAY",
  },
  {
    city: "Daytona Beach (FL)",
    airport: " Daytona Beach International Airport",
    country: "USA",
    iata: "DAB",
  },
  {
    city: "Decatur (IL)",
    airport: "Decatur Airport (GA)",
    country: "USA",
    iata: "DEC",
  },
  {
    city: "Deer Lake/Corner Brook",
    airport: "Deer Lake Regional Airport",
    country: "Canada",
    iata: "YDF",
  },
  {
    city: "Dehradun",
    airport: "Dehradun Airport",
    country: "Uttarakhand, India",
    iata: "DED",
  },
  {
    city: "Del Rio ",
    airport: "Del Rio International Airport (GA)",
    country: "USA",
    iata: "DRT",
  },
  {
    city: "Delhi",
    airport: " Indira Gandhi International Airport",
    country: "India",
    iata: "DEL",
  },
  {
    city: "Den Haag (seat of government)",
    airport: "Rotterdam The Hague Airport",
    country: "Netherlands",
    iata: "RTM",
  },
  {
    city: "Denizli",
    airport: "Çardak Airport",
    country: "Turkey",
    iata: "DNZ",
  },
  {
    city: "Denpasar",
    airport: "Ngurah Rai International Airport",
    country: "Bali, Indonesia",
    iata: "DPS",
  },
  {
    city: "Denver (CO)",
    airport: " Denver International Airport",
    country: "USA",
    iata: "DEN",
  },
  {
    city: "Dera Ismail Khan",
    airport: "Dera Ismail Khan Airport",
    country: "Pakistan",
    iata: "DSK",
  },
  {
    city: "Derby",
    airport: "East Midlands Airport",
    country: "England, UK",
    iata: "EMA",
  },
  {
    city: "Derby",
    airport: "Derby Airport",
    country: "Australia",
    iata: "DRB",
  },
  {
    city: "Derry (Londonderry)",
    airport: "City of Derry Airport",
    country: "Northern Ireland, UK",
    iata: "LDY",
  },
  {
    city: "Des Moines (IA)",
    airport: "Des Moines International Airport",
    country: "USA",
    iata: "DSM",
  },
  {
    city: "Detroit ",
    airport: "Metropolitan Area",
    country: "USA",
    iata: "DTT",
  },
  {
    city: "Detroit ",
    airport: "Detroit Metropolitan Airport",
    country: "USA",
    iata: "DTW",
  },
  {
    city: "Detroit ",
    airport: "Coleman A. Young International Airport (GA)",
    country: "USA",
    iata: "DET",
  },
  {
    city: "Devils Lake (ND)",
    airport: "Devils Lake Municipal Airport",
    country: "USA",
    iata: "DVL",
  },
  {
    city: "Devonport",
    airport: "Devonport Airport",
    country: "Tasmania, Australia",
    iata: "DPO",
  },
  {
    city: "Dhahran",
    airport: "King Abdulaziz Air Base",
    country: "Saudi Arabia",
    iata: "DHA",
  },
  {
    city: "Dhaka",
    airport: "Hazrat Shahjalal International Airport (Zia)",
    country: "Bangladesh",
    iata: "DAC",
  },
  {
    city: "Dien Bien Phu",
    airport: "Dien Bien Phu Airport",
    country: "Vietnam",
    iata: "DIN",
  },
  {
    city: "Dili",
    airport: "Nicolau Lobato International Airport",
    country: "Timor Leste (East Timor)",
    iata: "DIL",
  },
  {
    city: "Dillingham (AK)",
    airport: "Dillingham Airport",
    country: "USA",
    iata: "DLG",
  },
  {
    city: "Dinard",
    airport: "Dinard–Pleurtuit–Saint-Malo Airport",
    country: "France",
    iata: "DNR",
  },
  {
    city: "Dire Dawa",
    airport: "Dire Dawa International Airport",
    country: "Ethiopia",
    iata: "DIR",
  },
  {
    city: "Diu",
    airport: "Diu Airport",
    country: "Daman and Diu, India",
    iata: "DIU",
  },
  {
    city: "Diyarbakir",
    airport: " Diyarbakır Airport",
    country: "Turkey",
    iata: "DIY",
  },
  {
    city: "Djerba (island)",
    airport: " Zarzis Airport",
    country: "Tunisia",
    iata: "DJE",
  },
  {
    city: "Djibouti City",
    airport: "Djibouti-Ambouli International Airport",
    country: "Djibouti",
    iata: "JIB",
  },
  {
    city: "Dodoma",
    airport: "Dodoma International Airport",
    country: "Tanzania",
    iata: "DOD",
  },
  {
    city: "Doha",
    airport: "Hamad International Airport",
    country: "Qatar",
    iata: "DOH",
  },
  {
    city: "Doncaster/Sheffield",
    airport: "Robin Hood International Airport",
    country: "United Kingdom",
    iata: "DSA",
  },
  {
    city: "Donegal (Carrickfin)",
    airport: "Donegal Airport",
    country: "Ireland",
    iata: "CFN",
  },
  {
    city: "Donetsk",
    airport: "Donetsk International Airport (destroyed in 2014)",
    country: "Ukraine",
    iata: "DOK",
  },
  {
    city: "Dandugama (Colombo)",
    airport: " Dandugama Water Aerodrome",
    country: "Sri Lanka",
    iata: "DGM",
  },
  {
    city: "Dortmund",
    airport: " Dortmund Airport",
    country: "Germany",
    iata: "DTM",
  },
  {
    city: "Dothan ",
    airport: "Dothan Regional Airport",
    country: "USA",
    iata: "DHN",
  },
  {
    city: "Douala",
    airport: "Douala Airport",
    country: "Cameroon",
    iata: "DLA",
  },
  {
    city: "Dresden",
    airport: " Dresden International Airport",
    country: "Germany",
    iata: "DRS",
  },
  {
    city: "Dubai",
    airport: "Dubai International Airport",
    country: "United Arab Emirates",
    iata: "DXB",
  },
  {
    city: "Dubbo",
    airport: "Dubbo Regional Airport",
    country: "Australia",
    iata: "DBO",
  },
  {
    city: "Dublin",
    airport: "Dublin Airport",
    country: "Ireland",
    iata: "DUB",
  },
  {
    city: "Dubois (PA)",
    airport: "DuBois Regional Airport",
    country: "USA",
    iata: "DUJ",
  },
  {
    city: "Dubrovnik",
    airport: "Dubrovnik Airport",
    country: "Croatia (Hrvatska)",
    iata: "DBV",
  },
  {
    city: "Dubuque (IA)",
    airport: "Dubuque Regional Airport",
    country: "USA",
    iata: "DBQ",
  },
  {
    city: "Duesseldorf",
    airport: "Düsseldorf International Airport",
    country: "Germany",
    iata: "DUS",
  },
  {
    city: "Duluth (WI)",
    airport: "Duluth International Airport",
    country: "USA",
    iata: "DLH",
  },
  {
    city: " Dumai City",
    airport: "Pinang Kampai Airport",
    country: "Indonesia",
    iata: "DUM",
  },
  {
    city: "Dundee",
    airport: "Dundee Airport",
    country: "United Kingdom",
    iata: "DND",
  },
  {
    city: "Dunedin",
    airport: "Dunedin Airport",
    country: "New Zealand",
    iata: "DUD",
  },
  {
    city: "Dunk Island",
    airport: "Dunk Island Airport",
    country: "Australia",
    iata: "DKI",
  },
  {
    city: "Durango",
    airport: "Guadalupe Victoria International Airport",
    country: "Mexico",
    iata: "DGO",
  },
  {
    city: "Durango (CO)",
    airport: "Durango-La Plata County Airport",
    country: "USA",
    iata: "DRO",
  },
  {
    city: "Durban",
    airport: "King Shaka International Airport",
    country: "South Africa",
    iata: "DUR",
  },
  {
    city: "Dushanbe",
    airport: "Dushanbe Airport",
    country: "Tajikistan",
    iata: "DYU",
  },
  {
    city: "Dutch Harbor (AK)",
    airport: "Unalaska Airport",
    country: "USA",
    iata: "DUT",
  },
  {
    city: "Dysart",
    airport: "Dysart Airport",
    country: "Australia",
    iata: "DYA",
  },
  {
    city: "Dzaoudzi",
    airport: "Dzaoudzi Pamandzi International Airport",
    country: "Mayotte",
    iata: "DZA",
  },
  {
    city: "East London",
    airport: "East London Airport",
    country: "South Africa",
    iata: "ELS",
  },
  {
    city: "Easter Island (Rapa Nui)",
    airport: "Mataveri International Airport",
    country: "Chile",
    iata: "IPC",
  },
  {
    city: "Eau Clarie (WI)",
    airport: " Chippewa Valley Regional Airport",
    country: "USA",
    iata: "EAU",
  },
  {
    city: "Edinburgh",
    airport: "Edinburgh Airport",
    country: "Scotland, UK",
    iata: "EDI",
  },
  {
    city: "Edmonton",
    airport: "Metropolitan Area",
    country: "Canada",
    iata: "YEA",
  },
  {
    city: "Edmonton",
    airport: " Edmonton International Airport",
    country: "Canada",
    iata: "YEG",
  },
  {
    city: "Edmonton",
    airport: "Edmonton City Centre Airport (closed 2013)",
    country: "Canada",
    iata: "YXD",
  },
  {
    city: "Egilsstadir (Egilsstaðir)",
    airport: "Egilsstaðir Airport",
    country: "Iceland",
    iata: "EGS",
  },
  {
    city: "Eindhoven",
    airport: " Eindhoven Airport",
    country: "Netherlands",
    iata: "EIN",
  },
  {
    city: "Eilat",
    airport: "Eilat Airport  (J. Hozman)",
    country: "Israel",
    iata: "ETH",
  },
  {
    city: "Eilat",
    airport: "Ovda International Airport",
    country: "Israel",
    iata: "VDA",
  },
  {
    city: "Ekaterinburg (Yekaterinburg)",
    airport: "Koltsovo Airport",
    country: "Russia",
    iata: "SVX",
  },
  {
    city: " El Aaiún (Laayoune)",
    airport: "Hassan I Airport",
    country: "Morocco",
    iata: "EUN",
  },
  {
    city: "Elba (island)",
    airport: "Elba Airport Marina di Campo",
    country: "Italy",
    iata: "EBA",
  },
  {
    city: "El Dorado ",
    airport: "South Arkansas Regional Airport",
    country: "USA",
    iata: "ELD",
  },
  {
    city: " Eldoret",
    airport: "Eldoret International Airport",
    country: "Kenya",
    iata: "EDL",
  },
  {
    city: "Elkhart (IN)",
    airport: " Elkhart Municipal Airport (GA)",
    country: "USA",
    iata: "EKI",
  },
  {
    city: "Elko (NV)",
    airport: "Elko Regional Airport",
    country: "USA",
    iata: "EKO",
  },
  {
    city: "Ellisras",
    airport: "Ellisras Airport",
    country: "South Africa",
    iata: "ELL",
  },
  {
    city: "El Minya",
    airport: " El Minya (no airport)",
    country: "Egypt",
    iata: "EMY",
  },
  {
    city: "Elmira ",
    airport: " Elmira Corning Regional Airport",
    country: "USA",
    iata: "ELM",
  },
  {
    city: "El Paso ",
    airport: "El Paso International Airport",
    country: "USA",
    iata: "ELP",
  },
  {
    city: "Ely (NV)",
    airport: "Ely Airport",
    country: "USA",
    iata: "ELY",
  },
  {
    city: "Edward River, Pormpuraaw",
    airport: "Edward River Airport",
    country: "Australia",
    iata: "EDR",
  },
  {
    city: "Emerald",
    airport: " Emerald Airport",
    country: "Australia",
    iata: "EMD",
  },
  {
    city: "Enontekiö",
    airport: " Enontekiö Airport",
    country: "Finland",
    iata: "ENF",
  },
  {
    city: "Entebbe (Kampala)",
    airport: "Entebbe International Airport",
    country: "Uganda",
    iata: "EBB",
  },
  {
    city: "Erbil",
    airport: "Erbil International Airport",
    country: "Iraq",
    iata: "EBL",
  },
  {
    city: "Erfurt",
    airport: "Erfurt Weimar Airport  (Flughafen Erfurt Weimar)",
    country: "Germany",
    iata: "ERF",
  },
  {
    city: "Erie (PA)",
    airport: "Erie International Airport",
    country: "USA",
    iata: "ERI",
  },
  {
    city: "Erewan (Yerevan)",
    airport: "Zvartnots International Airport",
    country: "Armenia",
    iata: "EVN",
  },
  {
    city: "Erzincan",
    airport: " Erzincan Airport",
    country: "Turkey",
    iata: "ERC",
  },
  {
    city: "Erzurum",
    airport: "Erzurum Airport",
    country: "Turkey",
    iata: "ERZ",
  },
  {
    city: "Esbjerg",
    airport: "Esbjerg Airport",
    country: "Denmark",
    iata: "EBJ",
  },
  {
    city: "Escanaba ",
    airport: "Delta County Airport",
    country: "USA",
    iata: "ESC",
  },
  {
    city: "Esperance",
    airport: "Esperance Airport",
    country: "Australia",
    iata: "EPR",
  },
  {
    city: "Eugene (OR)",
    airport: " Eugene Airport (Mahlon Sweet Field)",
    country: "USA",
    iata: "EUG",
  },
  {
    city: "Eureka (CA)",
    airport: "Arcata-Eureka Airport",
    country: "USA",
    iata: "ACV",
  },
  {
    city: "Evansville (IN)",
    airport: "Evansville Regional Airport",
    country: "USA",
    iata: "EVV",
  },
  {
    city: "Evenes",
    airport: "Harstad/Narvik Airport, Evenes",
    country: "Norway",
    iata: "EVE",
  },
  {
    city: "Exeter",
    airport: "Exeter International Airport",
    country: "United Kingdom",
    iata: "EXT",
  },
  {
    city: "Fairbanks (AK)",
    airport: " Fairbanks International Airport",
    country: "USA",
    iata: "FAI",
  },
  {
    city: "Fair Isle (island)",
    airport: " Fair Isle Airport",
    country: "Shetland, United Kingdom",
    iata: "FIE",
  },
  {
    city: "Faisalabad",
    airport: " Faisalabad International Airport",
    country: "Pakistan",
    iata: "LYP",
  },
  {
    city: "Fargo (ND)",
    airport: "Hector International Airport",
    country: "USA",
    iata: "FAR",
  },
  {
    city: "Farmington (NM)",
    airport: " Four Corners Regional Airport",
    country: "USA",
    iata: "FMN",
  },
  {
    city: "Faro",
    airport: "Faro Airport",
    country: "Portugal",
    iata: "FAO",
  },
  {
    city: "Faroer (island)",
    airport: "Vágar Airport",
    country: "Denmark",
    iata: "FAE",
  },
  {
    city: "Fayetteville ",
    airport: "Drake Field",
    country: "USA",
    iata: "FYV",
  },
  {
    city: "Fayetteville/Ft. Bragg (NC)",
    airport: " Fayetteville Regional Airport",
    country: "USA",
    iata: "FAY",
  },
  {
    city: "Fes",
    airport: "Fes-Saïss Airport",
    country: "Morocco",
    iata: "FEZ",
  },
  {
    city: "Figari",
    airport: "Sud Corse Airport",
    country: "Corsica, France",
    iata: "FSC",
  },
  {
    city: "Flagstaff (AZ)",
    airport: "Flagstaff Pulliam Airport",
    country: "USA",
    iata: "FLG",
  },
  {
    city: "Flin Flon",
    airport: "Flin Flon Airport",
    country: "Canada",
    iata: "YFO",
  },
  {
    city: "Flint ",
    airport: "Bishop International Airport",
    country: "USA",
    iata: "FNT",
  },
  {
    city: "Florence (Firenze)",
    airport: "Florence Airport  (Amerigo Vespucci Airport)",
    country: "Italy",
    iata: "FLR",
  },
  {
    city: "Florence (SC)",
    airport: "Florence Regional Airport",
    country: "USA",
    iata: "FLO",
  },
  {
    city: "Florianopolis",
    airport: "Hercílio Luz International Airport",
    country: "Brazil",
    iata: "FLN",
  },
  {
    city: "Floroe (Florø)",
    airport: "Florø Airport",
    country: "Norway",
    iata: "FRO",
  },
  {
    city: "Fort Albany",
    airport: "Fort Albany Airport",
    country: "Canada",
    iata: "YFA",
  },
  {
    city: "Fort de France (territorial capital)",
    airport: "Martinique Aimé Césaire International Airport",
    country: "Martinique",
    iata: "FDF",
  },
  {
    city: "Fort Dodge (IA)",
    airport: "Fort Dodge Regional Airport",
    country: "USA",
    iata: "FOD",
  },
  {
    city: "Fort Huachuca/Sierra Vista (AZ)",
    airport: "Sierra Vista Municipal Airport",
    country: "USA",
    iata: "FHU",
  },
  {
    city: "Fort Lauderdale (FL)",
    airport: "Fort Lauderdale–Hollywood International",
    country: "USA",
    iata: "FLL",
  },
  {
    city: "Fort McMurray",
    airport: "Fort McMurray Airport",
    country: "Canada",
    iata: "YMM",
  },
  {
    city: "Fort Myers (FL)",
    airport: "Metropolitan Area",
    country: "USA",
    iata: "FMY",
  },
  {
    city: "Fort Myers (FL)",
    airport: "Southwest Florida Regional Airport",
    country: "USA",
    iata: "RSW",
  },
  {
    city: "Fort Riley/Junction City (KS)",
    airport: "Marshall Army Airfield (Fort Riley)",
    country: "USA",
    iata: "FRI",
  },
  {
    city: "Fort Smith",
    airport: "Fort Smith Airport",
    country: "Canada",
    iata: "YSM",
  },
  {
    city: "Fort Smith ",
    airport: " Fort Smith Regional Airport",
    country: "USA",
    iata: "FSM",
  },
  {
    city: "Fort St. John",
    airport: " Fort St. John Airport",
    country: "Canada",
    iata: "YXJ",
  },
  {
    city: "Fort Walton Beach (FL)",
    airport: "Northwest Florida Regional Airport",
    country: "USA",
    iata: "VPS",
  },
  {
    city: "Fort Wayne (IN)",
    airport: " Fort Wayne International Airport",
    country: "USA",
    iata: "FWA",
  },
  {
    city: "Fortaleza",
    airport: "Pinto Martins International Airport",
    country: "Brazil",
    iata: "FOR",
  },
  {
    city: "Foz do Iguacu",
    airport: "Foz do Iguaçu International Airport",
    country: "Paraná, Brazil",
    iata: "IGU",
  },
  {
    city: "Francistown",
    airport: "Francistown Airport",
    country: "Botswana",
    iata: "FRW",
  },
  {
    city: "Frankfurt/Main",
    airport: "Frankfurt Airport  (Rhein-Main-Flughafen)",
    country: "Germany",
    iata: "FRA",
  },
  {
    city: "Frankfurt/Hahn",
    airport: "Frankfurt-Hahn Airport",
    country: "Germany",
    iata: "HHN",
  },
  {
    city: "Franklin/Oil City (PA)",
    airport: "Venango Regional Airport",
    country: "USA",
    iata: "FKL",
  },
  {
    city: "Fredericton",
    airport: " Fredericton International Airport",
    country: "Canada",
    iata: "YFC",
  },
  {
    city: "Freeport",
    airport: "Grand Bahama International Airport",
    country: "Bahamas",
    iata: "FPO",
  },
  {
    city: "Freetown",
    airport: "Lungi International Airport",
    country: "Sierra Leone",
    iata: "FNA",
  },
  {
    city: "Frejus",
    airport: " Frejus Saint-Raphael Airport",
    country: "France",
    iata: "FRJ",
  },
  {
    city: "Fresno (CA)",
    airport: "Fresno Yosemite International Airport",
    country: "USA",
    iata: "FAT",
  },
  {
    city: "Friedrichshafen",
    airport: "Bodensee-Airport Friedrichshafen",
    country: "Germany",
    iata: "FDH",
  },
  {
    city: "Fuerteventura",
    airport: "El Fuerteventura Matorral Airport",
    country: "Spain",
    iata: "FUE",
  },
  {
    city: "Fukuoka",
    airport: "Fukuoka Airport",
    country: "Japan",
    iata: "FUK",
  },
  {
    city: "Fukushima",
    airport: "Fukushima Airport",
    country: "Japan",
    iata: "FKS",
  },
  {
    city: "Funchal",
    airport: "Madeira Airport",
    country: "Madeira, Portugal",
    iata: "FNC",
  },
  {
    city: "Futuna",
    airport: "Pointe Vele Airport",
    country: "Wallis and Futuna Islands",
    iata: "FUT",
  },
  {
    city: "Gaborone",
    airport: "Sir Seretse Khama International Airport",
    country: "Botswana",
    iata: "GBE",
  },
  {
    city: "Gadsden ",
    airport: "Northeast Alabama Regional Airport",
    country: "USA",
    iata: "GAD",
  },
  {
    city: "Gainesville (FL)",
    airport: "Gainesville Regional Airport",
    country: "USA",
    iata: "GNV",
  },
  {
    city: "Galway",
    airport: "Galway Airport",
    country: "Ireland",
    iata: "GWY",
  },
  {
    city: "Gander",
    airport: " Gander International Airport",
    country: "Canada",
    iata: "YQX",
  },
  {
    city: "Garoua",
    airport: "Garoua International Airport",
    country: "Cameroon",
    iata: "GOU",
  },
  {
    city: "Gassim (Al-Qassim)",
    airport: "Nayef bin Abdulaziz Regional Airport",
    country: "Saudi Arabia",
    iata: "ELQ",
  },
  {
    city: "Gaza City",
    airport: "Yasser Arafat International Airport",
    country: "Palestinian Territory",
    iata: "GZA",
  },
  {
    city: "Gaziantep",
    airport: "Oğuzeli Airport",
    country: "Turkey",
    iata: "GZT",
  },
  {
    city: "Gdansk",
    airport: "Gdańsk Lech Wałęsa Airport",
    country: "Poland",
    iata: "GDN",
  },
  {
    city: "Geelong",
    airport: "Geelong Airport (closed)",
    country: "Australia",
    iata: "GEX",
  },
  {
    city: "Geneva",
    airport: "Geneva-Cointrin International Airport",
    country: "Switzerland",
    iata: "GVA",
  },
  {
    city: "Genoa",
    airport: "Cristoforo Colombo Airport",
    country: "Italy",
    iata: "GOA",
  },
  {
    city: "George",
    airport: "George Airport",
    country: "South Africa",
    iata: "GRJ",
  },
  {
    city: "Georgetown",
    airport: "Cheddi Jagan International Airport",
    country: "Guyana",
    iata: "GEO",
  },
  {
    city: "Geraldton",
    airport: "Geraldton Airport",
    country: "Australia",
    iata: "GET",
  },
  {
    city: "Gerona (Girona)",
    airport: " Girona-Costa Brava Airport",
    country: "Spain",
    iata: "GRO",
  },
  {
    city: "Ghent (Gent)",
    airport: "St. Denijs-Westrem Airport (closed)",
    country: "Belgium",
    iata: "GNE",
  },
  {
    city: "Gibraltar",
    airport: " Gibraltar Airport",
    country: "Gibraltar",
    iata: "GIB",
  },
  {
    city: "Gilette (WY)",
    airport: "Gillette-Campbell County Airport",
    country: "USA",
    iata: "GCC",
  },
  {
    city: "Gilgit",
    airport: "Gilgit Airport",
    country: "Pakistan",
    iata: "GIL",
  },
  {
    city: "Gillam",
    airport: " Gillam Airport",
    country: "Canada",
    iata: "YGX",
  },
  {
    city: "Gladstone",
    airport: "Gladstone Airport",
    country: "Australia",
    iata: "GLT",
  },
  {
    city: "Glasgow",
    airport: "Metropolitan Area",
    country: "Scotland, UK",
    iata: "GLA",
  },
  {
    city: "Glasgow",
    airport: "Glasgow Prestwick International Airport",
    country: "Scotland, UK",
    iata: "PIK",
  },
  {
    city: "Glasgow (MT)",
    airport: "Glasgow Airport (Wokal Field)",
    country: "USA",
    iata: "GGW",
  },
  {
    city: "Glendive (MT)",
    airport: "Dawson Community Airport",
    country: "USA",
    iata: "GDV",
  },
  {
    city: "Goa",
    airport: "Goa International Airport",
    country: "India",
    iata: "GOI",
  },
  {
    city: "Goiania",
    airport: "Santa Genoveva Airport",
    country: "Brazil",
    iata: "GYN",
  },
  {
    city: "Gold Coast, Coolangatta",
    airport: "Gold Coast Airport",
    country: "Australia",
    iata: "OOL",
  },
  {
    city: "Goondiwindi",
    airport: " Goondiwindi Airport",
    country: "Australia",
    iata: "GOO",
  },
  {
    city: "Goose Bay",
    airport: "Goose Bay Airport",
    country: "Canada",
    iata: "YYR",
  },
  {
    city: "Gorna",
    airport: "Gorna Oryahovitsa Airport",
    country: "Bulgaria",
    iata: "GOZ",
  },
  {
    city: "Gothenburg (Göteborg)",
    airport: " Göteborg Landvetter Airport",
    country: "Sweden",
    iata: "GOT",
  },
  {
    city: "Gove/Nhulunbuy",
    airport: "Gove Airport",
    country: "Australia",
    iata: "GOV",
  },
  {
    city: "Govenors Harbour",
    airport: "Governor's Harbour Airport",
    country: "Eleuthera, Bahamas",
    iata: "GHB",
  },
  {
    city: "Granada",
    airport: "F.G.L. Granada-Jaén Airport",
    country: "Spain",
    iata: "GRX",
  },
  {
    city: "Granadilla de Abona",
    airport: "Tenerife South Airport  (Tenerife Sur)",
    country: "Tenerife, Spain",
    iata: "TFS",
  },
  {
    city: "Grand Canyon, Tusayan (AZ)",
    airport: "Grand Canyon National Park Airport",
    country: "USA",
    iata: "GCN",
  },
  {
    city: "Grand Cayman",
    airport: " Owen Roberts International",
    country: "Cayman Islands",
    iata: "GCM",
  },
  {
    city: "Grand Forks (ND)",
    airport: "Grand Forks Airport",
    country: "USA",
    iata: "GFK",
  },
  {
    city: "Grand Junction (CO)",
    airport: "Grand Junction Regional Airport",
    country: "USA",
    iata: "GJT",
  },
  {
    city: "Grand Rapids ",
    airport: "Gerald R. Ford International Airport",
    country: "USA",
    iata: "GRR",
  },
  {
    city: "Grand Rapids (MN)",
    airport: "Grand Rapids/Itasca County Airport (GA)",
    country: "USA",
    iata: "GPZ",
  },
  {
    city: "Graz",
    airport: " Graz Airport",
    country: "Austria",
    iata: "GRZ",
  },
  {
    city: "Great Falls (MT)",
    airport: " Great Falls Airport",
    country: "USA",
    iata: "GTF",
  },
  {
    city: "Great Keppel Island",
    airport: "Great Keppel Island Airport",
    country: "Australia",
    iata: "GKL",
  },
  {
    city: "Green Bay (WI)",
    airport: "Austin Straubel Airport",
    country: "USA",
    iata: "GRB",
  },
  {
    city: "Greensboro (NC)",
    airport: " Piedmont Triad Airport",
    country: "USA",
    iata: "GSO",
  },
  {
    city: "Greenville (MS)",
    airport: "Mid-Delta Regional Airport (GA)",
    country: "USA",
    iata: "GLH",
  },
  {
    city: "Greenville (NC)",
    airport: "Pitt-Greenville Airport",
    country: "USA",
    iata: "PGV",
  },
  {
    city: "Greenville/Spartanburg (SC)",
    airport: "GSP International Airport",
    country: "USA",
    iata: "GSP",
  },
  {
    city: "St. George's",
    airport: " Maurice Bishop International  (Point Salines)",
    country: "Grenada",
    iata: "GND",
  },
  {
    city: "Grenoble",
    airport: "Grenoble-Isère Airport",
    country: "France",
    iata: "GNB",
  },
  {
    city: "Griffith",
    airport: "Griffith Airport",
    country: "Australia",
    iata: "GFF",
  },
  {
    city: "Groningen",
    airport: " Groningen Airport Eelde",
    country: "Netherlands",
    iata: "GRQ",
  },
  {
    city: "Groote Eylandt (island)",
    airport: "Groote Eylandt Airport",
    country: "Australia",
    iata: "GTE",
  },
  {
    city: "Groton/New London (CT)",
    airport: "Groton-New London Airport",
    country: "USA",
    iata: "GON",
  },
  {
    city: "Guadalajara",
    airport: "Don Miguel Hidalgo y Costilla International",
    country: "Mexico",
    iata: "GDL",
  },
  {
    city: "Guangzhou",
    airport: "Baiyun International Airport  (cn)",
    country: "Guangdong, PR China",
    iata: "CAN",
  },
  {
    city: "Guarulhos, Sao Paulo",
    airport: "São Paulo-Guarulhos International Airport",
    country: "Brazil",
    iata: "GRU",
  },
  {
    city: " Guatemala City",
    airport: "La Aurora International Airport",
    country: "Guatemala",
    iata: "GUA",
  },
  {
    city: "Guayaquil",
    airport: " Guayaquil Airport José Joaquín de Olmedo",
    country: "Ecuador",
    iata: "GYE",
  },
  {
    city: "Guernsey",
    airport: " Guernsey Airport",
    country: "Channel Islands",
    iata: "GCI",
  },
  {
    city: "Guettin",
    airport: "Rügen Airport (GA)",
    country: "Germany",
    iata: "GTI",
  },
  {
    city: "Guilin",
    airport: "Guilin Liangjiang International Airport  (cn)",
    country: "Guangxi, China",
    iata: "KWL",
  },
  {
    city: "Gulfport/Biloxi (MS)",
    airport: " Gulfport-Biloxi International Airport",
    country: "USA",
    iata: "GPT",
  },
  {
    city: "Gulu",
    airport: "Gulu Airport",
    country: "Uganda",
    iata: "ULU",
  },
  {
    city: "Gunnison (CO)",
    airport: "Gunnison-Crested Butte Regional Airport",
    country: "USA",
    iata: "GUC",
  },
  {
    city: "Guwahati",
    airport: "Lokpriya Gopinath Bordoloi International",
    country: "Assam, India",
    iata: "GAU",
  },
  {
    city: "Gwadar",
    airport: "Gwadar Airport",
    country: "Pakistan",
    iata: "GWD",
  },
  {
    city: "Gweru",
    airport: "Gweru-Thornhill Air Base",
    country: "Zimbabwe",
    iata: "GWE",
  },
  {
    city: "Gympie",
    airport: "Gympie Airport",
    country: "Australia",
    iata: "GYP",
  },
  {
    city: "Hachijo Jima",
    airport: "Hachijojima Airport (Hachijo Jima Airport)",
    country: "Japan",
    iata: "HAC",
  },
  {
    city: "Haifa",
    airport: "Haifa Airport  (Uri Michaeli Airport)",
    country: "Israel",
    iata: "HFA",
  },
  {
    city: "Haines (AK)",
    airport: "Haines Airport",
    country: "USA",
    iata: "HNS",
  },
  {
    city: "Hakodate",
    airport: " Hakodate Airport",
    country: "Hokkaido, Japan",
    iata: "HKD",
  },
  {
    city: "Halifax",
    airport: "Halifax Stanfield International Airport",
    country: "Nova Scotia, Canada",
    iata: "YHZ",
  },
  {
    city: "Hall Beach",
    airport: "Hall Beach Airport",
    country: "Nunavut, Canada",
    iata: "YUX",
  },
  {
    city: "Hambantota",
    airport: "Mattala Rajapaksa International Airport",
    country: "Sri Lanka",
    iata: "HRI",
  },
  {
    city: "Hamburg",
    airport: "Hamburg Airport",
    country: "Germany",
    iata: "HAM",
  },
  {
    city: "Hamilton",
    airport: "Hamilton Airport",
    country: "Victoria, Australia",
    iata: "HLT",
  },
  {
    city: "Hamilton",
    airport: "Hamilton International Airport",
    country: "Canada",
    iata: "YHM",
  },
  {
    city: "Hamilton",
    airport: "Hamilton International Airport",
    country: "New Zealand",
    iata: "HLZ",
  },
  {
    city: "Hamilton Island",
    airport: "Great Barrier Reef Airport",
    country: "Australia",
    iata: "HTI",
  },
  {
    city: "Hammerfest",
    airport: "Hammerfest Airport",
    country: "Norway",
    iata: "HFT",
  },
  {
    city: "Hancock ",
    airport: "Houghton County Memorial Airport",
    country: "USA",
    iata: "CMX",
  },
  {
    city: "Hangchow (Hangzhou)",
    airport: "Hangzhou International Airport  (cn)",
    country: "Zhejiang, China",
    iata: "HGH",
  },
  {
    city: "Hannover",
    airport: "Hannover-Langenhagen Airport",
    country: "Germany",
    iata: "HAJ",
  },
  {
    city: "Hanoi",
    airport: "Noi Bai International Airport",
    country: "Viet Nam",
    iata: "HAN",
  },
  {
    city: "Harare",
    airport: "Harare International Airport",
    country: "Zimbabwe",
    iata: "HRE",
  },
  {
    city: "Harbin (Haerbin)",
    airport: "Harbin Taiping International Airport",
    country: "Heilongjiang,  China",
    iata: "HRB",
  },
  {
    city: "Harlingen/South Padre Island ",
    airport: " Valley International Airport",
    country: "USA",
    iata: "HRL",
  },
  {
    city: "Harrisburg (PA)",
    airport: "Metropolitan Area",
    country: "USA",
    iata: "HAR",
  },
  {
    city: "Harrisburg (PA)",
    airport: "Harrisburg International Airport",
    country: "USA",
    iata: "MDT",
  },
  {
    city: "Hartford (CT)",
    airport: "Bradley International Airport",
    country: "USA",
    iata: "BDL",
  },
  {
    city: " Hattiesburg/Laurel (MS)",
    airport: "Hattiesburg-Laurel Regional Airport",
    country: "USA",
    iata: "PIB",
  },
  {
    city: "Hatyai (Hat Yai)",
    airport: "Hat Yai International Airport",
    country: "Thailand",
    iata: "HDY",
  },
  {
    city: "Haugesund",
    airport: " Haugesund Airport, Karmøy",
    country: "Norway",
    iata: "HAU",
  },
  {
    city: "Havana",
    airport: "José Martí International Airport",
    country: "Cuba",
    iata: "HAV",
  },
  {
    city: "Havre (MT)",
    airport: "Havre City-County Airport",
    country: "USA",
    iata: "HVR",
  },
  {
    city: "Hayman Island",
    airport: "Hayman Island Resort Seaplace Base",
    country: "Australia",
    iata: "HIS",
  },
  {
    city: "Helena (MT)",
    airport: "Helena Regional Airport",
    country: "USA",
    iata: "HLN",
  },
  {
    city: "Helsingborg",
    airport: "Hamnen Heliport",
    country: "Sweden",
    iata: "JHE",
  },
  {
    city: "Helsinki",
    airport: "Metropolitan Area",
    country: "Finland",
    iata: "HEL",
  },
  {
    city: "Helsinki",
    airport: "Helsinki-Malmi Airport (GA)",
    country: "Finland",
    iata: "HEM",
  },
  {
    city: "Heraklion",
    airport: "Heraklion International Nikos Kazantzakis Crete",
    country: "Greece",
    iata: "HER",
  },
  {
    city: "Hermosillo",
    airport: "Gen. Pesqueira Garcia Airport",
    country: "Mexico",
    iata: "HMO",
  },
  {
    city: "Hervey Bay",
    airport: " Hervey Bay Airport",
    country: "Australia",
    iata: "HVB",
  },
  {
    city: "Hibbing (MN)",
    airport: "Chisholm-Hibbing Airport",
    country: "USA",
    iata: "HIB",
  },
  {
    city: "Hickory (NC)",
    airport: "Hickory Regional Airport (GA)",
    country: "USA",
    iata: "HKY",
  },
  {
    city: "Hilo (HI)",
    airport: " Hilo International Airport",
    country: "USA",
    iata: "ITO",
  },
  {
    city: "Hilton Head Island (SC)",
    airport: "Hilton Head Airport (GA)",
    country: "USA",
    iata: "HHH",
  },
  {
    city: "Hinchinbrook Island",
    airport: " Hinchinbrook Island Airport",
    country: "Australia",
    iata: "HNK",
  },
  {
    city: "Hiroshima",
    airport: "Hiroshima Airport",
    country: "Honshu, Japan",
    iata: "HIJ",
  },
  {
    city: "Ho Chi Minh City (Saigon)",
    airport: "Tan Son Nhat International Airport",
    country: "Vietnam",
    iata: "SGN",
  },
  {
    city: "Hobart",
    airport: "Hobart Airport",
    country: "Tasmania, Australia",
    iata: "HBA",
  },
  {
    city: "Hof",
    airport: "Hof–Plauen Regional Airport  (GA)",
    country: "Germany",
    iata: "HOQ",
  },
  {
    city: "Holguin",
    airport: " Frank País Airport",
    country: "Cuba",
    iata: "HOG",
  },
  {
    city: "Homer (AK)",
    airport: " Homer Airport",
    country: "USA",
    iata: "HOM",
  },
  {
    city: "Hong Kong",
    airport:
      "Hong Kong International Airport  (HKIA) know as Chek Lap Kok Airport",
    country: "Hong Kong",
    iata: "HKG",
  },
  {
    city: "Honiara",
    airport: "Henderson International Airport",
    country: "Guadalcanal, Solomon Islands",
    iata: "HIR",
  },
  {
    city: "Honolulu (HI)",
    airport: "Honolulu International Airport",
    country: "USA, Hawaii",
    iata: "HNL",
  },
  {
    city: "Hoonah (AK)",
    airport: "Hoonah Airport",
    country: "USA",
    iata: "HNH",
  },
  {
    city: "Horta, Azores",
    airport: "Horta Airport",
    country: "Portugal",
    iata: "HOR",
  },
  {
    city: "Houston ",
    airport: "George Bush Intercontinental Airport",
    country: "USA",
    iata: "IAH",
  },
  {
    city: "Houston ",
    airport: "William P. Hobby Airport",
    country: "USA",
    iata: "HOU",
  },
  {
    city: "Huahine, Society Islands",
    airport: "Fare Airport",
    country: "French Polynesia",
    iata: "HUH",
  },
  {
    city: "Huatulco",
    airport: "Bahías de Huatulco International Airport",
    country: "Mexico",
    iata: "HUX",
  },
  {
    city: "Hue",
    airport: "Phu Bai International Airport",
    country: "Vietnam",
    iata: "HUI",
  },
  {
    city: "Humberside",
    airport: "Humberside Airport",
    country: "United Kingdom",
    iata: "HUY",
  },
  {
    city: "Huntington (WV)",
    airport: "Tri-State Airport",
    country: "USA",
    iata: "HTS",
  },
  {
    city: "Huntsville ",
    airport: " Huntsville International Airport",
    country: "USA",
    iata: "HSV",
  },
  {
    city: "Hurghada",
    airport: "Hurghada International Airport",
    country: "Egypt",
    iata: "HRG",
  },
  {
    city: "Huron (SD)",
    airport: "Huron Regional Airport",
    country: "USA",
    iata: "HON",
  },
  {
    city: "Hwange National Park",
    airport: "Hwange National Park Airport",
    country: "Zimbabwe",
    iata: "HWN",
  },
  {
    city: "Hyannis (MA)",
    airport: "Barnstable Municipal Airport",
    country: "USA",
    iata: "HYA",
  },
  {
    city: "Hydaburg (AK)",
    airport: "Hydaburg Seaplane Base",
    country: "USA",
    iata: "HYG",
  },
  {
    city: "Hyderabad",
    airport: "Rajiv Gandhi International Airport",
    country: "Telangana, India",
    iata: "HYD",
  },
  {
    city: "Hyderabad",
    airport: "Hyderabad Airport",
    country: "Sindh, Pakistan",
    iata: "HDD",
  },
  {
    city: "Iasi (Iași)",
    airport: "Iași International Airport",
    country: "Romania",
    iata: "IAS",
  },
  {
    city: " Ibadan",
    airport: "Ibadan Airport",
    country: "Nigeria",
    iata: "IBA",
  },
  {
    city: "Ibagué",
    airport: " Perales Airport",
    country: "Colombia",
    iata: "IBE",
  },
  {
    city: "Ibiza (island)",
    airport: "Ibiza Airport",
    country: "Ibiza, Spain",
    iata: "IBZ",
  },
  {
    city: "Idaho Falls (ID)",
    airport: " Idaho Falls Regional Airport",
    country: "USA",
    iata: "IDA",
  },
  {
    city: "Iejima (island)",
    airport: "Iejima Airport",
    country: "Okinawa, Japan",
    iata: "IEJ",
  },
  {
    city: "Iguazu",
    airport: "Cataratas del Iguazú International Airport",
    country: "Argentina",
    iata: "IGR",
  },
  {
    city: "Ijuí",
    airport: " Ijuí Airport",
    country: "Brazil",
    iata: "IJU",
  },
  {
    city: "Ilhéus",
    airport: " Ilhéus Jorge Amado Airport",
    country: "Bahia, Brazil",
    iata: "IOS",
  },
  {
    city: "Ile des Pins (island)",
    airport: "Île des Pins Airport",
    country: "New Caledonia",
    iata: "ILP",
  },
  {
    city: "Ile Ouen (island)",
    airport: "Edmond Cane Airport",
    country: "New Caledonia",
    iata: "IOU",
  },
  {
    city: "Iliamna",
    airport: "Iliamna Airport",
    country: "AK, USA",
    iata: "ILI",
  },
  {
    city: "Ilo",
    airport: " Ilo Airport",
    country: "Peru",
    iata: "ILQ",
  },
  {
    city: "Ilorin",
    airport: " Ilorin Airport",
    country: "Nigeria",
    iata: "ILR",
  },
  {
    city: "Imperatriz",
    airport: " Imperatriz Airport",
    country: "Maranhão, Brazil",
    iata: "IMP",
  },
  {
    city: "Imperial",
    airport: " Imperial County Airport",
    country: "CA, USA",
    iata: "IPL",
  },
  {
    city: " Imphal",
    airport: "Tulihal Airport",
    country: "Manipur, India",
    iata: "IMF",
  },
  {
    city: "Incheon (Seoul)",
    airport: "Incheon International Airport",
    country: "South Korea",
    iata: "ICN",
  },
  {
    city: "Independence",
    airport: "Independence Municipal Airport",
    country: "Kansas, USA",
    iata: "IDP",
  },
  {
    city: "Indianapolis",
    airport: "Indianapolis International Airport",
    country: "IN, USA",
    iata: "IND",
  },
  {
    city: "Ingham",
    airport: "Ingham Airport",
    country: "Australia",
    iata: "IGH",
  },
  {
    city: " Ingolstadt",
    airport: " Ingolstadt Manching Airport  (GA)",
    country: "Germany",
    iata: "IGS",
  },
  {
    city: "Inhambane",
    airport: "Inhambane Airport",
    country: "Mozambique",
    iata: "INH",
  },
  {
    city: "Innisfail",
    airport: " Innisfail Airport",
    country: "Australia",
    iata: "IFL",
  },
  {
    city: "Indore",
    airport: "Devi Ahilyabai Holkar International Airport",
    country: "Madhya Pradesh, India",
    iata: "IDR",
  },
  {
    city: "Innsbruck - Kranebitten",
    airport: " Innsbruck Airport  (Kranebitten Airport)",
    country: "Austria",
    iata: "INN",
  },
  {
    city: "International Falls (MN)",
    airport: "Falls International Airport",
    country: "USA",
    iata: "INL",
  },
  {
    city: "Inuvik",
    airport: "Inuvik (Mike Zubko) Airport",
    country: "NWT, Canada",
    iata: "YEV",
  },
  {
    city: "Invercargill",
    airport: "Invercargill Airport",
    country: "New Zealand",
    iata: "IVC",
  },
  {
    city: "Inverness",
    airport: "Inverness Airport",
    country: "United Kingdom",
    iata: "INV",
  },
  {
    city: "Inyokern",
    airport: "Inyokern Airport",
    country: "CA, USA",
    iata: "IYK",
  },
  {
    city: "Ioannina",
    airport: " Ioannina National Airport",
    country: "Greece",
    iata: "IOA",
  },
  {
    city: " Iowa City",
    airport: " Iowa City Municipal Airport",
    country: "Iowa, USA",
    iata: "IOW",
  },
  {
    city: "Ipatinga",
    airport: "Usiminas Airport",
    country: "Minas Gerais, Brazil",
    iata: "IPN",
  },
  {
    city: "Ipiales",
    airport: " San Luis Airport",
    country: " Colombia",
    iata: "IPI",
  },
  {
    city: "Ipoh",
    airport: "Sultan Azlan Shah Airport",
    country: "Perak, Malaysia",
    iata: "IPH",
  },
  {
    city: "Iqaluit (Frobisher Bay)",
    airport: "Iqaluit Airport",
    country: "Nunavut, Canada",
    iata: "YFB",
  },
  {
    city: "Iquique",
    airport: "Diego Aracena International Airport",
    country: "Chile",
    iata: "IQQ",
  },
  {
    city: "Iquitos",
    airport:
      "Coronel FAP Francisco Secada Vignetta International Airport  (es)",
    country: "Peru",
    iata: "IQT",
  },
  {
    city: "Iringa",
    airport: " Iringa Airport",
    country: "Tanzania",
    iata: "IRI",
  },
  {
    city: "Irkutsk",
    airport: "Irkutsk International Airport",
    country: "Russia",
    iata: "IKT",
  },
  {
    city: "Ischia (island)",
    airport: "nearest airport is Naples",
    country: "Italy",
    iata: "ISH",
  },
  {
    city: "Isfahan (Esfahan)",
    airport: "Shahid Beheshti International Airport  (fa)",
    country: "Iran",
    iata: "IFN",
  },
  {
    city: "Ishigaki",
    airport: "Painushima Ishigaki Airport",
    country: "Japan",
    iata: "ISG",
  },
  {
    city: "Isiro",
    airport: "Matari Airport",
    country: "Democratic Rep. of the Congo",
    iata: "IRP",
  },
  {
    city: "Islamabad",
    airport: "Benazir Bhutto International Airport ",
    country: "Pakistan",
    iata: "ISB",
  },
  {
    city: "Islay",
    airport: "Port Ellen Airport",
    country: "Scotland, United Kingdom",
    iata: "ILY",
  },
  {
    city: "Isle of Man",
    airport: "Isle of Man Airport",
    country: "United Kingdom",
    iata: "IOM",
  },
  {
    city: "Islip (Long Island)",
    airport: "Long Island MacArthur Airport",
    country: "NY, USA",
    iata: "ISP",
  },
  {
    city: "Isparta",
    airport: "Suleyman Demirel Airport",
    country: "Turkey",
    iata: "ISE",
  },
  {
    city: "Istanbul",
    airport: "Metropolitan Area",
    country: "Turkey",
    iata: "IST",
  },
  {
    city: "Istanbul",
    airport: "Sabiha Gökçen International Airport",
    country: "Turkey",
    iata: "SAW",
  },
  {
    city: "Itaituba",
    airport: "Itaituba Airport",
    country: "Pará, Brazil",
    iata: "ITB",
  },
  {
    city: " Itami (near Osaka)",
    airport: "Osaka International Airport (Itami Airport)",
    country: "Japan",
    iata: "ITM",
  },
  {
    city: "Ithaca",
    airport: " Ithaca Tompkins Regional Airport",
    country: "NY, USA",
    iata: "ITH",
  },
  {
    city: "Itumbiara",
    airport: "Itumbiara Airport",
    country: "Goiás, Brazil",
    iata: "ITR",
  },
  {
    city: "Ivalo",
    airport: "Ivalo Airport",
    country: "Finland",
    iata: "IVL",
  },
  {
    city: " Iwo Jima (Iwo To)",
    airport: " Iwo Jima Air Base",
    country: "Bonin Islands, Japan",
    iata: "IWO",
  },
  {
    city: "Ixtapa/Zihuatenejo",
    airport: " Ixtapa-Zihuatanejo International Airport",
    country: "Guerrero, Mexico",
    iata: "ZIH",
  },
  {
    city: "Izhevsk",
    airport: "Izhevsk Airport",
    country: "Udmurt Rep., Russia",
    iata: "IJK",
  },
  {
    city: "Izmir",
    airport: "Adnan Menderes International Airport",
    country: "Turkey",
    iata: "ADB",
  },
  {
    city: "Izumo",
    airport: "Izumo Airport",
    country: "Shimane, Japan",
    iata: "IZO",
  },
  {
    city: "Jabalpur",
    airport: "Jabalpur Airport",
    country: "Madhya Pradesh, India",
    iata: "JLR",
  },
  {
    city: "Jabiru",
    airport: "Jabiru Airport",
    country: "NT, Australia",
    iata: "JAB",
  },
  {
    city: "Jackpot (NV)",
    airport: "Jackpot Airport",
    country: "USA",
    iata: "KPT",
  },
  {
    city: "Jackson ",
    airport: "Jackson County Airport",
    country: "USA",
    iata: "JXN",
  },
  {
    city: "Jackson (MS)",
    airport: "Jackson-Evers International Airport",
    country: "USA",
    iata: "JAN",
  },
  {
    city: "Jackson (MS)",
    airport: "Hawkins Field (GA)",
    country: " USA",
    iata: "HKS",
  },
  {
    city: "Jackson (TN)",
    airport: " McKellar-Sipes Regional Airport",
    country: "USA",
    iata: "MKL",
  },
  {
    city: "Jackson (WY)",
    airport: " Jackson Hole Airport",
    country: "USA",
    iata: "JAC",
  },
  {
    city: "Jacksonville ",
    airport: "Little Rock AFB",
    country: "USA",
    iata: "LRF",
  },
  {
    city: "Jacksonville (FL)",
    airport: "Jacksonville International Airport",
    country: "USA",
    iata: "JAX",
  },
  {
    city: "Jacksonville (FL)",
    airport: "Cecil Field NAS (closed)",
    country: "USA",
    iata: "NZC",
  },
  {
    city: "Jacksonville (FL)",
    airport: "Naval Air Station Jacksonville",
    country: "USA",
    iata: "NIP",
  },
  {
    city: "Jacksonville (FL)",
    airport: "Craig Municipal Airport",
    country: "USA",
    iata: "CRG",
  },
  {
    city: "Jacksonville (IL)",
    airport: "Municipal Airport",
    country: "USA",
    iata: "IJX",
  },
  {
    city: "Jacksonville (NC)",
    airport: "Albert J. Ellis Airport",
    country: "USA",
    iata: "OAJ",
  },
  {
    city: "Jacksonville ",
    airport: " Cherokee County Airport",
    country: "USA",
    iata: "JKV",
  },
  {
    city: "Jacmel",
    airport: " Jacmel Airport",
    country: "Haiti",
    iata: "JAK",
  },
  {
    city: "Jacobabad",
    airport: "Jacobabad Air Base",
    country: "Pakistan",
    iata: "JAG",
  },
  {
    city: "Jacobina",
    airport: "Jacobina Airport",
    country: "Bahia, Brazil",
    iata: "JCM",
  },
  {
    city: "Jacquinot Bay",
    airport: " Jacquinot Bay Airport",
    country: "Papua New Guinea",
    iata: "JAQ",
  },
  {
    city: "Jaffna",
    airport: "Jaffna Airport (Kankesanturai)",
    country: "Sri Lanka",
    iata: "JAF",
  },
  {
    city: "Jagdalpur",
    airport: "Jagdalpur Airport",
    country: "India",
    iata: "JGB",
  },
  {
    city: "Jaipur",
    airport: "Jaipur International Airport (Sanganer Airport)",
    country: "Rajasthan, India",
    iata: "JAI",
  },
  {
    city: "Jaisalmer",
    airport: "Jaisalmer Airport",
    country: "Rajasthan, India",
    iata: "JSA",
  },
  {
    city: "Jakarta",
    airport: "Metropolitan Area",
    country: "Indonesia",
    iata: "JKT",
  },
  {
    city: "Jakarta",
    airport: "Halim Perdanakusuma International Airport",
    country: "Indonesia",
    iata: "HLP",
  },
  {
    city: "Jakarta",
    airport: "Soekarno-Hatta International Airport",
    country: "Indonesia",
    iata: "CGK",
  },
  {
    city: "Jalalabad",
    airport: "Jalalabad Airport",
    country: "Afghanistan",
    iata: "JAA",
  },
  {
    city: "Jalapa",
    airport: "El Lencero Airport",
    country: "Veracruz, Mexico",
    iata: "JAL",
  },
  {
    city: "Jales",
    airport: "Jales Airport",
    country: "Brazil",
    iata: "JLS",
  },
  {
    city: "Jaluit Island (Jaluit Atoll)",
    airport: " Jaluit Airport",
    country: "Marshall Islands",
    iata: "UIT",
  },
  {
    city: "Jamba",
    airport: "Jamba Airport",
    country: "Angola",
    iata: "JMB",
  },
  {
    city: "Jambi",
    airport: "Sultan Taha (Syarifudn) Airport",
    country: "Indonesia",
    iata: "DJB",
  },
  {
    city: "Jambol",
    airport: " Bezmer Air Base",
    country: "Bulgaria",
    iata: "JAM",
  },
  {
    city: "Jamestown (ND)",
    airport: "Jamestown Regional Airport",
    country: "USA",
    iata: "JMS",
  },
  {
    city: "Jamestown ",
    airport: " Chautauqua County-Jamestown Airport",
    country: "USA",
    iata: "JHW",
  },
  {
    city: "Jammu",
    airport: "Jammu Airport (Satwari Airport)",
    country: "Jammu, India",
    iata: "IXJ",
  },
  {
    city: "Jamnagar",
    airport: "Govardhanpur Airport Jamnagar",
    country: "Gujurat, India",
    iata: "JGA",
  },
  {
    city: "Jamshedpur",
    airport: "Sonari Airport",
    country: "India",
    iata: "IXW",
  },
  {
    city: "Janakpur",
    airport: "Janakpur Airport",
    country: "Nepal",
    iata: "JKR",
  },
  {
    city: "Jandakot",
    airport: "Jandakot Airport",
    country: "WA, Australia",
    iata: "JAD",
  },
  {
    city: "Janesville (WI)",
    airport: "Southern Wisconsin Regional Airport  (Rock County Airport)",
    country: "USA",
    iata: "JVL",
  },
  {
    city: "Januaria",
    airport: "Januária Airport",
    country: "Minas Gerais, Brazil",
    iata: "JNA",
  },
  {
    city: "Jaque",
    airport: "Jaqué Airport",
    country: "Panama",
    iata: "JQE",
  },
  {
    city: "Jatai",
    airport: "Jatai Airport",
    country: "Goiás, Brazil",
    iata: "JTI",
  },
  {
    city: "Jauja",
    airport: "Francisco Carle Airport",
    country: "Peru",
    iata: "JAU",
  },
  {
    city: "Jayapura",
    airport: "Sentani Airport",
    country: "Indonesia",
    iata: "DJJ",
  },
  {
    city: "Jeddah",
    airport: "King Abdulaziz International Airport",
    country: "Saudi Arabia",
    iata: "JED",
  },
  {
    city: "Jefferson City (MO)",
    airport: "Jefferson City Memorial Airport (GA)",
    country: "USA",
    iata: "JEF",
  },
  {
    city: "Jeremie",
    airport: "Jeremie Airport",
    country: "Haiti",
    iata: "JEE",
  },
  {
    city: "Jerez de la Frontera/Cadiz",
    airport: "Jerez Airport  (La Parra Airport)",
    country: "Spain",
    iata: "XRY",
  },
  {
    city: "Jersey",
    airport: "Jersey Airport",
    country: "Channel Islands, UK",
    iata: "JER",
  },
  {
    city: "Jerusalem",
    airport: "Atarot Airport (closed)",
    country: "Israel",
    iata: "JRS",
  },
  {
    city: "Jessore",
    airport: "Jessore Airport",
    country: "Bangladesh",
    iata: "JSR",
  },
  {
    city: "Jeypore",
    airport: "Jeypore Airport",
    country: "Odisha, India",
    iata: "PYB",
  },
  {
    city: "Ji'an",
    airport: "Jinggangshan Airport",
    country: "Jiangxi, China",
    iata: "JGS",
  },
  {
    city: "Jiamusi",
    airport: "Jiamusi Airport",
    country: " China",
    iata: "JMU",
  },
  {
    city: "Jiayuguan",
    airport: "Jiayuguan Airport",
    country: " China",
    iata: "JGN",
  },
  {
    city: "Jijel",
    airport: "Jijel Ferhat Abbas Airport",
    country: "Algeria",
    iata: "GJL",
  },
  {
    city: "Jijiga",
    airport: " Jijiga Airport",
    country: "Ethiopia",
    iata: "JIJ",
  },
  {
    city: "Jilin City",
    airport: "Jilin Ertaizi Airport",
    country: "Jilin, China",
    iata: "JIL",
  },
  {
    city: "Jimma",
    airport: "Aba Segud Airport",
    country: "Ethiopia",
    iata: "JIM",
  },
  {
    city: "Jinan",
    airport: " Jinan Yaoqiang International Airport  (cn)",
    country: "Shandong, China",
    iata: "TNA",
  },
  {
    city: "Jingdezhen",
    airport: "Jingdezhen Luojia Airport",
    country: "China",
    iata: "JDZ",
  },
  {
    city: "Jinghong",
    airport: "Xishuangbanna Gasa Airport",
    country: "Jiangxi,  China",
    iata: "JHG",
  },
  {
    city: "Jining",
    airport: "Jining Qufu Airport",
    country: "Shandong, China",
    iata: "JNG",
  },
  {
    city: "Jinja",
    airport: "Jinja Airport",
    country: "Uganda",
    iata: "JIN",
  },
  {
    city: "Jinjiang",
    airport: "Quanzhou Jinjiang Airport  (cn)",
    country: "Fujian, China",
    iata: "JJN",
  },
  {
    city: "Jinka",
    airport: "Baco/Jinka Airport",
    country: "Ethiopia",
    iata: "BCO",
  },
  {
    city: "Jinzhou",
    airport: "Jinzhou Airport",
    country: "Liaoning, China",
    iata: "JNZ",
  },
  {
    city: "Ji-Paraná",
    airport: " Ji-Paraná Airport",
    country: "Rondônia, Brazil",
    iata: "JPR",
  },
  {
    city: "Jipijapa",
    airport: "Jipijapa Airport",
    country: "Ecuador",
    iata: "JIP",
  },
  {
    city: "Jiri",
    airport: "Jiri Airport",
    country: "Nepal",
    iata: "JIR",
  },
  {
    city: "Jiujiang",
    airport: "Jiujiang Lushan Airport",
    country: "Jiangxi, China",
    iata: "JIU",
  },
  {
    city: "Jiwani",
    airport: "Jiwani Airport",
    country: "Pakistan",
    iata: "JIW",
  },
  {
    city: "Joacaba",
    airport: "Joaçaba Airport",
    country: "Brazil",
    iata: "JCB",
  },
  {
    city: " Jixi",
    airport: " Jixi Xingkaihu Airport",
    country: "Heilongjiang, China",
    iata: "JXA",
  },
  {
    city: "Joao Pessoa",
    airport: "Presidente Castro Pinto International Airport",
    country: "Brazil",
    iata: "JPA",
  },
  {
    city: "Jodhpur",
    airport: "Jodhpur Airport",
    country: "India",
    iata: "JDH",
  },
  {
    city: "Jönköping (Joenkoeping)",
    airport: " Jönköping Airport",
    country: "Sweden",
    iata: "JKG",
  },
  {
    city: "Joensuu",
    airport: "Joensuu Airport",
    country: "Finland",
    iata: "JOE",
  },
  {
    city: "Johannesburg",
    airport: "O.R. Tambo International Airport",
    country: "Gauteng, South Africa",
    iata: "JNB",
  },
  {
    city: "Johnson City/Binghamton ",
    airport: "Greater Binghamton Airport",
    country: "USA",
    iata: "BGM",
  },
  {
    city: "Johnston Island",
    airport: "Johnston Atoll Airport",
    country: "U.S. Minor Outlying Islands",
    iata: "JON",
  },
  {
    city: "Johnstown (PA)",
    airport: "Johnstown-Cambria County Airport",
    country: "USA",
    iata: "JST",
  },
  {
    city: "Johor Bahru",
    airport: "Senai International Airport",
    country: "Malaysia",
    iata: "JHB",
  },
  {
    city: "Joinville",
    airport: "Cubatao Airport",
    country: "Brazil",
    iata: "JOI",
  },
  {
    city: "Joliet (IL)",
    airport: "Joliet Regional Airport",
    country: "USA",
    iata: "JOT",
  },
  {
    city: "Jolo",
    airport: "Jolo Airport",
    country: "Sulu, Philippines",
    iata: "JOL",
  },
  {
    city: "Jomsom",
    airport: "Jomsom Airport",
    country: "Nepal",
    iata: "JMO",
  },
  {
    city: "Jonesboro ",
    airport: "Jonesboro Municipal Airport",
    country: "USA",
    iata: "JBR",
  },
  {
    city: "Joplin (MO)",
    airport: "Joplin Regional Airport  (GA)",
    country: "USA",
    iata: "JLN",
  },
  {
    city: "Jorhat",
    airport: "Jorhat Rowriah Airport",
    country: "India",
    iata: "JRH",
  },
  {
    city: "Jos",
    airport: "Jos Yakubu Gowon Airport",
    country: "Nigeria",
    iata: "JOS",
  },
  {
    city: "Jose De San Martin",
    airport: "José de San Martín Airport",
    country: "Argentina",
    iata: "JSM",
  },
  {
    city: "Jouf (Al-Jouf)",
    airport: "Al-Jouf Domestic Airport",
    country: "Saudi Arabia",
    iata: "AJF",
  },
  {
    city: "Juanjui",
    airport: "Juanjuí Airport",
    country: "Peru",
    iata: "JJI",
  },
  {
    city: "Juba",
    airport: "Juba International Airport",
    country: "South Sudan",
    iata: "JUB",
  },
  {
    city: "Juist (island)",
    airport: " Juist Airport (Flugplatz Juist)",
    country: "Lower Saxony, Germany",
    iata: "JUI",
  },
  {
    city: "Juiz De Fora",
    airport: "Juiz de Fora Airport (Serrinha Airport)",
    country: "Minas Gerais, Brazil",
    iata: "JDF",
  },
  {
    city: "Jujuy",
    airport: "Horacio Guzmán International Airport",
    country: "Argentina",
    iata: "JUJ",
  },
  {
    city: "Julia Creek",
    airport: "Julia Creek Airport",
    country: "Australia",
    iata: "JCK",
  },
  {
    city: "Juliaca",
    airport: " Inca Manco Capac Airport",
    country: "Peru",
    iata: "JUL",
  },
  {
    city: "Jumla",
    airport: "Jumla Airport",
    country: "Nepal",
    iata: "JUM",
  },
  {
    city: "Jundah",
    airport: " Jundah Airport",
    country: "Australia",
    iata: "JUN",
  },
  {
    city: "Juneau (AK)",
    airport: "Juneau International Airport",
    country: "USA",
    iata: "JNU",
  },
  {
    city: "Junin",
    airport: "Junín Airport",
    country: "Argentina",
    iata: "JNI",
  },
  {
    city: "Juruena",
    airport: " Juruena Airport",
    country: "Mato Grosso, Brazil",
    iata: "JRN",
  },
  {
    city: "Juticalpa",
    airport: " Juticalpa Airport",
    country: "Honduras",
    iata: "JUT",
  },
  {
    city: "Jwaneng",
    airport: "Jwaneng Airport",
    country: "Botswana",
    iata: "JWA",
  },
  {
    city: "Jyväskylä (Jyvaskyla)",
    airport: "Jyväskylä Airport",
    country: "Finland",
    iata: "JYV",
  },
  {
    city: "Kabul",
    airport: "Hamid Karzai International Airport",
    country: "Afghanistan",
    iata: "KBL",
  },
  {
    city: "Kagoshima",
    airport: "Kagoshima Airport",
    country: "Kyushu, Japan",
    iata: "KOJ",
  },
  {
    city: "Kahramanmaras",
    airport: " Kahramanmaras Airport",
    country: "Turkey",
    iata: "KCM",
  },
  {
    city: "Kahului (HI)",
    airport: "Kahului Airport",
    country: "USA",
    iata: "OGG",
  },
  {
    city: "Kajaani",
    airport: "Kajaani Airport",
    country: "Finland",
    iata: "KAJ",
  },
  {
    city: "Kalamata",
    airport: "Kalamata Airport",
    country: "Greece",
    iata: "KLX",
  },
  {
    city: "Kalamazoo/Battle Creek ",
    airport: " Kalamazoo/Battle Creek International",
    country: "USA",
    iata: "AZO",
  },
  {
    city: "Kalgoorlie",
    airport: "Kalgoorlie-Boulder Airport",
    country: "WA, Australia",
    iata: "KGI",
  },
  {
    city: "Kalibo",
    airport: "Kalibo International Airport",
    country: " Boracay, Aklan, Philippines",
    iata: "KLO",
  },
  {
    city: "Kaliningrad",
    airport: "Khrabrovo Airport",
    country: "Russia",
    iata: "KGD",
  },
  {
    city: "Kalispell (MT)",
    airport: "Glacier Park International Airport",
    country: "USA",
    iata: "FCA",
  },
  {
    city: "Kalmar",
    airport: "Kalmar Öland Airport",
    country: "Sweden",
    iata: "KLR",
  },
  {
    city: "Kamloops (BC)",
    airport: "Kamloops Airport",
    country: "BC, Canada",
    iata: "YKA",
  },
  {
    city: "Kamuela (HI)",
    airport: " Waimea-Kohala Airport",
    country: "USA",
    iata: "MUE",
  },
  {
    city: " Kandahar",
    airport: " Kandahar International Airport",
    country: "Afghanistan",
    iata: "KDH",
  },
  {
    city: "Kano",
    airport: "Mallam Aminu Kano International Airport",
    country: "Nigeria",
    iata: "KAN",
  },
  {
    city: "Kanpur",
    airport: "Kanpur Airport",
    country: "Uttar Pradesh, India",
    iata: "KNU",
  },
  {
    city: "Kansas City (MO)",
    airport: "Kansas City International Airport",
    country: "USA",
    iata: "MCI",
  },
  {
    city: "Kaohsiung",
    airport: " Kaohsiung International Airport",
    country: "Taiwan",
    iata: "KHH",
  },
  {
    city: "Kapalua, Lahaina (HI)",
    airport: "Kapalua Airport",
    country: "USA",
    iata: "JHM",
  },
  {
    city: "Karachi",
    airport: "Jinnah International Airport",
    country: "Pakistan",
    iata: "KHI",
  },
  {
    city: "Karlsruhe/Baden-Baden",
    airport: "Baden Airpark",
    country: "Germany",
    iata: "FKB",
  },
  {
    city: "Karlstad",
    airport: "Karlstad Airport",
    country: "Sweden",
    iata: "KSD",
  },
  {
    city: "Karpathos (island)",
    airport: "Karpathos Airport",
    country: "Greece",
    iata: "AOK",
  },
  {
    city: "Karratha",
    airport: "Karratha Airport",
    country: "WA, Australia",
    iata: "KTA",
  },
  {
    city: "Kaunas",
    airport: "Kaunas International Airport",
    country: "Lithuania",
    iata: "KUN",
  },
  {
    city: "Kayes",
    airport: "Kayes Airport",
    country: "Mali",
    iata: "KYS",
  },
  {
    city: "Karumba",
    airport: "Karumba Airport",
    country: "Australia",
    iata: "KRB",
  },
  {
    city: "Karup",
    airport: "Karup Airport",
    country: "Denmark",
    iata: "KRP",
  },
  {
    city: " Kashechewan",
    airport: " Kashechewan Airport",
    country: "Ontario, Canada",
    iata: "ZKE",
  },
  {
    city: "Kassala",
    airport: "Kassala Airport",
    country: "Sudan",
    iata: " KSL",
  },
  {
    city: "Katherine",
    airport: "Tindal Airport",
    country: "NT, Australia",
    iata: "KTR",
  },
  {
    city: "Kathmandu",
    airport: "Tribhuvan International Airport",
    country: "Nepal",
    iata: "KTM",
  },
  {
    city: "Katima Mulilo/Mpacha",
    airport: "Katima Mulilo Airport (Mpacha Airport)",
    country: "Namibia",
    iata: "MPA",
  },
  {
    city: "Kauhajoki",
    airport: " Kauhajoki Airport",
    country: "Finland",
    iata: "KHJ",
  },
  {
    city: "Kaunakakai (HI)",
    airport: "Molokai Airport",
    country: "USA",
    iata: "MKK",
  },
  {
    city: "Kavalla",
    airport: "Alexander the Great International Airport",
    country: "Greece",
    iata: "KVA",
  },
  {
    city: "Kayseri (province)",
    airport: "Erkilet International Airport",
    country: "Turkey",
    iata: "ASR",
  },
  {
    city: "Kazan",
    airport: "Kazan International Airport",
    country: "Tatarstan, Russia",
    iata: "KZN",
  },
  {
    city: "Keetmanshoop",
    airport: "Keetmanshoop Airport",
    country: "Namibia",
    iata: "KMP",
  },
  {
    city: "Keflavík (Reykjavík)",
    airport: "Reykjavík-Keflavík International Airport",
    country: "Iceland",
    iata: "KEF",
  },
  {
    city: "Kelowna",
    airport: "Kelowna International Airport",
    country: "BC, Canada",
    iata: "YLW",
  },
  {
    city: "Kemi/Tornio",
    airport: "Kemi-Tornio Airport",
    country: "Finland",
    iata: "KEM",
  },
  {
    city: "Kenai (AK)",
    airport: " Kenai Municipal Airport",
    country: "USA",
    iata: "ENA",
  },
  {
    city: "Kent/Manston",
    airport: "Kent International Airport (currently closed)",
    country: "United Kingdom",
    iata: "MSE",
  },
  {
    city: "Kerry County",
    airport: " Kerry Airport (Farranfore Airport)",
    country: "Ireland",
    iata: "KIR",
  },
  {
    city: "Ketchikan (AK)",
    airport: "Ketchikan International Airport",
    country: "USA",
    iata: "KTN",
  },
  {
    city: "Key West (FL)",
    airport: "Key West International Airport",
    country: "USA",
    iata: "EYW",
  },
  {
    city: "Khabarovsk",
    airport: "Khabarovsk Novy Airport",
    country: "Russia",
    iata: "KHV",
  },
  {
    city: "Khamis Mushayat",
    airport: "Abha Regional Airport",
    country: "Saudi Arabia",
    iata: "AHB",
  },
  {
    city: "Kharga (Kharga Oasis)",
    airport: "El Kharga Airport",
    country: "Egypt",
    iata: "UVL",
  },
  {
    city: "Kharkov (Kharkiv)",
    airport: "Kharkiv International Airport",
    country: "Ukraine",
    iata: "HRK",
  },
  {
    city: "Khartoum",
    airport: "Khartoum International Airport",
    country: "Sudan",
    iata: "KRT",
  },
  {
    city: "Khuzdar",
    airport: "Khuzdar Airport",
    country: "Pakistan",
    iata: "KDD",
  },
  {
    city: "Kiel",
    airport: "Airport Kiel  (Kiel-Holtenau)",
    country: "Germany",
    iata: "KEL",
  },
  {
    city: "Kiev",
    airport: "Boryspil International Airport",
    country: "Ukraine",
    iata: "KBP",
  },
  {
    city: "Kiev",
    airport: "International Airport Kiev",
    country: "Ukraine",
    iata: "IEV",
  },
  {
    city: "Kigali",
    airport: "Kigali International Airport",
    country: "Rwanda",
    iata: "KGL",
  },
  {
    city: " Kilimanjaro",
    airport: "Kilimanjaro International Airport",
    country: "Tanzania",
    iata: "JRO",
  },
  {
    city: "Killeem ",
    airport: " Skylark Field",
    country: "USA",
    iata: "ILE",
  },
  {
    city: "Kimberley",
    airport: "Kimberley Airport",
    country: "South Africa",
    iata: "KIM",
  },
  {
    city: "King Island",
    airport: "King Island Airport",
    country: "Australia",
    iata: "KNS",
  },
  {
    city: "King Salomon (AK)",
    airport: "King Salmon Airport",
    country: "USA",
    iata: "AKN",
  },
  {
    city: "Kingscote",
    airport: " Kingscote Airport",
    country: "Australia",
    iata: "KGC",
  },
  {
    city: "Kingston",
    airport: "Norman Manley International Airport",
    country: "Jamaica",
    iata: "KIN",
  },
  {
    city: "Kingston (NC)",
    airport: "Kinston Regional Jetport",
    country: "USA",
    iata: "ISO",
  },
  {
    city: "Kingstown",
    airport: "E. T. Joshua Airport",
    country: "Saint Vincent and the Grenadines",
    iata: "SVD",
  },
  {
    city: "Kinshasa",
    airport: " Kinshasa International (N'Djili Airport)",
    country: "Congo (DRC)",
    iata: "FIH",
  },
  {
    city: "Kiritimati (island)",
    airport: "Cassidy International Airport",
    country: "Kiribati",
    iata: "CXI",
  },
  {
    city: "Kirkenes",
    airport: " Kirkenes Airport, Høybuktmoen",
    country: "Norway",
    iata: "KKN",
  },
  {
    city: "Kirkuk",
    airport: " Kirkuk Air Base (closed)",
    country: "Iraq",
    iata: "KIK",
  },
  {
    city: "Kiruna",
    airport: "Kiruna Airport",
    country: "Sweden",
    iata: "KRN",
  },
  {
    city: "Kirkwall (Orkney Islands)",
    airport: " Kirkwall Airport",
    country: "United Kingdom",
    iata: "KOI",
  },
  {
    city: "Kisangani",
    airport: " Bangoka International Airport",
    country: "Congo (DRC)",
    iata: "FKI",
  },
  {
    city: "Kish Island",
    airport: "Kish International Airport",
    country: "Iran",
    iata: "KIH",
  },
  {
    city: "Kitakyushu",
    airport: "Kitakyushu Airport",
    country: "Kyushu, Japan",
    iata: "KKJ",
  },
  {
    city: "Kittilä",
    airport: "Kittilä Airport",
    country: "Finland",
    iata: "KTT",
  },
  {
    city: "Kitwe",
    airport: "Southdowns Airport",
    country: "Zambia",
    iata: "KIW",
  },
  {
    city: "Klagenfurt",
    airport: "Kärnten Airport",
    country: "Austria",
    iata: "KLU",
  },
  {
    city: "Klamath Fall (OR)",
    airport: " Klamath Falls Airport",
    country: "USA",
    iata: "LMT",
  },
  {
    city: "Klawock (AK)",
    airport: "Klawock Airport",
    country: "USA",
    iata: "KLW",
  },
  {
    city: "Kleinsee (Kleinzee)",
    airport: "Kleinzee Airport",
    country: "South Africa",
    iata: "KLZ",
  },
  {
    city: "Knock",
    airport: "Ireland West Airport Knock",
    country: "Ireland",
    iata: "NOC",
  },
  {
    city: "Knoxville (TN)",
    airport: "McGhee Tyson Airport",
    country: "USA",
    iata: "TYS",
  },
  {
    city: "Kobe",
    airport: " Kobe Airport",
    country: "Japan",
    iata: "UKB",
  },
  {
    city: "Kōchi (Kochi)",
    airport: "Kōchi Ryōma Airport",
    country: "Kochi, Japan",
    iata: "KCZ",
  },
  {
    city: "Kodiak (AK)",
    airport: "Kodiak Airport",
    country: "USA",
    iata: "ADQ",
  },
  {
    city: "Kohat",
    airport: "Kohat Airport",
    country: "Pakistan",
    iata: "OHT",
  },
  {
    city: "Kokkola/Pietarsaari",
    airport: "Kokkola-Pietarsaari Airport",
    country: "Finland",
    iata: "KOK",
  },
  {
    city: "Komatsu",
    airport: " Komatsu Airport (Kanazawa Airport)",
    country: "Japan",
    iata: "KMQ",
  },
  {
    city: "Kona (HI)",
    airport: " Kona International Airport",
    country: "USA",
    iata: "KOA",
  },
  {
    city: "Konya",
    airport: " Konya Airport",
    country: "Turkey",
    iata: "KYA",
  },
  {
    city: "Korhogo",
    airport: "Korhogo Airport",
    country: "Cote d'Ivoire",
    iata: "HGO",
  },
  {
    city: "Kos (island)",
    airport: " Kos Island International Airport",
    country: "Greece",
    iata: "KGS",
  },
  {
    city: "Kosrae",
    airport: "Kosrae International Airport",
    country: "Micronesia",
    iata: "KSA",
  },
  {
    city: "Kota Kinabalu",
    airport: " Kota Kinabalu International Airport",
    country: "Malaysia",
    iata: "BKI",
  },
  {
    city: "Kotzbue (AK)",
    airport: "Ralph Wien Memorial Airport",
    country: "USA",
    iata: "OTZ",
  },
  {
    city: "Kowanyama",
    airport: "Kowanyama Airport",
    country: "Australia",
    iata: "KWM",
  },
  {
    city: "Krakow (Cracow)",
    airport: "John Paul II International Airport",
    country: "Poland",
    iata: "KRK",
  },
  {
    city: "Krasnoyarsk",
    airport: "Yemelyanovo Krasnoyarsk Airport",
    country: "Russia",
    iata: "KJA",
  },
  {
    city: "Kristiansand",
    airport: "Kristiansand Airport, Kjevik",
    country: "Norway",
    iata: "KRS",
  },
  {
    city: "Kristianstad",
    airport: "Kristianstad Airport",
    country: "Sweden",
    iata: "KID",
  },
  {
    city: "Kristiansund",
    airport: "Kristiansund Airport, Kvernberget",
    country: "Norway",
    iata: "KSU",
  },
  {
    city: "Kuala Lumpur",
    airport: "Kuala Lumpur International Airport",
    country: "Malaysia",
    iata: "KUL",
  },
  {
    city: "Kuala Lumpur",
    airport: "Sultan Abdul Aziz Shah",
    country: "Malaysia",
    iata: "SZB",
  },
  {
    city: "Kuala Namu (Medan)",
    airport: "Kuala Namu International Airport",
    country: "North Sumatra, Indonesia",
    iata: "KNO",
  },
  {
    city: "Kuantan",
    airport: "Sultan Haji Ahmad Shah Airport",
    country: "Pahang, Malaysia",
    iata: "KUA",
  },
  {
    city: "Kuching",
    airport: " Kuching International Airport",
    country: "Sarawak, Malaysia",
    iata: "KCH",
  },
  {
    city: "Kumamoto",
    airport: "Kumamoto Airport",
    country: "Japan",
    iata: "KMJ",
  },
  {
    city: "Kunming",
    airport: "Kunming Changshui International Airport",
    country: " Yunnan, China",
    iata: "KMG",
  },
  {
    city: "Kununurra",
    airport: " Kununurra Airport",
    country: "Australia",
    iata: "KNX",
  },
  {
    city: "Kuopio",
    airport: " Kuopio Airport",
    country: "Finland",
    iata: "KUO",
  },
  {
    city: "Kushiro",
    airport: "Kushiro Airport",
    country: "Japan",
    iata: "KUH",
  },
  {
    city: "Kuujjuaq (Fort Chimo)",
    airport: "Kuujjuaq Airport",
    country: "Quebec, Canada",
    iata: "YVP",
  },
  {
    city: "Kuujjuarapik",
    airport: "Kuujjuarapik Airport",
    country: "Quebec, Canada",
    iata: "YGW",
  },
  {
    city: "Kuusamo",
    airport: "Kuusamo Airport",
    country: "Finland",
    iata: "KAO",
  },
  {
    city: "Kuwait City",
    airport: "Kuwait International Airport",
    country: "Kuwait",
    iata: "KWI",
  },
  {
    city: "Labe",
    airport: " Tata Airport (Labé Airport)",
    country: "Guinea",
    iata: "LEK",
  },
  {
    city: "Labuan",
    airport: "Labuan Airport",
    country: "Sabah, Malaysia",
    iata: "LBU",
  },
  {
    city: "Lac Brochet (MB)",
    airport: " Lac Brochet Airport",
    country: "Manitoba, Canada",
    iata: "XLB",
  },
  {
    city: "La Crosse (WI)",
    airport: "La Crosse Regional Airport",
    country: "USA",
    iata: "LSE",
  },
  {
    city: "Lae",
    airport: "Lae Nadzab Airport",
    country: "Papua New Guinea",
    iata: "LAE",
  },
  {
    city: "La Rochelle",
    airport: "La Rochelle – Île de Ré Airport",
    country: "France",
    iata: "LRH",
  },
  {
    city: "Lafayette (IN)",
    airport: " Purdue University Airport (no commercial service)",
    country: "USA",
    iata: "LAF",
  },
  {
    city: "Lafayette (LA)",
    airport: "Lafayette Regional Airport",
    country: "USA",
    iata: "LFT",
  },
  {
    city: "Lagos",
    airport: "Murtala Muhammed International Airport",
    country: "Nigeria",
    iata: "LOS",
  },
  {
    city: "Lahore",
    airport: "Allama Iqbal International Airport",
    country: "Pakistan",
    iata: "LHE",
  },
  {
    city: "Lake Balaton",
    airport: "Hévíz–Balaton Airport",
    country: "Hungary",
    iata: "SOB",
  },
  {
    city: "Lake Charles (LA)",
    airport: "Lake Charles Regional Airport",
    country: "USA",
    iata: "LCH",
  },
  {
    city: "Lake Havasu City, (AZ)",
    airport: "Lake Havasu City Airport",
    country: "USA",
    iata: "HII",
  },
  {
    city: "Lake Tahoe (CA)",
    airport: "Lake Tahoe Airport",
    country: "USA",
    iata: "TVL",
  },
  {
    city: "Lakselv",
    airport: "Lakselv Airport, Banak",
    country: "Norway",
    iata: "LKL",
  },
  {
    city: "Lambarene",
    airport: "Lambaréné Airport",
    country: "Gabon",
    iata: "LBQ",
  },
  {
    city: "Lamezia Terme",
    airport: "Lamezia Terme International Airport",
    country: "Italy",
    iata: "SUF",
  },
  {
    city: "Lampedusa (island)",
    airport: "Lampedusa Airport",
    country: "Italy",
    iata: "LMP",
  },
  {
    city: "Lanai City (HI)",
    airport: " Lanai Airport",
    country: "USA",
    iata: "LNY",
  },
  {
    city: "Lancaster (PA)",
    airport: "Lancaster Airport",
    country: "USA",
    iata: "LNS",
  },
  {
    city: "Land's End",
    airport: "Land's End Airport",
    country: "United Kingdom",
    iata: "LEQ",
  },
  {
    city: "Langkawi (islands)",
    airport: " Langkawi International Airport",
    country: "Malaysia",
    iata: "LGK",
  },
  {
    city: "Lannion",
    airport: " Lannion - Côte de Granit Airport",
    country: "France",
    iata: "LAI",
  },
  {
    city: "Lanseria",
    airport: " Lanseria Airport",
    country: "South Africa",
    iata: "HLA",
  },
  {
    city: "Lansing ",
    airport: "Capital Region International Airport",
    country: "USA",
    iata: "LAN",
  },
  {
    city: "La Paz",
    airport: "El Alto International Airport",
    country: "Bolivia",
    iata: "LPB",
  },
  {
    city: "La Paz",
    airport: "La Paz International",
    country: "Mexico",
    iata: "LAP",
  },
  {
    city: "Lappeenranta",
    airport: " Lappeenranta Airport",
    country: "Finland",
    iata: "LPP",
  },
  {
    city: "Laramie (WY)",
    airport: " Laramie Regional Airport",
    country: "USA",
    iata: "LAR",
  },
  {
    city: "Laredo ",
    airport: " Laredo International Airport",
    country: "USA",
    iata: "LRD",
  },
  {
    city: "Larnaca",
    airport: " Larnaca International Airport",
    country: "Cyprus",
    iata: "LCA",
  },
  {
    city: "Las Palmas",
    airport: "Gran Canaria International Airport",
    country: "Canary Islands, Spain",
    iata: "LPA",
  },
  {
    city: "Las Vegas (NV)",
    airport: "McCarran International Airport",
    country: "USA",
    iata: "LAS",
  },
  {
    city: "Latrobe (near Pittsburgh) (PA)",
    airport: "Arnold Palmer Regional Airport",
    country: "USA",
    iata: "LBE",
  },
  {
    city: "Launceston",
    airport: "Launceston Airport",
    country: "Australia",
    iata: "LST",
  },
  {
    city: "Laverton",
    airport: "Laverton Airport",
    country: "WA, Australia",
    iata: "LVO",
  },
  {
    city: "Lawton (OK)",
    airport: " Lawton-Fort Sill Regional Airport",
    country: "USA",
    iata: "LAW",
  },
  {
    city: "Lazaro Cardenas",
    airport: " Lázaro Cárdenas Airport",
    country: "Mexico",
    iata: "LZC",
  },
  {
    city: "Leaf Rapids",
    airport: "Leaf Rapids Airport",
    country: "Manitoba, Canada",
    iata: "YLR",
  },
  {
    city: "Learmouth (Exmouth)",
    airport: "Learmonth Airport",
    country: "WA, Australia",
    iata: "LEA",
  },
  {
    city: "Lebanon (NH)",
    airport: " Lebanon Municipal Airport",
    country: "USA",
    iata: "LEB",
  },
  {
    city: "Leeds/Bradford",
    airport: " Leeds Bradford International Airport",
    country: "United Kingdom",
    iata: "LBA",
  },
  {
    city: "Leinster",
    airport: "Leinster Airport",
    country: "WA, Australia",
    iata: "LER",
  },
  {
    city: "Leipzig",
    airport: "Leipzig/Halle Airport",
    country: "Germany",
    iata: "LEJ",
  },
  {
    city: "Lelystad",
    airport: "Lelystad Airport",
    country: "Netherlands",
    iata: "LEY",
  },
  {
    city: "Leon",
    airport: "Guanajuato International Airport",
    country: "Mexico",
    iata: "BJX",
  },
  {
    city: "Leonora",
    airport: " Leonora Airport",
    country: "WA, Australia",
    iata: "LNO",
  },
  {
    city: "Lerwick/Tingwall (Shetland Islands)",
    airport: "Tingwall Airport",
    country: "United Kingdom",
    iata: "LWK",
  },
  {
    city: " Lewisburg (WV)",
    airport: "Greenbrier Valley Airport",
    country: "USA",
    iata: "LWB",
  },
  {
    city: "Lewiston (ID)",
    airport: " Lewiston-Nez Perce County Airport",
    country: "USA",
    iata: "LWS",
  },
  {
    city: "Lewistown (MT)",
    airport: "Lewistown Municipal Airport",
    country: "USA",
    iata: "LWT",
  },
  {
    city: "Lexington (KY)",
    airport: " Blue Grass Airport",
    country: "USA",
    iata: "LEX",
  },
  {
    city: "Lhasa",
    airport: "Lhasa Gonggar Airport",
    country: "Tibet (China)",
    iata: "LXA",
  },
  {
    city: "Libreville",
    airport: "Libreville Leon M’ba International Airport",
    country: "Gabon",
    iata: "LBV",
  },
  {
    city: "Lidkoeping",
    airport: "Lidköping-Hovby Airport",
    country: "Sweden",
    iata: "LDK",
  },
  {
    city: "Liege",
    airport: "Liège Airport  (Bierset Airport)",
    country: "Belgium",
    iata: "LGG",
  },
  {
    city: "Lifou",
    airport: "Ouanaham Airport",
    country: "Loyalty Islands, New Caledonia",
    iata: "LIF",
  },
  {
    city: "Lihue (HI)",
    airport: "Lihue Airport",
    country: "USA",
    iata: "LIH",
  },
  {
    city: "Lille",
    airport: "Lille Lesquin International Airport",
    country: "France",
    iata: "LIL",
  },
  {
    city: "Lilongwe",
    airport: "Lilongwe International (Kumuzu Airport)",
    country: "Malawi",
    iata: "LLW",
  },
  {
    city: "Lima",
    airport: "Jorge Chávez International Airport",
    country: "Peru",
    iata: "LIM",
  },
  {
    city: "Limoges",
    airport: " Bellegarde Airport",
    country: "France",
    iata: "LIG",
  },
  {
    city: "Lincoln (NE)",
    airport: "Lincoln Airport",
    country: "USA",
    iata: "LNK",
  },
  {
    city: "Lindeman Island",
    airport: "Lindeman Island Airport",
    country: "Queensland, Australia",
    iata: "LDC",
  },
  {
    city: "Linz",
    airport: "Linz Airport  (Blue Danube Airport)",
    country: "Austria",
    iata: "LNZ",
  },
  {
    city: "Lisala",
    airport: " Lisala Airport",
    country: "Congo (DRC)",
    iata: "LIQ",
  },
  {
    city: "Lisbon",
    airport: "Lisbon Airport  (Aeroportos Lisboa)",
    country: "Portugal",
    iata: "LIS",
  },
  {
    city: "Lismore",
    airport: "Lismore Airport",
    country: "NSW, Australia",
    iata: "LSY",
  },
  {
    city: "Little Rock ",
    airport: "Little Rock Clinton National Airport",
    country: "USA",
    iata: "LIT",
  },
  {
    city: "Liverpool",
    airport: "Liverpool John Lennon Airport",
    country: "United Kingdom",
    iata: "LPL",
  },
  {
    city: "Lizard Island",
    airport: "Lizard Island Airport",
    country: "Queensland, Australia",
    iata: "LZR",
  },
  {
    city: "Ljubljana",
    airport: "Ljubljana Jože Pučnik Airport",
    country: "Slovenia",
    iata: "LJU",
  },
  {
    city: "Lobamba/Manzini",
    airport: "Matsapha Airport",
    country: "Swaziland",
    iata: "MTS",
  },
  {
    city: "Lockhart River",
    airport: "Lockhart River Airport",
    country: "Queensland, Australia",
    iata: "IRG",
  },
  {
    city: "Lomé",
    airport: "Lomé-Tokoin International Airport  (Gnassingbé Eyadéma)",
    country: "Togo",
    iata: "LFW",
  },
  {
    city: "London",
    airport: "London Heathrow Airport",
    country: "United Kingdom",
    iata: "LHR",
  },
  {
    city: "London",
    airport: " London Gatwick Airport",
    country: "United Kingdom",
    iata: "LGW",
  },
  {
    city: "London",
    airport: "Metropolitan Area",
    country: "United Kingdom",
    iata: "LON",
  },
  {
    city: "London",
    airport: "London City Airport",
    country: "United Kingdom",
    iata: "LCY",
  },
  {
    city: "London",
    airport: "London Luton Airport",
    country: "United Kingdom",
    iata: "LTN",
  },
  {
    city: "London",
    airport: "London Stansted Airport",
    country: "United Kingdom",
    iata: "STN",
  },
  {
    city: "London",
    airport: " London Southend Airport",
    country: "United Kingdom",
    iata: "SEN",
  },
  {
    city: "London",
    airport: " London International Airport",
    country: "Ontario, Canada",
    iata: "YXU",
  },
  {
    city: "Long Beach (CA)",
    airport: "Long Beach Airport  (Daugherty Field)",
    country: "USA",
    iata: "LGB",
  },
  {
    city: "Longreach",
    airport: "Longreach Airport",
    country: "Australia",
    iata: "LRE",
  },
  {
    city: "Longview/Kilgore ",
    airport: "East Texas Regional Airport",
    country: "USA",
    iata: "GGG",
  },
  {
    city: "Longyearbyen",
    airport: "Svalbard Airport, Longyear",
    country: "Svalbard, Norway",
    iata: "LYR",
  },
  {
    city: "Loreto",
    airport: "Loreto International Airport",
    country: "Mexico",
    iata: "LTO",
  },
  {
    city: "Lorient",
    airport: "Lorient South Brittany Airport",
    country: "France",
    iata: "LRT",
  },
  {
    city: "Los Angeles (CA)",
    airport: "Los Angeles International Airport",
    country: "USA",
    iata: "LAX",
  },
  {
    city: "Los Cabos",
    airport: "Los Cabos International Airport",
    country: "Mexico",
    iata: "SJD",
  },
  {
    city: "Los Mochis",
    airport: " Federal del Valle del Fuerte International",
    country: "Mexico",
    iata: "LMM",
  },
  {
    city: "Los Rodeos",
    airport: " Tenerife North Airport  (Los Rodeos Airport)",
    country: "Teneriffa/Spain",
    iata: "TFN",
  },
  {
    city: "Losinj (island)",
    airport: "Mali Losinj Airport",
    country: "Croatia (Hrvatska)",
    iata: "LSZ",
  },
  {
    city: "Lourdes/Tarbes",
    airport: " Tarbes-Lourdes-Pyrenees Airport",
    country: "France",
    iata: "LDE",
  },
  {
    city: "Louisville (KY)",
    airport: "Louisville International Airport",
    country: "USA",
    iata: "SDF",
  },
  {
    city: "Luanda",
    airport: "Quatro de Fevereiro Airport",
    country: "Angola",
    iata: "LAD",
  },
  {
    city: "Lubbock ",
    airport: "Lubbock Preston Smith International ",
    country: "USA",
    iata: "LBB",
  },
  {
    city: "Lucknow",
    airport: " Chaudhary Charan Singh International",
    country: "Uttar Pradesh, India",
    iata: "LKO",
  },
  {
    city: "Luederitz",
    airport: "Lüderitz Airport",
    country: "Namibia",
    iata: "LUD",
  },
  {
    city: "Luqa",
    airport: "Malta International Airport ",
    country: "Malta",
    iata: "MLA",
  },
  {
    city: "Lugano",
    airport: "Lugano Airport",
    country: "Switzerland",
    iata: "LUG",
  },
  {
    city: "Lulea (Luleå)",
    airport: "Lulea Airport",
    country: "Sweden",
    iata: "LLA",
  },
  {
    city: "Lumbumbashi",
    airport: "Lubumbashi International Airport",
    country: "Congo (DRC)",
    iata: "FBM",
  },
  {
    city: "Lusaka",
    airport: "Kenneth Kaunda International Airport",
    country: "Zambia",
    iata: "LUN",
  },
  {
    city: "Luxembourg City",
    airport: "Luxembourg – Findel Airport",
    country: "Luxembourg",
    iata: "LUX",
  },
  {
    city: "Luxi (Mangshi)",
    airport: "Dehong Mangshi Airport",
    country: "Yunnan, PR China",
    iata: "LUM",
  },
  {
    city: "Luxor",
    airport: "Luxor International Airport",
    country: "Egypt",
    iata: "LXR",
  },
  {
    city: "Lviv (Lvov/Lemberg)",
    airport: "Lviv Danylo Halytskyi International Airport",
    country: "Ukraine",
    iata: "LWO",
  },
  {
    city: "Lydd",
    airport: "London Ashford International Airport",
    country: "United Kingdom",
    iata: "LYX",
  },
  {
    city: "Lynchburg (VA)",
    airport: " Lynchburg Regional Airport",
    country: "USA",
    iata: "LYH",
  },
  {
    city: "Lyon",
    airport: " Lyon Bron Airport (general aviation)",
    country: "France",
    iata: "LYN",
  },
  {
    city: "Lyon",
    airport: "Lyon-Saint Exupéry Airport  (Satolas)",
    country: "France",
    iata: "LYS",
  },
  {
    city: "Maastricht/Aachen",
    airport: "Maastricht Aachen Airport",
    country: "Netherlands",
    iata: "MST",
  },
  {
    city: "Macaé",
    airport: "Macaé Airport",
    country: "RJ, Brazil",
    iata: "MEA",
  },
  {
    city: "Macapa",
    airport: "Alberto Alcolumbre International Airport",
    country: "Amapá, Brazil",
    iata: "MCP",
  },
  {
    city: "Macau",
    airport: "Macau International Airport",
    country: "Macau, China SAR",
    iata: "MFM",
  },
  {
    city: "Maceio",
    airport: " Zumbi dos Palmares International Airport",
    country: "Alagoas, Brazil",
    iata: "MCZ",
  },
  {
    city: "Mackay",
    airport: " Mackay Airport",
    country: "Australia",
    iata: "MKY",
  },
  {
    city: "Macon (GA)",
    airport: "Middle Georgia Regional Airport",
    country: "USA",
    iata: "MCN",
  },
  {
    city: "Mactan Island (Cebu City)",
    airport: "Mactan-Cebu International Airport",
    country: "Philippines",
    iata: "CEB",
  },
  {
    city: "Madinah",
    airport: "Mohammad Bin Abdulaziz Airport",
    country: "Saudi Arabia",
    iata: "MED",
  },
  {
    city: "Madison (WI)",
    airport: "Dane County Regional Airport",
    country: "USA",
    iata: "MSN",
  },
  {
    city: "Madrid",
    airport: "Barajas International Airport",
    country: "Spain",
    iata: "MAD",
  },
  {
    city: "Magadan",
    airport: "Sokol Airport",
    country: "Russia",
    iata: "GDX",
  },
  {
    city: "Mahé",
    airport: "Seychelles International Airport",
    country: "Seychelles",
    iata: "SEZ",
  },
  {
    city: "Mahon",
    airport: "Mahon Airport",
    country: "Spain",
    iata: "MAH",
  },
  {
    city: "Maitland",
    airport: "Maitland Airport",
    country: "Australia",
    iata: "MTL",
  },
  {
    city: " Mahajanga (Majunga)",
    airport: "Amborovy Airport",
    country: "Madagascar",
    iata: "MJN",
  },
  {
    city: "Majuro",
    airport: "Amata Kabua International Airport",
    country: "Marshall Islands",
    iata: "MAJ",
  },
  {
    city: "Makassar",
    airport: " Sultan Hasanuddin International Airport",
    country: "South Sulawesi, Indonesia",
    iata: "UPG",
  },
  {
    city: "Makung (Magong)",
    airport: "Magong Airport",
    country: "Taiwan",
    iata: "MZG",
  },
  {
    city: "Malabo",
    airport: "Malabo International Airport",
    country: "Equatorial Guinea",
    iata: "SSG",
  },
  {
    city: "Malaga",
    airport: "Pablo Ruiz Picasso Airport",
    country: "Spain",
    iata: "AGP",
  },
  {
    city: "Malatya",
    airport: " Erhac Airport",
    country: "Turkey",
    iata: "MLX",
  },
  {
    city: "Male (Malé)",
    airport: "Malé International Airport",
    country: "Maldives",
    iata: "MLE",
  },
  {
    city: "Malindi",
    airport: "Malindi Airport",
    country: "Kenya",
    iata: "MYD",
  },
  {
    city: "Malmö (Malmoe)",
    airport: "Metropolitan Area",
    country: "Sweden",
    iata: "MMA",
  },
  {
    city: "Malmö (Malmoe)",
    airport: "Malmö Airport",
    country: "Sweden",
    iata: "MMX",
  },
  {
    city: "Man",
    airport: "Man Airport",
    country: "Cote d'Ivoire",
    iata: "MJC",
  },
  {
    city: "Managua",
    airport: "Augusto C. Sandino International Airport",
    country: "Nicaragua",
    iata: "MGA",
  },
  {
    city: "Manaus",
    airport: "Eduardo Gomes International Airport",
    country: "Amazonas, Brazil",
    iata: "MAO",
  },
  {
    city: "Manchester",
    airport: "Manchester Airport",
    country: "United Kingdom",
    iata: "MAN",
  },
  {
    city: "Manchester (NH)",
    airport: "Manchester-Boston Regional Airport",
    country: "USA",
    iata: "MHT",
  },
  {
    city: "Mandalay",
    airport: "Mandalay International Airport (Annisaton)",
    country: "Myanmar",
    iata: "MDL",
  },
  {
    city: "Manguna",
    airport: " Manguna Airport",
    country: "Papua New Guinea",
    iata: "MFO",
  },
  {
    city: "Manihi (island)",
    airport: "Manihi Airport",
    country: "French Polynesia",
    iata: "XMH",
  },
  {
    city: "Manila",
    airport: "Ninoy Aquino International Airport",
    country: "Philippines",
    iata: "MNL",
  },
  {
    city: "Manzanillo",
    airport: " Playa de Oro International Airport",
    country: "Mexico",
    iata: "ZLO",
  },
  {
    city: "Manzhouli",
    airport: "Manzhouli Xijiao Airport",
    country: "Inner Mongolia, China",
    iata: "NZH",
  },
  {
    city: "Maputo",
    airport: "Maputo International Airport",
    country: "Mozambique",
    iata: "MPM",
  },
  {
    city: "Marabá",
    airport: "Marabá Airport",
    country: "Pará, Brazil",
    iata: "MAB",
  },
  {
    city: "Maracaibo",
    airport: " La Chinita International Airport",
    country: "Venezuela",
    iata: "MAR",
  },
  {
    city: "Maradi",
    airport: " Maradi Airport",
    country: "Niger",
    iata: "MFQ",
  },
  {
    city: "Marathon (FL)",
    airport: "Florida Keys Marathon Airport",
    country: "USA",
    iata: "MTH",
  },
  {
    city: "Mar del Plata",
    airport: "Astor Piazzolla International Airport",
    country: "Argentina",
    iata: "MDQ",
  },
  {
    city: "Mardin",
    airport: " Mardin Airport",
    country: "Turkey",
    iata: "MQM",
  },
  {
    city: "Mare",
    airport: "Maré Airport",
    country: "New Caledonia",
    iata: "MEE",
  },
  {
    city: "Margate",
    airport: "Margate Airport",
    country: "South Africa",
    iata: "MGH",
  },
  {
    city: "Margarita (island)",
    airport: "Del Caribe Santiago Mariño International",
    country: "Venezuela",
    iata: "PMV",
  },
  {
    city: "Maribor",
    airport: "Maribor Airport",
    country: "Slovenia",
    iata: "MBX",
  },
  {
    city: "Mariehamn (Maarianhamina)",
    airport: "Mariehamn Airport",
    country: "Finland",
    iata: "MHQ",
  },
  {
    city: "Maroua",
    airport: "Salak Airport",
    country: "Cameroon",
    iata: "MVR",
  },
  {
    city: "Marquette ",
    airport: "Sawyer International Airport",
    country: "USA",
    iata: "MQT",
  },
  {
    city: "Marrakesh",
    airport: "Menara International Airport",
    country: "Morocco",
    iata: "RAK",
  },
  {
    city: "Marsa Alam",
    airport: "Marsa Alam International Airport",
    country: "Egypt",
    iata: "RMF",
  },
  {
    city: "Marsa Matrah (Marsa Matruh)",
    airport: " Mersa Matruh Airport",
    country: "Egypt",
    iata: "MUH",
  },
  {
    city: "Marseille",
    airport: "Marseille Provence Airport",
    country: "France",
    iata: "MRS",
  },
  {
    city: "Marsh Harbour",
    airport: "Marsh Harbour Airport",
    country: "Bahamas",
    iata: "MHH",
  },
  {
    city: "Martha's Vineyard (island) (MA)",
    airport: "Martha's Vineyard Airport",
    country: "USA",
    iata: "MVY",
  },
  {
    city: "Martinsburg (WV)",
    airport: " Eastern WV Regional Airport",
    country: "USA",
    iata: "MRB",
  },
  {
    city: "Maryborough",
    airport: "Maryborough Airport",
    country: "Australia",
    iata: "MBH",
  },
  {
    city: "Maseru",
    airport: " Moshoeshoe International Airport",
    country: "Lesotho",
    iata: "MSU",
  },
  {
    city: "Mason City (IA)",
    airport: "Mason City Municipal Airport",
    country: "USA",
    iata: "MCW",
  },
  {
    city: "Masvingo",
    airport: "Masvingo International Airport",
    country: "Zimbabwe",
    iata: "MVZ",
  },
  {
    city: "Matsumoto/ Nagano",
    airport: " Matsumoto Airport",
    country: "Japan",
    iata: "MMJ",
  },
  {
    city: "Matsuyama",
    airport: "Matsuyama Airport",
    country: "Japan",
    iata: "MYJ",
  },
  {
    city: "Mattoon (IL)",
    airport: "Coles County Memorial Airport",
    country: "USA",
    iata: "MTO",
  },
  {
    city: "Maun",
    airport: " Maun Airport",
    country: "Botswana",
    iata: "MUB",
  },
  {
    city: "Maupiti (island)",
    airport: " Maupiti Airport",
    country: "French Polynesia",
    iata: "MAU",
  },
  {
    city: "Mauritius (island)",
    airport: "Sir Seewoosagur Ramgoolam Int'l ",
    country: "Mauritius",
    iata: "MRU",
  },
  {
    city: "Mayaguez",
    airport: "Eugenio Maria de Hostos Airport",
    country: "Puerto Rico",
    iata: "MAZ",
  },
  {
    city: "Mazatlan",
    airport: "Rafael Buelna International Airport",
    country: "Mexico",
    iata: "MZT",
  },
  {
    city: "McAllen ",
    airport: " McAllen-Miller International Airport",
    country: "USA",
    iata: "MFE",
  },
  {
    city: "Mecca",
    airport: "Mecca East Airport (no airline service)",
    country: "Saudi Arabia",
    iata: "QCA",
  },
  {
    city: "Medan",
    airport: "Polonia Airport (now AFB)",
    country: "Indonesia",
    iata: "MES",
  },
  {
    city: "Medellin",
    airport: " José María Córdova International Airport",
    country: "Colombia",
    iata: "MDE",
  },
  {
    city: "Medford (OR)",
    airport: "Rogue Valley International-Medford Airport",
    country: "USA",
    iata: "MFR",
  },
  {
    city: "Meekatharra",
    airport: " Meekatharra Airport",
    country: "WA, Australia",
    iata: "MKR",
  },
  {
    city: "Melbourne",
    airport: "Melbourne Airport",
    country: "Australia",
    iata: "MEL",
  },
  {
    city: "Melbourne (FL)",
    airport: "Melbourne International Airport",
    country: "USA",
    iata: "MLB",
  },
  {
    city: "Memmingen",
    airport: "Allgäu Airport",
    country: "Germany",
    iata: "FMM",
  },
  {
    city: "Memphis (TN)",
    airport: "Memphis International Airport",
    country: "USA",
    iata: "MEM",
  },
  {
    city: "Mendoza",
    airport: "Francisco Gabrielli International Airport",
    country: "Argentina",
    iata: "MDZ",
  },
  {
    city: "Manado",
    airport: "Sam Ratulangi International Airport",
    country: "Sulawesi, Indonesia",
    iata: "MDC",
  },
  {
    city: "Merauke",
    airport: "Merauke Mopah Airport",
    country: "Papua, Indonesia",
    iata: "MKQ",
  },
  {
    city: "Merced (CA)",
    airport: " Merced Municipal Airport",
    country: "USA",
    iata: "MCE",
  },
  {
    city: "Merida",
    airport: " Manuel Crescencio Rejón International",
    country: "Mexico",
    iata: "MID",
  },
  {
    city: "Meridian (MS)",
    airport: " Key Field",
    country: "USA",
    iata: "MEI",
  },
  {
    city: "Merimbula",
    airport: " Merimbula Airport",
    country: "NSW, Australia",
    iata: "MIM",
  },
  {
    city: "Messina",
    airport: "Messina Airport",
    country: "South Africa",
    iata: "MEZ",
  },
  {
    city: "Metlakatla (AK)",
    airport: "Metlakatla Seaplane Base",
    country: "USA",
    iata: "MTM",
  },
  {
    city: "Metz",
    airport: "Frescaty Airport",
    country: "France",
    iata: "MZM",
  },
  {
    city: "Metz",
    airport: "Metz-Nancy-Lorraine Airport",
    country: "France",
    iata: "ETZ",
  },
  {
    city: "Mexicali",
    airport: "Rodolfo Sánchez Taboada International",
    country: "Mexico",
    iata: "MXL",
  },
  {
    city: "Mexico City",
    airport: "Mexico City International Airport",
    country: "Mexico",
    iata: "MEX",
  },
  {
    city: "Mexico City",
    airport: " Atizapan Airport",
    country: "Mexico",
    iata: "AZP",
  },
  {
    city: "Mexico City",
    airport: "Santa Lucia AFB",
    country: "Mexico",
    iata: "NLU",
  },
  {
    city: "Mfuwe",
    airport: "Mfuwe Airport",
    country: "Zambia",
    iata: "MFU",
  },
  {
    city: "Miami (FL)",
    airport: "Miami International Airport",
    country: "USA",
    iata: "MIA",
  },
  {
    city: "Mianwali",
    airport: "Mianwali AFB",
    country: "Pakistan",
    iata: "MWD",
  },
  {
    city: "Middlemount",
    airport: "Middlemount Airport",
    country: "Queensland, Australia",
    iata: "MMM",
  },
  {
    city: "Midland/Odessa ",
    airport: "Midland International Air and Space Port",
    country: "USA",
    iata: "MAF",
  },
  {
    city: "Midway Island",
    airport: "Henderson Field",
    country: "US Minor Outlying Islands",
    iata: "MDY",
  },
  {
    city: "Mikkeli",
    airport: "Mikkeli Airport",
    country: "Finland",
    iata: "MIK",
  },
  {
    city: "Milan",
    airport: "Metropolitan Area",
    country: "Italy",
    iata: "MIL",
  },
  {
    city: "Milan",
    airport: "Linate Airport",
    country: "Italy",
    iata: "LIN",
  },
  {
    city: "Milan",
    airport: "Malpensa Airport",
    country: "Italy",
    iata: "MXP",
  },
  {
    city: "Mildura",
    airport: " Mildura Airport",
    country: "Victoria, Australia",
    iata: "MQL",
  },
  {
    city: "Miles City (MT)",
    airport: "Miles City Municipal Airport",
    country: "USA",
    iata: "MLS",
  },
  {
    city: "Milford Sound",
    airport: "Milford Sound Airport",
    country: "New Zealand",
    iata: "MFN",
  },
  {
    city: "Milwaukee (WI)",
    airport: "General Mitchell International Airport",
    country: "USA",
    iata: "MKE",
  },
  {
    city: "Minatitlan",
    airport: " Minatitlán/Coatzacoalcos National Airport",
    country: "Mexico",
    iata: "MTT",
  },
  {
    city: "Mineralnye Vody",
    airport: " Mineralnye Vody Airport",
    country: "Russia",
    iata: "MRV",
  },
  {
    city: "Minneapolis (MN)",
    airport: "Saint Paul International Airport",
    country: "USA",
    iata: "MSP",
  },
  {
    city: "Minot (ND)",
    airport: " Minot International Airport",
    country: "USA",
    iata: "MOT",
  },
  {
    city: "Minsk",
    airport: "Minsk International Airport",
    country: "Belarus",
    iata: "MSQ",
  },
  {
    city: "Miri",
    airport: "Miri International Airport",
    country: "Sarawak, Malaysia",
    iata: "MYY",
  },
  {
    city: "Missula (MT)",
    airport: "Missoula International Airport",
    country: "USA",
    iata: "MSO",
  },
  {
    city: "Mitchell (SD)",
    airport: "Mitchell Municipal Airport",
    country: "USA",
    iata: "MHE",
  },
  {
    city: "Miyako Jima (Ryuku Islands)",
    airport: "Miyako Airport",
    country: "Okinawa, Japan",
    iata: "MMY",
  },
  {
    city: "Miyazaki",
    airport: "Miyazaki Airport",
    country: "Miyazaki, Japan",
    iata: "KMI",
  },
  {
    city: "Mkambati",
    airport: "Mkambati Airport",
    country: "Eastern Cape, South Africa",
    iata: "MBM",
  },
  {
    city: "Moanda",
    airport: "Moanda Airport",
    country: "Gabon",
    iata: "MFF",
  },
  {
    city: "Mobile ",
    airport: "Mobile Regional Airport",
    country: "USA",
    iata: "MOB",
  },
  {
    city: "Modesto (CA)",
    airport: "Modesto City-County Airport",
    country: "USA",
    iata: "MOD",
  },
  {
    city: "Moenjodaro",
    airport: "Moenjodaro Airport",
    country: "Pakistan",
    iata: "MJD",
  },
  {
    city: "Mogadishu",
    airport: "Aden Adde International Airport",
    country: "Somalia",
    iata: "MGQ",
  },
  {
    city: "Mokuti Lodge",
    airport: "Mokuti Lodge Airport",
    country: "Namibia",
    iata: "OKU",
  },
  {
    city: "Moline/Quad Cities (IL)",
    airport: "Quad City International Airport",
    country: "USA",
    iata: "MLI",
  },
  {
    city: "Mombasa",
    airport: "Moi International Airport",
    country: "Kenya",
    iata: "MBA",
  },
  {
    city: "Monaco",
    airport: "Monaco Heliport",
    country: "Monaco",
    iata: "MCM",
  },
  {
    city: "Monastir",
    airport: "Monastir Habib Bourguiba International",
    country: "Tunisia",
    iata: "MIR",
  },
  {
    city: "Moncton",
    airport: "Greater Moncton International Airport",
    country: "NB, Canada",
    iata: "YQM",
  },
  {
    city: "Monroe (LA)",
    airport: "Monroe Regional Airport",
    country: "USA",
    iata: "MLU",
  },
  {
    city: "Monrovia",
    airport: "Roberts International Airport",
    country: "Liberia",
    iata: "ROB",
  },
  {
    city: "Monrovia",
    airport: "Spriggs Payne Airport",
    country: "Liberia",
    iata: "MLW",
  },
  {
    city: "Montego Bay",
    airport: " Sangster International Airport",
    country: "Jamaica",
    iata: "MBJ",
  },
  {
    city: "Monterey (CA)",
    airport: "Monterey Regional Airport",
    country: "USA",
    iata: "MRY",
  },
  {
    city: "Monterrey",
    airport: "Monterrey International Airport",
    country: "Nuevo Leon, Mexico",
    iata: "MTY",
  },
  {
    city: "Monterrey",
    airport: "Monterrey Del Norte International",
    country: "Nuevo Leon, Mexico",
    iata: "NTR",
  },
  {
    city: "Montes Claros",
    airport: "Montes Claros Airport",
    country: "Minas Gerais, Brazil",
    iata: "MOC",
  },
  {
    city: "Montevideo",
    airport: "Carrasco International Airport",
    country: "Uruguay",
    iata: "MVD",
  },
  {
    city: "Montgomery ",
    airport: "Montgomery Regional Airport",
    country: "USA",
    iata: "MGM",
  },
  {
    city: "Montpellier",
    airport: "Mediterranée Airport  (Frejorgues)",
    country: "France",
    iata: "MPL",
  },
  {
    city: "Montreal",
    airport: "Metropolitan Area",
    country: "Canada",
    iata: "YMQ",
  },
  {
    city: "Montreal",
    airport: "Pierre Elliott Trudeau International Airport",
    country: "Canada",
    iata: "YUL",
  },
  {
    city: "Montreal",
    airport: " Montréal-Mirabel International Airport (cargo)",
    country: "Canada",
    iata: "YMX",
  },
  {
    city: "Montrose/Tellruide (CO)",
    airport: "Montrose Regional Airport",
    country: "USA",
    iata: "MTJ",
  },
  {
    city: "Moorea (Mo'orea island)",
    airport: " Moorea Airport",
    country: "French Polynesia",
    iata: "MOZ",
  },
  {
    city: "Moranbah",
    airport: "Moranbah Airport",
    country: "Queensland, Australia",
    iata: "MOV",
  },
  {
    city: "Moree",
    airport: " Moree Airport",
    country: "NSW, Australia",
    iata: "MRZ",
  },
  {
    city: "Morelia",
    airport: "Francisco J. Mujica International Airport",
    country: "Michoacán, Mexico",
    iata: "MLM",
  },
  {
    city: "Morgantown (WV)",
    airport: "Morgantown Municipal Airport",
    country: "USA",
    iata: "MGW",
  },
  {
    city: "Morioka",
    airport: "Hanamaki Airport",
    country: "Honshū, Japan",
    iata: "HNA",
  },
  {
    city: "Moroni",
    airport: "Prince Said Ibrahim International Airport",
    country: "Comoros",
    iata: "HAH",
  },
  {
    city: "Moroni",
    airport: "Moroni Iconi Airport (closed)",
    country: "Comoros",
    iata: "YVA",
  },
  {
    city: "Moruya",
    airport: "Moruya Airport",
    country: "NSW, Australia",
    iata: "MYA",
  },
  {
    city: "Moscow",
    airport: "Metropolitan Area",
    country: "Russia",
    iata: "MOW",
  },
  {
    city: "Moscow",
    airport: "Domodedovo International Airport",
    country: "Russia",
    iata: "DME",
  },
  {
    city: "Moscow",
    airport: "Sheremetyevo International Airport",
    country: "Russia",
    iata: "SVO",
  },
  {
    city: "Moscow",
    airport: " Vnukovo International Airport",
    country: "Russia",
    iata: "VKO",
  },
  {
    city: "Moses Lake (WA)",
    airport: "Grant County International Airport",
    country: "USA",
    iata: "MWH",
  },
  {
    city: "Moss",
    airport: "Moss Airport Rygge",
    country: "Norway",
    iata: "RYG",
  },
  {
    city: "Mossel Bay",
    airport: "Mossel Bay Airport",
    country: "South Africa",
    iata: "MZY",
  },
  {
    city: "Mostar",
    airport: "Mostar International Airport",
    country: "Bosnia and Herzegovina",
    iata: "OMO",
  },
  {
    city: "Mosul",
    airport: "Mosul International Airport",
    country: "Iraq",
    iata: "OSM",
  },
  {
    city: "Mouila",
    airport: "Mouila Airport",
    country: "Gabon",
    iata: "MJL",
  },
  {
    city: "Moundou",
    airport: "Moundou Airport",
    country: "Chad",
    iata: "MQQ",
  },
  {
    city: "Mount Cook",
    airport: "Glentanner Aerodrome",
    country: "New Zealand",
    iata: "GTN",
  },
  {
    city: "Mount Gambier",
    airport: "Mount Gambier Airport",
    country: "Australia",
    iata: "MGB",
  },
  {
    city: "Mount Magnet",
    airport: "Mount Magnet Airport",
    country: "Australia",
    iata: "MMG",
  },
  {
    city: "Mthatha (Umtata)",
    airport: "Mthatha Airport",
    country: "South Africa",
    iata: "UTT",
  },
  {
    city: "Mt. Isa",
    airport: "Mount Isa Airport",
    country: "Queensland, Australia",
    iata: "ISA",
  },
  {
    city: "Mt. McKinley (AK)",
    airport: "McKinley National Park Airport",
    country: "USA",
    iata: "MCL",
  },
  {
    city: "Mudanjiang",
    airport: "Mudanjiang Hailang Airport",
    country: "Heilongjiang, China",
    iata: "MDG",
  },
  {
    city: "München (Munich)",
    airport: "Munich Airport",
    country: "Bavaria, Germany",
    iata: "MUC",
  },
  {
    city: "Muenster/Osnabrueck",
    airport: "Münster Osnabrück International Airport",
    country: "NRW, Germany",
    iata: "FMO",
  },
  {
    city: "Mulhouse",
    airport: "EuroAirport Basel-Mulhouse-Freiburg",
    country: "France",
    iata: "MLH",
  },
  {
    city: "Multan",
    airport: "Multan International Airport",
    country: "Pakistan",
    iata: "MUX",
  },
  {
    city: "Murcia",
    airport: "Murcia-San Javier Airport",
    country: "Spain",
    iata: "MJV",
  },
  {
    city: "Murmansk",
    airport: "Murmansk Airport",
    country: "Russia",
    iata: "MMK",
  },
  {
    city: "Mus (Muş)",
    airport: "Muş Airport",
    country: "Turkey",
    iata: "MSR",
  },
  {
    city: "Muscat",
    airport: "Muscat International Airport  (Seeb)",
    country: "Oman",
    iata: "MCT",
  },
  {
    city: "Muscle Shoals ",
    airport: "Northwest Alabama Regional Airport",
    country: "USA",
    iata: "MSL",
  },
  {
    city: "Muskegon ",
    airport: " Muskegon County Airport",
    country: "USA",
    iata: "MKG",
  },
  {
    city: "Muzaffarabad",
    airport: "Muzaffarabad Airport",
    country: "Pakistan",
    iata: "MFG",
  },
  {
    city: "Mvengue",
    airport: "Franceville/Mvengue Airport",
    country: "Gabon",
    iata: "MVB",
  },
  {
    city: "Mykonos (island)",
    airport: "Mykonos Island National Airport",
    country: "Greece",
    iata: "JMK",
  },
  {
    city: "Myrtle Beach (SC)",
    airport: " Myrtle Beach International Airport",
    country: "USA",
    iata: "MYR",
  },
  {
    city: "Myrtle Beach (SC)",
    airport: "Grand Strand Airport",
    country: "USA",
    iata: "CRE",
  },
  {
    city: "Mysore (Mysuru)",
    airport: "Mysore Airport",
    country: "Karnataka, India",
    iata: "MYQ",
  },
  {
    city: "Mytilene (Lesbos island)",
    airport: " Mytilene International Airport",
    country: "Greece",
    iata: "MJT",
  },
  {
    city: "Mzamba",
    airport: "Wild Coast Sun Airport",
    country: "South Africa",
    iata: "MZF",
  },
  {
    city: "Nadi",
    airport: "Nadi International Airport",
    country: "Viti Levu, Fiji",
    iata: "NAN",
  },
  {
    city: "Nagasaki",
    airport: " Nagasaki Airport",
    country: "Japan",
    iata: "NGS",
  },
  {
    city: "Nagoya",
    airport: " Chubu Centrair International Airport",
    country: "Japan",
    iata: "NGO",
  },
  {
    city: "Nagpur",
    airport: "Dr. Babasaheb Ambedkar International",
    country: "Maharashtra, India",
    iata: "NAG",
  },
  {
    city: " Naha",
    airport: "Naha Airport",
    country: "Okinawa, Japan",
    iata: "OKA",
  },
  {
    city: "Nairobi",
    airport: "Jomo Kenyatta International",
    country: "Kenya",
    iata: "NBO",
  },
  {
    city: "Nakhchivan",
    airport: "Nakhchivan Airport",
    country: "Azerbaijan",
    iata: "NAJ",
  },
  {
    city: "Nakhon Ratchasima",
    airport: "Nakhon Ratchasima Airport",
    country: "Thailand",
    iata: "NAK",
  },
  {
    city: "Nakhon Si Thammarat",
    airport: "Nakhon Si Thammarat Airport",
    country: "Thailand",
    iata: "NST",
  },
  {
    city: "Nanchang",
    airport: "Nanchang Changbei International Airport",
    country: "Jiangxi, China",
    iata: "KHN",
  },
  {
    city: "Nancy",
    airport: " Essey Airport",
    country: "France",
    iata: "ENC",
  },
  {
    city: "Nanisivik",
    airport: "Nanisivik Airport",
    country: "Nunavut, Canada",
    iata: "YSR",
  },
  {
    city: "Nanning",
    airport: "Nanning Wuxu International Airport",
    country: "Guangxi,  China",
    iata: "NNG",
  },
  {
    city: "Nantes",
    airport: "Nantes Atlantique Airport",
    country: "France",
    iata: "NTE",
  },
  {
    city: "Nantucket (MA)",
    airport: " Nantucket Memorial Airport",
    country: "USA",
    iata: "ACK",
  },
  {
    city: "Naples (Napoli)",
    airport: "Naples International Airport",
    country: "Italy",
    iata: "NAP",
  },
  {
    city: "Naples (FL)",
    airport: "Naples Municipal Airport",
    country: "USA",
    iata: "APF",
  },
  {
    city: "Narita (Tokyo)",
    airport: "Narita International Airport",
    country: "Chiba, Japan",
    iata: "NRT",
  },
  {
    city: "Narrabri",
    airport: "Narrabri Airport",
    country: "NSW, Australia",
    iata: "NAA",
  },
  {
    city: "Narrandera",
    airport: "Narrandera Airport",
    country: "NSW, Australia",
    iata: "NRA",
  },
  {
    city: "Narsarsuaq",
    airport: " Narsarsuaq Airport",
    country: "Greenland",
    iata: "UAK",
  },
  {
    city: "Nashville (TN)",
    airport: "Nashville International Airport",
    country: "USA",
    iata: "BNA",
  },
  {
    city: "Natal",
    airport: "Natal International Airport",
    country: "Brazil",
    iata: "NAT",
  },
  {
    city: "Nausori/Suva",
    airport: " Nausori International Airport",
    country: "Viti Levu, Fiji",
    iata: "SUV",
  },
  {
    city: "Nawabshah",
    airport: "Nawabshah Airport",
    country: "Sindh, Pakistan",
    iata: "WNS",
  },
  {
    city: "Naxos (island)",
    airport: "Naxos Island National Airport",
    country: "Greece",
    iata: "JNX",
  },
  {
    city: "Naypyidaw",
    airport: "Naypyidaw International Airport",
    country: "Myanmar (Burma)",
    iata: "NYT",
  },
  {
    city: "N'Djamena",
    airport: "N’Djamena International Airport",
    country: "Chad",
    iata: "NDJ",
  },
  {
    city: "N'Dola (Ndola)",
    airport: " Ndola Airport",
    country: "Zambia",
    iata: "NLA",
  },
  {
    city: "Nelson",
    airport: " Nelson Airport",
    country: "New Zealand",
    iata: "NSN",
  },
  {
    city: "Nelspruit",
    airport: "Nelspruit Airport (closed)",
    country: "South Africa",
    iata: "NLP",
  },
  {
    city: "Nelspruit",
    airport: "Kruger Mpumalanga International Airport",
    country: "South Africa",
    iata: "MQP",
  },
  {
    city: "Nevis",
    airport: "Vance Amory International Airport",
    country: "Nevis",
    iata: "NEV",
  },
  {
    city: "New Bern (NC)",
    airport: "Craven County Regional Airport",
    country: "USA",
    iata: "EWN",
  },
  {
    city: "New Haven (CT)",
    airport: " Tweed-New Haven Airport",
    country: "USA",
    iata: "HVN",
  },
  {
    city: "New Orleans (LA)",
    airport: "Louis Armstrong International Airport",
    country: "USA",
    iata: "MSY",
  },
  {
    city: "Newquay",
    airport: "Newquay Cornwall Airport",
    country: "United Kingdom",
    iata: " NQY",
  },
  {
    city: "New York ",
    airport: "Metropolitan Area",
    country: "USA",
    iata: "NYC",
  },
  {
    city: "New York ",
    airport: "John F Kennedy International Airport",
    country: "USA",
    iata: "JFK",
  },
  {
    city: "New York ",
    airport: "LaGuardia Airport",
    country: "USA",
    iata: "LGA",
  },
  {
    city: "New York ",
    airport: "Newark Liberty International Airport",
    country: "USA",
    iata: "EWR",
  },
  {
    city: "Newburgh ",
    airport: "Stewart International Airport",
    country: "USA",
    iata: "SWF",
  },
  {
    city: "Newcastle",
    airport: "Newcastle - Belmont Airport",
    country: "NSW, Australia",
    iata: "BEO",
  },
  {
    city: "Newcastle",
    airport: " Newcastle Airport",
    country: "NSW, Australia",
    iata: "NTL",
  },
  {
    city: "Newcastle",
    airport: "Newcastle International",
    country: "United Kingdom",
    iata: "NCL",
  },
  {
    city: "Newcastle",
    airport: "Newcastle Industrial Airport",
    country: "KwaZulu-Natal, South Africa",
    iata: "NCS",
  },
  {
    city: "Newman",
    airport: "Newman Airport",
    country: "WA, Australia",
    iata: "ZNE",
  },
  {
    city: "Newport News/Williamsburg (VA)",
    airport: " Newport News/Williamsburg International",
    country: "USA",
    iata: "PHF",
  },
  {
    city: "N'Gaoundere",
    airport: "Ngaoundéré Airport",
    country: "Cameroon",
    iata: "NGE",
  },
  {
    city: "Ngerulmud",
    airport: "Roman Tmetuchl International Airport",
    country: "Palau",
    iata: "ROR",
  },
  {
    city: "Niagara Falls International",
    airport: "Niagara Falls International Airport",
    country: "USA",
    iata: "IAG",
  },
  {
    city: "Niamey",
    airport: "Diori Hamani International Airport",
    country: "Niger",
    iata: "NIM",
  },
  {
    city: "Nice",
    airport: "Nice Côte d'Azur Airport",
    country: "France",
    iata: "NCE",
  },
  {
    city: "Nicosia",
    airport: "Nicosia International Airport (abandoned)",
    country: "Cyprus",
    iata: "NIC",
  },
  {
    city: "Nikolaev (Mykolaiv)",
    airport: " Mykolaiv Airport",
    country: "Ukraine",
    iata: "NLV",
  },
  {
    city: "Niigata",
    airport: "Niigata Airport",
    country: "Japan",
    iata: "KIJ",
  },
  {
    city: "Nimes",
    airport: "Nîmes Airport",
    country: "France",
    iata: "FNI",
  },
  {
    city: "Ningbo",
    airport: "Ningbo Lishe International Airport  (cn)",
    country: "Zhejiang, China",
    iata: "NGB",
  },
  {
    city: "Nis (Niš)",
    airport: "Niš Constantine the Great Airport",
    country: "Serbia",
    iata: "INI",
  },
  {
    city: "Nizhniy Novgorod",
    airport: "Strigino International Airport",
    country: "Russia",
    iata: "GOJ",
  },
  {
    city: "Nogales (AZ)",
    airport: " Nogales International Airport",
    country: "USA",
    iata: "OLS",
  },
  {
    city: "Nome (AK)",
    airport: "Nome Airport",
    country: "USA",
    iata: "OME",
  },
  {
    city: "Norfolk Island",
    airport: "Norfolk Island Airport",
    country: "Australia",
    iata: "NLK",
  },
  {
    city: "Norfolk/Virginia Beach (VA)",
    airport: "Norfolk International Airport",
    country: "USA",
    iata: "ORF",
  },
  {
    city: "Norman Wells",
    airport: "Norman Wells Airport",
    country: "NWT, Canada",
    iata: "YVQ",
  },
  {
    city: "Norrkoeping",
    airport: "Norrköping Airport",
    country: "Östergötland, Sweden",
    iata: "NRK",
  },
  {
    city: "North Bend (OR)",
    airport: "North Bend Municipal Airport",
    country: "USA",
    iata: "OTH",
  },
  {
    city: "North Eleuthera",
    airport: "North Eleuthera Airport",
    country: "Bahamas",
    iata: "ELH",
  },
  {
    city: "Norwich",
    airport: "Norwich International Airport",
    country: "United Kingdom",
    iata: "NWI",
  },
  {
    city: "Nouadhibou",
    airport: "Nouadhibou International Airport",
    country: "Mauritania",
    iata: "NDB",
  },
  {
    city: "Nouakchott",
    airport: "Nouakchott International Airport",
    country: "Mauritania",
    iata: "NKC",
  },
  {
    city: "Noumea",
    airport: "La Tontouta International Airport",
    country: "New Caledonia",
    iata: "NOU",
  },
  {
    city: "Noumea",
    airport: "Nouméa Magenta Airport",
    country: "New Caledonia",
    iata: "GEA",
  },
  {
    city: "Novi Sad",
    airport: "Novi Sad Airport",
    country: "Serbia",
    iata: "QND",
  },
  {
    city: "Novosibirsk",
    airport: "Novosibirsk Tolmachevo Airport",
    country: "Russia",
    iata: "OVB",
  },
  {
    city: "Nürnberg (Nuremberg)",
    airport: "Albrecht Dürer Airport Nürnberg",
    country: "Germany",
    iata: "NUE",
  },
  {
    city: "Nuevo Laredo",
    airport: "Quetzalcóatl International Airport",
    country: "Mexico",
    iata: "NLD",
  },
  {
    city: "Nuku'alofa",
    airport: "Fua’amotu International Airport",
    country: "Tonga",
    iata: "TBU",
  },
  {
    city: "Nuuk",
    airport: "Nuuk Airport  (Mittarfik Nuuk)",
    country: "Greenland",
    iata: "GOH",
  },
  {
    city: "Oakland (CA)",
    airport: "Oakland International Airport",
    country: "USA",
    iata: "OAK",
  },
  {
    city: "Oaxaca de Juárez",
    airport: "Xoxocotlán International Airport",
    country: "Mexico",
    iata: "OAX",
  },
  {
    city: "Odense",
    airport: "Hans Christian Andersen Airport",
    country: "Denmark",
    iata: "ODE",
  },
  {
    city: "Odessa",
    airport: "Odessa International Airport",
    country: "Ukraine",
    iata: "ODS",
  },
  {
    city: "Örebro (Oerebro)",
    airport: "Örebro Airport",
    country: "Sweden",
    iata: "ORB",
  },
  {
    city: "Ohrid",
    airport: " Ohrid Airport",
    country: "Macedonia",
    iata: "OHD",
  },
  {
    city: "Oita",
    airport: "Oita Airport",
    country: "Kyushu, Japan",
    iata: "OIT",
  },
  {
    city: "Okayama",
    airport: "Okayama Airport",
    country: "Chūgoku, Japan",
    iata: "OKJ",
  },
  {
    city: "Oklahoma City (OK)",
    airport: " Will Rogers World Airport",
    country: "USA",
    iata: "OKC",
  },
  {
    city: "Olbia",
    airport: "Olbia Costa Smeralda Airport",
    country: "Sardinia, Italy",
    iata: "OLB",
  },
  {
    city: "Olympic Dam",
    airport: "Olympic Dam Airport",
    country: "SA, Australia",
    iata: "OLP",
  },
  {
    city: "Omaha (NE)",
    airport: "Eppley Airfield",
    country: "USA",
    iata: "OMA",
  },
  {
    city: "Ondangwa",
    airport: "Ondangwa Airport",
    country: "Namibia",
    iata: "OND",
  },
  {
    city: "Ontario (CA)",
    airport: "Ontario International Airport",
    country: "USA",
    iata: "ONT",
  },
  {
    city: "Oran (Ouahran)",
    airport: "Oran Es Sénia Airport",
    country: "Algeria",
    iata: "ORN",
  },
  {
    city: "Orange",
    airport: "Orange Airport",
    country: "NSW, Australia",
    iata: "OAG",
  },
  {
    city: "Orange County (Santa Ana) (CA)",
    airport: "John Wayne Airport",
    country: "USA",
    iata: "SNA",
  },
  {
    city: "Oranjemund",
    airport: "Oranjemund Airport",
    country: "Karas, Namibia",
    iata: "OMD",
  },
  {
    city: "Orlando (FL)",
    airport: "Metropolitan Area",
    country: "USA",
    iata: "ORL",
  },
  {
    city: "Orlando (FL)",
    airport: "Orlando International Airport",
    country: "USA",
    iata: "MCO",
  },
  {
    city: "Orpheus Island",
    airport: "Waterport Airport",
    country: "Queensland, Australia",
    iata: "ORS",
  },
  {
    city: "Osaka",
    airport: "Metropolitan Area",
    country: "Japan",
    iata: "OSA",
  },
  {
    city: "Osaka",
    airport: "Kansai International Airport",
    country: "Japan",
    iata: "KIX",
  },
  {
    city: "Oshkosh (WI)",
    airport: "Wittman Regional Airport",
    country: "USA",
    iata: "OSH",
  },
  {
    city: "Osijek",
    airport: "Osijek Airport",
    country: "Croatia (Hrvatska)",
    iata: "OSI",
  },
  {
    city: "Oslo",
    airport: "Oslo Airport, Gardermoen",
    country: "Norway",
    iata: "OSL",
  },
  {
    city: "Oslo",
    airport: "Fornebu Airport (closed) replaced by Oslo Airport Gardermoen",
    country: "Norway",
    iata: "FBU",
  },
  {
    city: "Oslo",
    airport: "Sandefjord Airport, Torp",
    country: "Norway",
    iata: "TRF",
  },
  {
    city: "Ottawa",
    airport: "Macdonald-Cartier International Airport",
    country: "Ontario, Canada",
    iata: "YOW",
  },
  {
    city: "Ouadda",
    airport: "Ouadda Airport",
    country: "Central African Republic",
    iata: "ODA",
  },
  {
    city: "Ouarzazate",
    airport: "Ouarzazate Airport",
    country: "Morocco",
    iata: "OZZ",
  },
  {
    city: "Oudtshoorn",
    airport: "Oudtshoorn Airport",
    country: "Western Cape, South Africa",
    iata: "OUH",
  },
  {
    city: "Ouagadougou",
    airport: "Ouagadougou International Airport",
    country: "Burkina Faso",
    iata: "OUA",
  },
  {
    city: "Oujda",
    airport: "Angads Airport",
    country: "Morocco",
    iata: "OUD",
  },
  {
    city: "Oulu",
    airport: " Oulu Airport",
    country: "Finland",
    iata: "OUL",
  },
  {
    city: "Out Skerries (Shetland)",
    airport: "Out Skerries",
    country: "United Kingdom",
    iata: "OUK",
  },
  {
    city: "Ouvea",
    airport: "Ouloup Airport",
    country: "New Caledonia",
    iata: "UVE",
  },
  {
    city: "Oviedo",
    airport: "Asturias Airport (Oviedo Airport)",
    country: "Spain",
    iata: "OVD",
  },
  {
    city: "Owensboro (KY)",
    airport: "Owensboro-Daviess County Airport",
    country: "USA",
    iata: "OWB",
  },
  {
    city: "Oxnard (CA)",
    airport: "Oxnard Airport",
    country: "USA",
    iata: "OXR",
  },
  {
    city: "Oyem",
    airport: "Oyem Airport",
    country: "Gabon",
    iata: "OYE",
  },
  {
    city: "Paderborn/Lippstadt",
    airport: "Paderborn/Lippstadt Airport",
    country: "Germany",
    iata: "PAD",
  },
  {
    city: "Padang",
    airport: "Minangkabau International Airport",
    country: "West Sumatra, Indonesia",
    iata: "PDG",
  },
  {
    city: "Paducah (KY)",
    airport: "Barkley Regional Airport",
    country: "USA",
    iata: "PAH",
  },
  {
    city: "Page/Lake Powell (AZ)",
    airport: "Page Municipal Airport",
    country: "USA",
    iata: "PGA",
  },
  {
    city: "Pago Pago",
    airport: "Pago Pago International Airport",
    country: "American Samoa",
    iata: "PPG",
  },
  {
    city: "Pai",
    airport: "Pai Airport",
    country: "Thailand",
    iata: "PYY",
  },
  {
    city: "Pakersburg (WV) /Marietta (OH)",
    airport: "Mid-Ohio Valley Regional Airport",
    country: "USA",
    iata: "PKB",
  },
  {
    city: "Palanga",
    airport: "Palanga International Airport",
    country: "Lithuania",
    iata: "PLQ",
  },
  {
    city: "Palermo",
    airport: "Palermo Airport",
    country: "Italy",
    iata: "PMO",
  },
  {
    city: "Palma de Mallorca",
    airport: "Palma de Mallorca Airport",
    country: "Spain",
    iata: "PMI",
  },
  {
    city: "Palmas",
    airport: "Palmas Airport",
    country: "Tocantins, Brazil",
    iata: "PMW",
  },
  {
    city: "Palmdale/Lancaster (CA)",
    airport: " LA/Palmdale Regional Airport",
    country: "USA",
    iata: "PMD",
  },
  {
    city: "Palmerston North",
    airport: "Palmerston North International Airport",
    country: "New Zealand",
    iata: "PMR",
  },
  {
    city: "Palm Springs (CA)",
    airport: "Palm Springs International Airport",
    country: "USA",
    iata: "PSP",
  },
  {
    city: "Palo Alto (CA)",
    airport: "Palo Alto Airport",
    country: "USA",
    iata: "PAO",
  },
  {
    city: "Panama City",
    airport: "Tocumen International Airport",
    country: "Panama",
    iata: "PTY",
  },
  {
    city: "Panama City (FL)",
    airport: "Panama City-Bay County Airport",
    country: "USA",
    iata: "PFN",
  },
  {
    city: "Panjgur",
    airport: " Panjgur Airport",
    country: "Pakistan",
    iata: "PJG",
  },
  {
    city: "Pantelleria",
    airport: "Pantelleria Airport",
    country: "Italy",
    iata: "PNL",
  },
  {
    city: "Pantnagar",
    airport: "Pantnagar Airport",
    country: "Uttarakhand, India",
    iata: "PGH",
  },
  {
    city: "Papeete",
    airport: "Tahiti International Airport",
    country: "French Polynesia (Tahiti)",
    iata: "PPT",
  },
  {
    city: "Paphos",
    airport: "Paphos International Airport",
    country: "Cyprus",
    iata: "PFO",
  },
  {
    city: "Paraburdoo",
    airport: "Paraburdoo Airport",
    country: "Australia",
    iata: "PBO",
  },
  {
    city: "Paramaribo",
    airport: "Paramaribo-Zanderij International Airport",
    country: "Suriname",
    iata: "PBM",
  },
  {
    city: "Paris",
    airport: "Metropolitan Area",
    country: "France",
    iata: "PAR",
  },
  {
    city: "Paris",
    airport: "Charles de Gaulle Airport",
    country: "France",
    iata: "CDG",
  },
  {
    city: "Paris",
    airport: "Paris–Le Bourget Airport",
    country: "France",
    iata: "LBG",
  },
  {
    city: "Paris",
    airport: "Paris Orly Airport",
    country: "France",
    iata: "ORY",
  },
  {
    city: " Parnaiba",
    airport: "Parnaíba International Airport",
    country: "Piaui, Brazil",
    iata: "PHB",
  },
  {
    city: "Paro",
    airport: "Paro Airport",
    country: "Bhutan",
    iata: "PBH",
  },
  {
    city: " Pascagoula (MS)",
    airport: "Trent Lott International Airport",
    country: "USA",
    iata: "PGL",
  },
  {
    city: "Pasco (WA)",
    airport: " Tri-Cities Airport",
    country: "USA",
    iata: "PSC",
  },
  {
    city: "Pasni",
    airport: "Pasni Airport",
    country: "Balochistan, Pakistan",
    iata: "PSI",
  },
  {
    city: "Patna",
    airport: "Lok Nayak Jayaprakash Airport",
    country: "Bihar, India",
    iata: "PAT",
  },
  {
    city: "Pattaya",
    airport: "Pattaya Airpark",
    country: "Thailand",
    iata: "PYX",
  },
  {
    city: "Pau",
    airport: " Pau Pyrénées Airport",
    country: "France",
    iata: "PUF",
  },
  {
    city: " Pavlodar",
    airport: "Pavlodar Airport",
    country: "Kazakhstan",
    iata: "PWQ",
  },
  {
    city: "Paysandu",
    airport: "Tydeo Larre Borges International Airport",
    country: "Uruguay",
    iata: "PDU",
  },
  {
    city: "Pellston ",
    airport: "Pellston Regional Airport",
    country: "USA",
    iata: "PLN",
  },
  {
    city: "Pelotas",
    airport: "Pelotas International Airport",
    country: "Brazil",
    iata: "PET",
  },
  {
    city: "Penang",
    airport: "Penang International Airport",
    country: "Malaysia",
    iata: "PEN",
  },
  {
    city: "Pendelton (OR)",
    airport: "Eastern Oregon Regional Airport",
    country: "USA",
    iata: "PDT",
  },
  {
    city: "Pensacola (FL)",
    airport: " Pensacola International Airport",
    country: "USA",
    iata: "PNS",
  },
  {
    city: "Peoria/Bloomington (IL)",
    airport: " Greater Peoria Regional Airport",
    country: "USA",
    iata: "PIA",
  },
  {
    city: "Pereira",
    airport: "Matecaña International Airport",
    country: "Colombia",
    iata: "PEI",
  },
  {
    city: "Perpignan",
    airport: "Perpignan – Rivesaltes Airport",
    country: "France",
    iata: "PGF",
  },
  {
    city: "Perth",
    airport: "Perth Airport",
    country: "WA, Australia",
    iata: "PER",
  },
  {
    city: "Perugia",
    airport: " San Egidio Airport",
    country: "Italy",
    iata: "PEG",
  },
  {
    city: "Pescara",
    airport: "Pescara Airport",
    country: "Italy",
    iata: "PSR",
  },
  {
    city: "Peshawar",
    airport: "Peshawar Airport",
    country: "Pakistan",
    iata: "PEW",
  },
  {
    city: "Petersburg (AK)",
    airport: " Petersburg James A. Johnson Airport",
    country: "USA",
    iata: "PSG",
  },
  {
    city: "Phalaborwa",
    airport: "Hendrik Van Eck Airport",
    country: "Limpopo, South Africa",
    iata: "PHW",
  },
  {
    city: "Phetchabun",
    airport: "Phetchabun Airport",
    country: "Thailand",
    iata: "PHY",
  },
  {
    city: "Philadelphia (PA)",
    airport: "Philadelphia International Airport",
    country: "USA",
    iata: "PHL",
  },
  {
    city: "Phnom Penh",
    airport: "Phnom Penh International Airport",
    country: "Cambodia",
    iata: "PNH",
  },
  {
    city: "Phoenix (AZ)",
    airport: "Metropolitan Area",
    country: "USA",
    iata: "PHX",
  },
  {
    city: "Phoenix (AZ)",
    airport: "Phoenix Deer Valley Airport",
    country: "USA",
    iata: "DVT",
  },
  {
    city: "Phoenix (AZ)",
    airport: "Phoenix-Mesa Gateway Airport",
    country: "USA",
    iata: "AZA",
  },
  {
    city: "Phuket (island)",
    airport: "Phuket International Airport",
    country: "Thailand",
    iata: "HKT",
  },
  {
    city: "Piedras Negras",
    airport: "Piedras Negras International Airport",
    country: "Coahuila, Mexico",
    iata: "PDS",
  },
  {
    city: "Pierre (SD)",
    airport: "Pierre Regional Airport",
    country: "USA",
    iata: "PIR",
  },
  {
    city: "Pietermaritzburg",
    airport: " Pietermaritzburg Airport",
    country: " South Africa",
    iata: "PZB",
  },
  {
    city: "Pietersburg",
    airport: "Polokwane International Airport",
    country: "South Africa",
    iata: "PTG",
  },
  {
    city: "Pilanesberg/Sun City",
    airport: "Pilanesberg International Airport",
    country: "South Africa",
    iata: "NTY",
  },
  {
    city: "Pisa",
    airport: "Galileo Galilei Airport",
    country: "Italy",
    iata: "PSA",
  },
  {
    city: "Pittsburgh (PA)",
    airport: "Pittsburgh International Airport",
    country: "USA",
    iata: "PIT",
  },
  {
    city: "Plattsburgh ",
    airport: "Clinton County Airport (closed)",
    country: "USA",
    iata: "PLB",
  },
  {
    city: "Plettenberg Bay",
    airport: "Plettenberg Bay Airport",
    country: "South Africa",
    iata: "PBZ",
  },
  {
    city: "Plovdiv",
    airport: "Plovdiv International Airport",
    country: "Bulgaria",
    iata: "PDV",
  },
  {
    city: "Pocatello (ID)",
    airport: " Pocatello Regional Airport",
    country: "USA",
    iata: "PIH",
  },
  {
    city: "Podgorica",
    airport: "Podgorica Airport",
    country: "Montenegro",
    iata: "TGD",
  },
  {
    city: "Pohnpei, Kolonia",
    airport: "Pohnpei International Airport",
    country: "Micronesia",
    iata: "PNI",
  },
  {
    city: "Pointe Noire",
    airport: "Pointe Noire Airport",
    country: "Congo (ROC)",
    iata: "PNR",
  },
  {
    city: "Poitiers",
    airport: "Poitiers - Biard Airport",
    country: "France",
    iata: "PIS",
  },
  {
    city: "Ponce",
    airport: "Mercedita Airport",
    country: "Puerto Rico",
    iata: "PSE",
  },
  {
    city: "Ponta Delgada",
    airport: "João Paulo II Airport",
    country: "Azores, Portugal",
    iata: "PDL",
  },
  {
    city: "Ponta Pora",
    airport: "Ponta Porã Airport",
    country: "Brazil",
    iata: "PMG",
  },
  {
    city: "Pori",
    airport: "Pori Airport",
    country: "Finland",
    iata: "POR",
  },
  {
    city: "Port Angeles (WA)",
    airport: "William R. Fairchild International Airport",
    country: "USA",
    iata: "CLM",
  },
  {
    city: "Port au Prince",
    airport: "Toussaint Louverture International Airport",
    country: "Haiti",
    iata: "PAP",
  },
  {
    city: "Port Augusta",
    airport: "Port Augusta Airport",
    country: "SA, Australia",
    iata: "PUG",
  },
  {
    city: "Port Elizabeth",
    airport: "Port Elizabeth Airport",
    country: "Eastern Cape, South Africa",
    iata: "PLZ",
  },
  {
    city: "Port Gentil",
    airport: "Port Gentil Airport",
    country: "Gabon",
    iata: "POG",
  },
  {
    city: "Port Harcourt",
    airport: "Port Harcourt International Airport",
    country: "Nigeria",
    iata: "PHC",
  },
  {
    city: "Port Hedland",
    airport: "Port Hedland International Airport",
    country: "WA, Australia",
    iata: "PHE",
  },
  {
    city: "Port Huron ",
    airport: "St. Clair County International Airport",
    country: "USA",
    iata: "PHN",
  },
  {
    city: "Portland",
    airport: "Portland Airport",
    country: "Victoria, Australia",
    iata: "PTJ",
  },
  {
    city: "Portland (ME)",
    airport: "Portland International Jetport",
    country: "USA",
    iata: "PWM",
  },
  {
    city: "Portland (OR)",
    airport: "Portland International Airport",
    country: "USA",
    iata: "PDX",
  },
  {
    city: "Port Lincoln",
    airport: "Port Lincoln Airport",
    country: "SA, Australia",
    iata: "PLO",
  },
  {
    city: "Port Macquarie",
    airport: "Port Macquarie Airport",
    country: "NSW, Australia",
    iata: "PQQ",
  },
  {
    city: "Port Menier (PQ)",
    airport: "Port-Menier Airport",
    country: "Quebec, Canada",
    iata: "YPN",
  },
  {
    city: "Port Moresby",
    airport: "Jacksons International Airport",
    country: "Papua New Guinea",
    iata: "POM",
  },
  {
    city: "Porto",
    airport: "Francisco de Sá Carneiro Airport",
    country: "Portugal",
    iata: "OPO",
  },
  {
    city: "Porto Alegre",
    airport: "Salgado Filho International Airport",
    country: "Brazil",
    iata: "POA",
  },
  {
    city: "Port of Spain",
    airport: "Piarco International Airport",
    country: "Trinidad and Tobago",
    iata: "POS",
  },
  {
    city: "Porto Santo Island",
    airport: " Porto Santo Airport",
    country: "Porto Santo, Portugal",
    iata: "PXO",
  },
  {
    city: "Port Sudan",
    airport: "Port Sudan New International Airport",
    country: "Sudan",
    iata: "PZU",
  },
  {
    city: "Porto Velho",
    airport: "Porto Velho International Airport",
    country: "Rodondia, Brazil",
    iata: "PVH",
  },
  {
    city: "Port Said",
    airport: "Port Said Airport",
    country: "Egypt",
    iata: "PSD",
  },
  {
    city: "Portsmouth (NH)",
    airport: "Portsmouth International Airport at Pease",
    country: "USA",
    iata: "PSM",
  },
  {
    city: "Port Vila",
    airport: "Bauerfield International Airport",
    country: "Vanuatu",
    iata: "VLI",
  },
  {
    city: "Poughkeepsie ",
    airport: "Dutchess County Airport",
    country: "USA",
    iata: "POU",
  },
  {
    city: "Poznan",
    airport: "Poznan-Lawica Airport",
    country: "Poland",
    iata: "POZ",
  },
  {
    city: "Prague",
    airport: "Václav Havel Airport Prague",
    country: "Czech Republic",
    iata: "PRG",
  },
  {
    city: "Praia",
    airport: "Nelson Mandela International Airport",
    country: "Cape Verde",
    iata: "RAI",
  },
  {
    city: "Presque Island (ME)",
    airport: " Northern Maine Regional Airport",
    country: "USA",
    iata: "PQI",
  },
  {
    city: "Pretoria",
    airport: "Wonderboom National Airport",
    country: "South Africa",
    iata: "PRY",
  },
  {
    city: "Preveza/Lefkas",
    airport: "Aktion Airport",
    country: "Greece",
    iata: "PVK",
  },
  {
    city: "Prince George",
    airport: "Prince George Airport",
    country: "BC, Canada",
    iata: "YXS",
  },
  {
    city: "Prince Rupert/Digby Island",
    airport: "Prince Rupert Airport",
    country: "BC,Canada",
    iata: "YPR",
  },
  {
    city: "Pristina",
    airport: "Pristina Intl Airport Adem Jashari",
    country: "Serbia",
    iata: "PRN",
  },
  {
    city: "Prosperpine",
    airport: "Whitsunday Coast Airport",
    country: "Queensland, Australia",
    iata: "PPP",
  },
  {
    city: "Providence (RI)",
    airport: "T. F. Green Airport",
    country: "USA",
    iata: "PVD",
  },
  {
    city: "Providenciales",
    airport: "Providenciales International Airport",
    country: "Turks and Caicos Islands",
    iata: "PLS",
  },
  {
    city: "Prudhoe Bay (AK)",
    airport: "Deadhorse Airport",
    country: "USA",
    iata: "SCC",
  },
  {
    city: "Pucallpa",
    airport: "Captain Rolden International Airport",
    country: "Peru",
    iata: "PCL",
  },
  {
    city: "Puebla",
    airport: "Hermanos Serdán International Airport",
    country: "Mexico",
    iata: "PBC",
  },
  {
    city: "Pueblo (CO)",
    airport: " Pueblo Memorial Airport",
    country: "USA",
    iata: "PUB",
  },
  {
    city: "Puerto Escondido",
    airport: "Puerto Escondido International Airport",
    country: "Oaxaca, Mexico",
    iata: "PXM",
  },
  {
    city: "Puerto Maldonado",
    airport: "Puerto Maldonado International Airport",
    country: "Peru",
    iata: "PEM",
  },
  {
    city: "Puerto Ordaz",
    airport: "Manuel Carlos Piar Guayana Airport",
    country: "Venezuela",
    iata: "PZO",
  },
  {
    city: "Puerto Plata",
    airport: "Gregorio Luperón International Airport",
    country: "Dominican Republic",
    iata: "POP",
  },
  {
    city: " Puerto Princesa",
    airport: " Puerto Princesa Airport",
    country: "Philippines",
    iata: "PPS",
  },
  {
    city: "Puerto Suarez",
    airport: "Puerto Suárez Airport",
    country: "Bolivia",
    iata: "PSZ",
  },
  {
    city: "Puerto Vallarta",
    airport: "Lic. Gustavo Díaz Ordaz International",
    country: "Mexico",
    iata: "PVR",
  },
  {
    city: "Pukatawagan",
    airport: "Pukatawagan Airport",
    country: "Canada",
    iata: "XPK",
  },
  {
    city: "Pula",
    airport: "Pula Airport",
    country: "Croatia (Hrvatska)",
    iata: "PUY",
  },
  {
    city: "Pullman (WA)",
    airport: "Pullman-Moscow Regional Airport",
    country: "USA",
    iata: "PUW",
  },
  {
    city: "Pune (Poona)",
    airport: "Pune International Airport",
    country: "Maharashtra, India",
    iata: "PNQ",
  },
  {
    city: "Punta Arenas",
    airport: "Carlos Ibanez Del Campo International",
    country: "Chile",
    iata: "PUQ",
  },
  {
    city: "Punta Cana",
    airport: " Punta Cana International Airport",
    country: "Dominican Republic",
    iata: "PUJ",
  },
  {
    city: "Punta del Este",
    airport: "Punta del Este International Airport",
    country: "Uruguay",
    iata: "PDP",
  },
  {
    city: "Pusan (Busan)",
    airport: "Gimhae International Airport",
    country: "South Korea",
    iata: "PUS",
  },
  {
    city: "Pyongyang",
    airport: "Sunan International Airport",
    country: "North Korea",
    iata: "FNJ",
  },
  {
    city: "Qaarsut",
    airport: "Qaarsut Airport",
    country: "Greenland",
    iata: "JQA",
  },
  {
    city: "Qala i Naw (city)",
    airport: "Qala i Naw Airport",
    country: "Afghanistan",
    iata: "LQN",
  },
  {
    city: "Qardho (Gardo)",
    airport: "Qardho Airport",
    country: "Somalia",
    iata: "GSR",
  },
  {
    city: "Qazvin",
    airport: "Qazvin Airport",
    country: "Iran",
    iata: "GZW",
  },
  {
    city: " Qianjiang",
    airport: " Qianjiang Airport",
    country: "Chongqing, China",
    iata: "JIQ",
  },
  {
    city: "Qiemo",
    airport: "Qiemo Airport",
    country: "Xinjiang, China",
    iata: "IQM",
  },
  {
    city: "Qikiqtarjuaq",
    airport: "Qikiqtarjuaq Airport",
    country: "Nunavut, Canada",
    iata: "YVM",
  },
  {
    city: "Qingdao",
    airport: "Qingdao Liuting International Airport",
    country: "Shandong, China",
    iata: "TAO",
  },
  {
    city: "Qingshan",
    airport: "Xichang Qingshan Airport",
    country: "XIC",
    iata: "XIC",
  },
  {
    city: "Qingyang",
    airport: "Qingyang Airport",
    country: "Gansu, China",
    iata: "IQN",
  },
  {
    city: "Qinhuangdao",
    airport: "Qinhuangdao Beidaihe Airport",
    country: "Hebei, China",
    iata: "BPE",
  },
  {
    city: " Qinhuangdao",
    airport: " Qinhuangdao Shanhaiguan Airport",
    country: "Hebei, China",
    iata: "SHP",
  },
  {
    city: "Qishn",
    airport: "Qishn Airport",
    country: " Yemen",
    iata: " IHN",
  },
  {
    city: "Quakertown (PA)",
    airport: "Quakertown Airport",
    country: "USA",
    iata: "UKT",
  },
  {
    city: "Qualicum Beach",
    airport: "Qualicum Beach Airport",
    country: "BC, Canada",
    iata: "XQU",
  },
  {
    city: "Quaqtaq",
    airport: "Quaqtaq Airport",
    country: "Nunavik, Canada",
    iata: "YQC",
  },
  {
    city: "Quebec City",
    airport: "Jean Lesage International Airport",
    country: "Canada",
    iata: "YQB",
  },
  {
    city: "Queenstown",
    airport: "Queenstown Airport",
    country: "Tasmania, Australia",
    iata: "UEE",
  },
  {
    city: "Queenstown",
    airport: "Queenstown Airport",
    country: "New Zealand",
    iata: "ZQN",
  },
  {
    city: "Quelimane",
    airport: "Quelimane Airport",
    country: "Mozambique",
    iata: "UEL",
  },
  {
    city: "Quepos",
    airport: "Quepos La Managua Airport",
    country: "Costa Rica",
    iata: "XQP",
  },
  {
    city: " Queretaro",
    airport: " Querétaro International Airport  (es)",
    country: "Mexico",
    iata: "QRO",
  },
  {
    city: "Quesnel",
    airport: "Quesnel Airport",
    country: "BC, Canada",
    iata: "YQZ",
  },
  {
    city: "Quetta",
    airport: "Quetta International Airport",
    country: "Balochistan, Pakistan",
    iata: "UET",
  },
  {
    city: " Quetzaltenango (Xelajú)",
    airport: " Quetzaltenango Airport",
    country: "Guatemala",
    iata: "AAZ",
  },
  {
    city: "Quibdo",
    airport: "El Caraño Airport",
    country: "Colombia",
    iata: "UIB",
  },
  {
    city: " Quiche",
    airport: " Quiché Airport",
    country: "Guatemala",
    iata: "AQB",
  },
  {
    city: "Quimper",
    airport: "Quimper - Cornouaille Airport",
    country: "France",
    iata: "UIP",
  },
  {
    city: "Quincy (IL)",
    airport: "Quincy Regional Airport",
    country: "USA",
    iata: "UIN",
  },
  {
    city: "Quinhagak (AK)",
    airport: "Quinhagak Airport",
    country: "USA",
    iata: "KWN",
  },
  {
    city: "Qui Nhon",
    airport: "Phu Cat Airport",
    country: "Vietnam",
    iata: "UIH",
  },
  {
    city: "Quito",
    airport: "Quito International Airport  (es)",
    country: "Ecuador",
    iata: "UIO",
  },
  {
    city: "Quoin Hill",
    airport: "Quoin Hill Airfield (abandoned)",
    country: "Vanuatu",
    iata: "UIQ",
  },
  {
    city: "Quzhou",
    airport: "Quzhou Airport",
    country: "Zhejiang, China",
    iata: "JUZ",
  },
  {
    city: "Rabat",
    airport: "Rabat-Salé Airport",
    country: "Morocco",
    iata: "RBA",
  },
  {
    city: "Rahim Yar Khan",
    airport: "Shaikh Zayed International Airport",
    country: "Punjab, Pakistan",
    iata: "RYK",
  },
  {
    city: "Raiatea (island)",
    airport: "Raiatea Airport",
    country: "French Polynesia",
    iata: "RFP",
  },
  {
    city: "Rainbow Lake",
    airport: "Rainbow Lake Airport",
    country: "Alberta, Canada",
    iata: "YOP",
  },
  {
    city: "Rajkot",
    airport: "Rajkot Airport",
    country: "India",
    iata: "RAJ",
  },
  {
    city: "Raleigh/Durham (NC)",
    airport: " Raleigh-Durham International Airport",
    country: "USA",
    iata: "RDU",
  },
  {
    city: "Ranchi",
    airport: "Birsa Munda Airport",
    country: "Jharkhand, India",
    iata: "IXR",
  },
  {
    city: "Rangiroa (island)",
    airport: "Rangiroa Airport",
    country: "French Polynesia",
    iata: "RGI",
  },
  {
    city: "Rangoon (Yangon)",
    airport:
      "Yangon International Airport, also known as Yangon-Mingaladon Airport",
    country: "Myanmar",
    iata: "RGN",
  },
  {
    city: "Rapid City (SD)",
    airport: "Rapid City Regional Airport",
    country: "USA",
    iata: "RAP",
  },
  {
    city: "Rarotonga (island)",
    airport: "Rarotonga International Airport",
    country: "Cook Island",
    iata: "RAR",
  },
  {
    city: "Ras al Khaymah (Ras al Khaimah)",
    airport: "Ras Al Khaimah International Airport",
    country: "United Arab Emirates",
    iata: "RKT",
  },
  {
    city: "Rawala Kot",
    airport: "Rawalakot Airport",
    country: "Pakistan",
    iata: "RAZ",
  },
  {
    city: "Rayong",
    airport: " U-Tapao–Rayong–Pattaya International",
    country: "Thailand",
    iata: "UTP",
  },
  {
    city: "Reading (PA)",
    airport: "Reading Regional Airport",
    country: "USA",
    iata: "RDG",
  },
  {
    city: "Recife",
    airport: "Recife/Guararapes–Gilberto Freyre International Airport",
    country: "Brazil",
    iata: "REC",
  },
  {
    city: "Redding (CA)",
    airport: "Redding Municipal Airport",
    country: "USA",
    iata: "RDD",
  },
  {
    city: "Redmond (OR)",
    airport: "Roberts Field",
    country: "USA",
    iata: "RDM",
  },
  {
    city: "Reggio Calabria",
    airport: "Reggio Calabria Airport",
    country: "Italy",
    iata: "REG",
  },
  {
    city: "Regina",
    airport: "Regina International Airport",
    country: "Saskatchewan, Canada",
    iata: "YQR",
  },
  {
    city: "Rennes",
    airport: "Rennes–Saint-Jacques Airport",
    country: "Brittany, France",
    iata: "RNS",
  },
  {
    city: "Reno (NV)",
    airport: "Reno-Tahoe International Airport",
    country: "USA",
    iata: "RNO",
  },
  {
    city: "Resistencia",
    airport: "Resistencia International Airport",
    country: "Chaco, Argentina",
    iata: "RES",
  },
  {
    city: "Resolute Bay",
    airport: " Resolute Bay Airport",
    country: "Nunavut, Canada",
    iata: "YRB",
  },
  {
    city: "Reus",
    airport: "Reus Airport",
    country: "Spain",
    iata: "REU",
  },
  {
    city: "Reykjavik",
    airport: "Metropolitan Area",
    country: "Iceland",
    iata: "REK",
  },
  {
    city: "Reykjavik",
    airport: "Reykjavik Airport",
    country: "Iceland",
    iata: "RKV",
  },
  {
    city: " Reynosa",
    airport: "Reynosa International Airport",
    country: "Mexico",
    iata: "REX",
  },
  {
    city: "Rhinelander (WI)",
    airport: "Rhinelander-Oneida County Airport",
    country: "USA",
    iata: "RHI",
  },
  {
    city: "Rhodes (island)",
    airport: "Rhodes International Airport",
    country: "Greece",
    iata: "RHO",
  },
  {
    city: "Richards Bay",
    airport: "Richards Bay Airport",
    country: "KZN, South Africa",
    iata: "RCB",
  },
  {
    city: "Richmond (VA)",
    airport: "Richmond International Airport",
    country: "USA",
    iata: "RIC",
  },
  {
    city: "Riga",
    airport: "Riga International Airport",
    country: "Latvia",
    iata: "RIX",
  },
  {
    city: "Rijeka",
    airport: "Rijeka Airport",
    country: "Croatia (Hrvatska)",
    iata: "RJK",
  },
  {
    city: "Rimini",
    airport: " Federico Fellini International Airport",
    country: "Italy",
    iata: "RMI",
  },
  {
    city: "Rio Branco",
    airport: "Plácido de Castro International Airport",
    country: "Brazil",
    iata: "RBR",
  },
  {
    city: "Rio de Janeiro",
    airport: "Metropolitan Area",
    country: "Brazil",
    iata: "RIO",
  },
  {
    city: "Rio de Janeiro",
    airport: "Rio de Janeiro–Galeão International",
    country: "Brazil",
    iata: "GIG",
  },
  {
    city: "Rio de Janeiro",
    airport: "Santos Dumont Airport",
    country: "Brazil",
    iata: "SDU",
  },
  {
    city: "Río Gallegos",
    airport: "Piloto N. Fernandez Airport",
    country: "Argentina",
    iata: "RGL",
  },
  {
    city: "Río Grande",
    airport: "Río Grande International Airport",
    country: "Argentina",
    iata: "RGA",
  },
  {
    city: "Rio Grande",
    airport: "Rio Grande Airport",
    country: "Rio Grande do Sul, Brazil",
    iata: "RIG –",
  },
  {
    city: "Rivera",
    airport: "Rivera International Airport",
    country: "Uruguay",
    iata: "RVY",
  },
  {
    city: "Riyadh",
    airport: "King Khalid International Airport",
    country: "Saudi Arabia",
    iata: "RUH",
  },
  {
    city: "Roanne",
    airport: "Roanne - Renaison Airport",
    country: "France",
    iata: "RNE",
  },
  {
    city: "Roanoke (VA)",
    airport: " Roanoke Regional Airport",
    country: "USA",
    iata: "ROA",
  },
  {
    city: " Roanoke Rapids (NC)",
    airport: "Halifax County Airport",
    country: "USA",
    iata: "RZZ",
  },
  {
    city: "Roatan",
    airport: "Juan Manuel Gálvez International Airport",
    country: "Honduras",
    iata: "RTB",
  },
  {
    city: "Rochester (MN)",
    airport: "Rochester International Airport",
    country: "USA",
    iata: "RST",
  },
  {
    city: "Rochester ",
    airport: "Greater Rochester International Airport",
    country: "USA",
    iata: "ROC",
  },
  {
    city: "Rock Sound",
    airport: "Rock Sound International Airport",
    country: "Eleuthera, Bahamas",
    iata: "RSD",
  },
  {
    city: "Rock Springs (WY)",
    airport: "Rock Springs – Sweetwater County Airport",
    country: "USA",
    iata: "RKS",
  },
  {
    city: "Rockford (IL)",
    airport: "Chicago Rockford International Airport",
    country: "USA",
    iata: "RFD",
  },
  {
    city: "Rockhampton",
    airport: "Rockhampton Airport",
    country: "Australia",
    iata: "ROK",
  },
  {
    city: "Rockland (ME)",
    airport: " Knox County Regional Airport",
    country: "USA",
    iata: "RKD",
  },
  {
    city: "Rocky Mount - Wilson (NC)",
    airport: "Rocky Mount-Wilson Regional Airport",
    country: "USA",
    iata: "RWI",
  },
  {
    city: "Rodez",
    airport: " Rodez-Marcillac Airport",
    country: "France",
    iata: "RDZ",
  },
  {
    city: "Rodrigues Island",
    airport: "Sir Gaëtan Duval Airport",
    country: "Mauritius",
    iata: "RRG",
  },
  {
    city: "Roenne (Rønne) Bornholm is.",
    airport: "Bornholm Airport",
    country: "Denmark",
    iata: "RNN",
  },
  {
    city: "Rome",
    airport: "Metropolitan Area",
    country: "Italy",
    iata: "ROM",
  },
  {
    city: "Rome",
    airport: "Ciampino Airport",
    country: "Italy",
    iata: "CIA",
  },
  {
    city: "Rome",
    airport: "Leonardo da Vinci-Fiumicino Airport",
    country: "Italy",
    iata: "FCO",
  },
  {
    city: "Rome  Oneida County",
    airport: "Griffiss International Airport",
    country: "USA",
    iata: "RME",
  },
  {
    city: "Ronneby",
    airport: "Ronneby Airport",
    country: "Sweden",
    iata: "RNB",
  },
  {
    city: "Rosario",
    airport: "Rosario - Islas Malvinas International",
    country: "Argentina",
    iata: "ROS",
  },
  {
    city: "Roseau",
    airport: "Roseau Airport (Canefield Airport)",
    country: "Dominica",
    iata: "DCF",
  },
  {
    city: "Roseau",
    airport: "Melville Hall Airport",
    country: "Dominica",
    iata: "DOM",
  },
  {
    city: "Rosh Pina",
    airport: "Rosh Pina Airport",
    country: "Israel",
    iata: "RPN",
  },
  {
    city: "Rostov-on-Don",
    airport: "Platov International Airport",
    country: "Russia",
    iata: "ROV",
  },
  {
    city: "Rostov-on-Don",
    airport: "Rostov-on-Don Airport",
    country: "Russia",
    iata: "RVI",
  },
  {
    city: "Roswell (NM)",
    airport: "Roswell International Air Center",
    country: "USA",
    iata: "ROW",
  },
  {
    city: "Rotorua",
    airport: "Rotorua Airport",
    country: "New Zealand",
    iata: "ROT",
  },
  {
    city: "Rovaniemi",
    airport: "Rovaniemi Airport",
    country: "Finland",
    iata: "RVN",
  },
  {
    city: "Rundu",
    airport: "Rundu Airport",
    country: "Namibia",
    iata: "NDU",
  },
  {
    city: "Ruse",
    airport: "Ruse Airport (no service)",
    country: "Bulgaria",
    iata: "ROU",
  },
  {
    city: " Ryazan",
    airport: "Turlatovo Airport (airstrip)",
    country: "Russia",
    iata: "RZN",
  },
  {
    city: "Saarbruecken",
    airport: "Airport Saarbrücken",
    country: "Germany",
    iata: "SCN",
  },
  {
    city: "Sacramento (CA)",
    airport: "Sacramento International Airport",
    country: "USA",
    iata: "SMF",
  },
  {
    city: "Sado Shima (island)",
    airport: "Sado Airport",
    country: "Niigata, Japan",
    iata: "SDS",
  },
  {
    city: "Saginaw/Bay City/Midland ",
    airport: "MBS International Airport",
    country: "USA",
    iata: "MBS",
  },
  {
    city: "Saidu Sharif",
    airport: "Saidu Sharif Airport (no service)",
    country: "Pakistan",
    iata: "SDT",
  },
  {
    city: "Saint Brieuc",
    airport: "Saint-Brieuc - Armor Airport",
    country: "France",
    iata: "SBK",
  },
  {
    city: "Saint Catherine",
    airport: "St Catherine Airport",
    country: "Sinai, Egypt",
    iata: "SKV",
  },
  {
    city: "Saint Denis",
    airport: " Roland Garros Airport",
    country: "Reunion",
    iata: "RUN",
  },
  {
    city: "Saint John",
    airport: "Saint John Airport",
    country: "NB, Canada",
    iata: "YSJ",
  },
  {
    city: "Saint Louis (MO)",
    airport: "Metropolitan Area",
    country: "USA",
    iata: "STL",
  },
  {
    city: "Saint Louis (MO)",
    airport: "Spirit of Saint Louis Airport",
    country: "USA",
    iata: "SUS",
  },
  {
    city: "Saipan",
    airport: "Saipan International Airport",
    country: "Northern Mariana Islands",
    iata: "SPN",
  },
  {
    city: "Sal",
    airport: " Amilcar Cabral International Airport",
    country: "Cape Verde",
    iata: "SID",
  },
  {
    city: "Salalah",
    airport: "Salalah Airport",
    country: "Oman",
    iata: "SLL",
  },
  {
    city: "Salem (OR)",
    airport: "Salem Municipal Airport (McNary Field)",
    country: "USA",
    iata: "SLE",
  },
  {
    city: "Salinas (CA)",
    airport: "Salinas Municipal Airport",
    country: "USA",
    iata: "SNS",
  },
  {
    city: "Salinas",
    airport: "Ulpiano Paez Airport",
    country: "Ecuador",
    iata: " SNC",
  },
  {
    city: "Salisbury (MD)",
    airport: "Salisbury-Ocean City Wicomico Airport",
    country: "USA",
    iata: "SBY",
  },
  {
    city: "Saloniki (Thessaloniki)",
    airport: "Makedonia Airport",
    country: "Greece",
    iata: "SKG",
  },
  {
    city: "Salt Lake City (UT)",
    airport: "Salt Lake City International Airport",
    country: "USA",
    iata: "SLC",
  },
  {
    city: "Salto",
    airport: "Nueva Hespérides Airport",
    country: "Uruguay",
    iata: "STY",
  },
  {
    city: "Salvador",
    airport: "Salvador International Airport",
    country: "Brazil",
    iata: "SSA",
  },
  {
    city: "Salzburg",
    airport: "Salzburg Airport W. A. Mozart",
    country: "Austria",
    iata: "SZG",
  },
  {
    city: "Samana",
    airport: "Samaná El Catey International Airport",
    country: "Dominican Republic",
    iata: "AZS",
  },
  {
    city: "Samara",
    airport: "Kurumoch International Airport",
    country: "Russia",
    iata: "KUF",
  },
  {
    city: "Samarkand",
    airport: "Samarkand International Airport",
    country: "Uzbekistan",
    iata: "SKD",
  },
  {
    city: "Samos (island)",
    airport: "Samos International Airport Aristarchos",
    country: "Greece",
    iata: "SMI",
  },
  {
    city: "Samsun",
    airport: "Carsamba Airport",
    country: "Turkey",
    iata: "SZF",
  },
  {
    city: "San Andres (island)",
    airport: "Gustavo Rojas Pinilla International Airport",
    country: "Colombia",
    iata: "ADZ",
  },
  {
    city: "San Angelo ",
    airport: " San Angelo Regional Airport",
    country: "USA",
    iata: "SJT",
  },
  {
    city: "San Antonio ",
    airport: "Metropolitan Area",
    country: "USA",
    iata: "SAT",
  },
  {
    city: "San Carlos de Bariloche",
    airport: "San Carlos de Bariloche Airport",
    country: "Río Negro, Argentina",
    iata: "BRC",
  },
  {
    city: "San Cristóbal Island",
    airport: "San Cristóbal Airport",
    country: "Galápagos, Ecuador",
    iata: "SCY",
  },
  {
    city: " San Bernardino (CA)",
    airport: " San Bernardino International Airport",
    country: "USA",
    iata: "SBT",
  },
  {
    city: " Sandakan",
    airport: " Sandakan Airport",
    country: "Malaysia",
    iata: "SDK",
  },
  {
    city: "Sandane",
    airport: "Sandane Airport",
    country: "Norway",
    iata: "SDN",
  },
  {
    city: "San Diego (CA)",
    airport: "San Diego International Airport (Lindberg Field)",
    country: "USA",
    iata: "SAN",
  },
  {
    city: " San Felipe",
    airport: "San Felipe International Airport, Baja California",
    country: "Mexico",
    iata: "SFH",
  },
  {
    city: " Sanford (FL)",
    airport: "Orlando Sanford International Airport",
    country: "USA",
    iata: "SFB",
  },
  {
    city: "San Francisco (CA)",
    airport: "San Francisco International Airport",
    country: "USA",
    iata: "SFO",
  },
  {
    city: "San José",
    airport: "Juan Santamaría International",
    country: "Costa Rica",
    iata: "SJO",
  },
  {
    city: "San José",
    airport: "Tobías Bolaños Airport",
    country: "Costa Rica",
    iata: "SYQ",
  },
  {
    city: "San Jose (CA)",
    airport: "Mineta San José International Airport",
    country: "USA",
    iata: "SJC",
  },
  {
    city: "San Jose",
    airport: "San Jose Airport",
    country: "Mindoro, Philippines",
    iata: "SJI",
  },
  {
    city: "San José del Guaviare",
    airport: "Jorge Enrique González Torres Airport",
    country: "Guaviare, Colombia",
    iata: "SJE",
  },
  {
    city: "San Juan",
    airport: "Luis Munoz Marin International Airport",
    country: "Puerto Rico",
    iata: "SJU",
  },
  {
    city: "San Juan, Miramar",
    airport: "Fernando Luis Ribas Dominicci Airport",
    country: "Puerto Rico",
    iata: "SIG",
  },
  {
    city: "San Luis Obisco (CA)",
    airport: "San Luis Obispo County Regional Airport",
    country: "USA",
    iata: "SBP",
  },
  {
    city: "San Luis Potosi",
    airport: "Ponciano Arriaga International Airport",
    country: "Mexico",
    iata: "SLP",
  },
  {
    city: "San Pedro",
    airport: "San Pedro Airport",
    country: "Belize",
    iata: "SPR",
  },
  {
    city: "San Pedro",
    airport: "San Pédro Airport",
    country: "Cote d'Ivoire",
    iata: "SPY",
  },
  {
    city: "San Pedro Sula",
    airport: "La Mesa International Airport",
    country: "Honduras",
    iata: "SAP",
  },
  {
    city: "San Salvador",
    airport: "San Salvador Airport",
    country: "Bahamas",
    iata: "ZSA",
  },
  {
    city: "San Salvador",
    airport: "El Salvador International Airport",
    country: "El Salvador",
    iata: "SAL",
  },
  {
    city: "San Sebastian",
    airport: "San Sebastián Airport",
    country: "Spain",
    iata: "EAS",
  },
  {
    city: "Sanaa",
    airport: "Sana'a International Airport",
    country: "Yemen",
    iata: "SAH",
  },
  {
    city: "Sandspit",
    airport: "Sandspit Airport",
    country: "BC, Canada",
    iata: "YZP",
  },
  {
    city: "Santa Barbara (CA)",
    airport: "Santa Barbara Municipal Airport",
    country: "USA",
    iata: "SBA",
  },
  {
    city: "Santa Barbara (CA)",
    airport: "Santa Cruz Island Airport",
    country: "USA",
    iata: "SZN",
  },
  {
    city: " Santa Clara",
    airport: "Abel Santamaría Airport",
    country: "Cuba",
    iata: "SNU",
  },
  {
    city: "Santa Cruz de la Palma",
    airport: "La Palma Airport",
    country: "Canary Islands, Spain",
    iata: "SPC",
  },
  {
    city: "Santa Cruz de la Sierra",
    airport: " El Trompillo Airport",
    country: "Bolivia",
    iata: "SRZ",
  },
  {
    city: "Santa Fe (NM)",
    airport: "Santa Fe Municipal Airport",
    country: "USA",
    iata: "SAF",
  },
  {
    city: "Santa Maria (island)",
    airport: "Santa Maria Airport",
    country: "Azores, Portugal",
    iata: "SMA",
  },
  {
    city: "Santa Maria (CA)",
    airport: "Santa Maria Public Airport",
    country: "USA",
    iata: "SMX",
  },
  {
    city: "Santa Marta",
    airport: "Simón Bolívar Airport",
    country: "Colombia",
    iata: "SMR",
  },
  {
    city: "Santander",
    airport: "Santander Airport",
    country: "Spain",
    iata: "SDR",
  },
  {
    city: "Santarem",
    airport: "Santarém-Maestro Wilson Fonseca Airport",
    country: "Para, Brazil",
    iata: "STM",
  },
  {
    city: "Santa Rosa (CA)",
    airport: "Charles M. Schulz–Sonoma County Airport",
    country: "USA",
    iata: "STS",
  },
  {
    city: "Santa Rosa",
    airport: "Santa Rosa Airport",
    country: "Argentina",
    iata: "RSA",
  },
  {
    city: "Santa Rosa",
    airport: "Santa Rosa Airport",
    country: "Bolivia",
    iata: "SRB",
  },
  {
    city: "Santa Rosa",
    airport: "Luís Alberto Lehr Airport",
    country: "Brazil",
    iata: "SRA",
  },
  {
    city: "Santa Rosa de Copan",
    airport: "Santa Rosa de Copan (no airport)",
    country: "Honduras",
    iata: "SDH",
  },
  {
    city: "Santa Rosalia",
    airport: "Santa Rosalia (no airport)",
    country: "Colombia",
    iata: "SSL",
  },
  {
    city: "Santa Rosalia",
    airport: "Santa Rosalia (no airport)",
    country: "Mexico",
    iata: "SRL",
  },
  {
    city: "Santiago",
    airport: "Antonio Maceo International Airport",
    country: "Cuba",
    iata: "SCU",
  },
  {
    city: "Santiago de Chile",
    airport: "Santiago International Airport",
    country: "Chile",
    iata: "SCL",
  },
  {
    city: "Santiago de Compostela",
    airport: "Santiago de Compostela Airport",
    country: "Spain",
    iata: "SCQ",
  },
  {
    city: "Santiago del Estero",
    airport: "Ángel de la Paz Aragonés Airport",
    country: "Argentina",
    iata: "SDE",
  },
  {
    city: "Santiago de los Caballeros",
    airport: "Cibao International Airport  (es)",
    country: "Dominican Republic",
    iata: "STI",
  },
  {
    city: "Santo",
    airport: "Santo-Pekoa International Airport",
    country: "Vanuatu",
    iata: "SON",
  },
  {
    city: "Santo Domingo",
    airport: "Las Américas International Airport",
    country: "Dominican Republic",
    iata: "SDQ",
  },
  {
    city: "Sanya",
    airport: "Sanya Phoenix International Airport",
    country: "Hainan, China",
    iata: "SYX",
  },
  {
    city: "Sao Luis",
    airport: "Cunha Machado International Airport",
    country: "Brazil",
    iata: "SLZ",
  },
  {
    city: "Sao Paulo",
    airport: "Metropolitan Area",
    country: "Brazil",
    iata: "SAO",
  },
  {
    city: "Sao Paulo",
    airport: "São Paulo–Congonhas Airport",
    country: "Brazil",
    iata: "CGH",
  },
  {
    city: "Sao Paulo",
    airport: "Sao Paulo Viracopos International Airport",
    country: "Brazil",
    iata: "VCP",
  },
  {
    city: "Sao Tome",
    airport: "São Tomé International Airport",
    country: "Sao Tome & Principe",
    iata: "TMS",
  },
  {
    city: "Sapporo",
    airport: "Metropolitan Area",
    country: "Hokkaido, Japan",
    iata: "SPK",
  },
  {
    city: "Sapporo",
    airport: "Okadama Airport",
    country: "Hokkaido, Japan",
    iata: "OKD",
  },
  {
    city: "Sarajevo",
    airport: "Sarajevo International Airport",
    country: "Bosnia and Herzegovina",
    iata: "SJJ",
  },
  {
    city: "Saransk",
    airport: "Saransk Airport",
    country: "Russia",
    iata: "SKX",
  },
  {
    city: "Sarasota/Bradenton (FL)",
    airport: "Sarasota-Bradenton International Airport",
    country: "USA",
    iata: "SRQ",
  },
  {
    city: "Saskatoon",
    airport: "Saskatoon International Airport",
    country: "Saskatchewan, Canada",
    iata: "YXE",
  },
  {
    city: "Sassandra",
    airport: "Sassandra Airport",
    country: "Cote d'Ivoire",
    iata: "ZSS",
  },
  {
    city: "Satu Mare",
    airport: "Satu Mare International Airport",
    country: "Romania",
    iata: "SUJ",
  },
  {
    city: "Savannah (GA)",
    airport: "Savannah/Hilton Head International",
    country: "USA",
    iata: "SAV",
  },
  {
    city: "Savonlinna",
    airport: "Savonlinna Airport",
    country: "Finland",
    iata: "SVL",
  },
  {
    city: " Savu Island",
    airport: "Tardamu Airport",
    country: "Indonesia",
    iata: "SAU",
  },
  {
    city: "Scarborough",
    airport: "A.N.R. Robinson International Airport",
    country: "Tobago, Trinidad and Tobago",
    iata: "TAB",
  },
  {
    city: "Scone",
    airport: "Scone Airport",
    country: "NSW, Australia",
    iata: "NSO",
  },
  {
    city: "Scottsdale (AZ)",
    airport: " Scottsdale Airport",
    country: "USA",
    iata: "SCF",
  },
  {
    city: "Seattle (WA)",
    airport: "Metropolitan Area",
    country: "USA",
    iata: "SEA",
  },
  {
    city: "Seattle (WA)",
    airport: "Kenmore Air Harbor Seaplane Base",
    country: "USA",
    iata: "LKE",
  },
  {
    city: "Seattle (WA)",
    airport: "King County International (Boeing Field)",
    country: "USA",
    iata: "BFI",
  },
  {
    city: "Sedona (AZ)",
    airport: "Sedona Airport",
    country: "USA",
    iata: "SDX",
  },
  {
    city: "Sehba",
    airport: "Sabha Airport",
    country: "Libya",
    iata: "SEB",
  },
  {
    city: "Seinaejoki",
    airport: " Seinäjoki Airport",
    country: "Finland",
    iata: "SJY",
  },
  {
    city: "Selebi-Phikwe",
    airport: "Selebi-Phikwe Airport",
    country: "Botswana",
    iata: "PKW",
  },
  {
    city: "Semarang",
    airport: "Achmad Yani International Airport",
    country: "Java, Indonesia",
    iata: "SRG",
  },
  {
    city: "Sendai",
    airport: "Sendai Airport  (jp)",
    country: "Miyagi, Japan",
    iata: "SDJ",
  },
  {
    city: "Seoul",
    airport: "Metropolitan Area",
    country: "SCA, South Korea",
    iata: "SEL",
  },
  {
    city: "Seville (Sevilla)",
    airport: "San Pablo Airport",
    country: "Andalusia, Spain",
    iata: "SVQ",
  },
  {
    city: "Sfax",
    airport: "Sfax–Thyna International Airport",
    country: "Tunisia",
    iata: "SFA",
  },
  {
    city: "Shamattawa",
    airport: "Shamattawa Airport",
    country: "Manitoba, Canada",
    iata: "ZTM",
  },
  {
    city: "Shanghai",
    airport: "Metropolitan Area",
    country: "China",
    iata: "SHA",
  },
  {
    city: "Shanghai",
    airport: "Pudong International Airport",
    country: "China",
    iata: "PVG",
  },
  {
    city: "Shannon (Limerick)",
    airport: "Shannon Airport",
    country: "Ireland",
    iata: "SNN",
  },
  {
    city: "Sharjah",
    airport: "Sharjah International Airport",
    country: "UAE",
    iata: "SHJ",
  },
  {
    city: "Sharm El Sheikh",
    airport: "Sharm el-Sheikh International Airport",
    country: "Egypt",
    iata: "SSH",
  },
  {
    city: "Sheffield",
    airport: "City Airport (closed in April 2008)",
    country: "United Kingdom",
    iata: "SZD",
  },
  {
    city: "Shenandoah Valley/ Staunton (VA)",
    airport: "Shenandoah Valley Regional Airport",
    country: "USA",
    iata: "SHD",
  },
  {
    city: "Shenyang",
    airport: "Shenyang Taoxian International Airport",
    country: "Liaoning, China",
    iata: "SHE",
  },
  {
    city: "Shenzhen",
    airport: "Shenzhen Bao'an International Airport",
    country: "Guangdong, China",
    iata: "SZX",
  },
  {
    city: "Sheridan (WY)",
    airport: "Sheridan County Airport",
    country: "USA",
    iata: "SHR",
  },
  {
    city: " Shijiazhuang",
    airport: "Shijiazhuang Zhengding International  (cn)",
    country: "Hebei, China",
    iata: "SJW",
  },
  {
    city: "Shiraz",
    airport: "Shiraz International Airport  (fas)",
    country: "Iran",
    iata: "SYZ",
  },
  {
    city: "Shreveport (La)",
    airport: " Shreveport Regional Airport",
    country: "USA",
    iata: "SHV",
  },
  {
    city: "Sialkot",
    airport: "Sialkot International Airport",
    country: "Punjab, Pakistan",
    iata: "SKT",
  },
  {
    city: "Sibiu",
    airport: "Sibiu International Airport",
    country: "Romania",
    iata: "SBZ",
  },
  {
    city: "Sibu",
    airport: "Sibu Airport",
    country: "Sarawak, Malaysia",
    iata: "SBW",
  },
  {
    city: "Sidney (MT)",
    airport: " Sidney-Richland Municipal Airport",
    country: "USA",
    iata: "SDY",
  },
  {
    city: "Siem Reap (Angkor Wat)",
    airport: "Siem Reap-Angkor International Airport",
    country: "Cambodia",
    iata: "REP",
  },
  {
    city: "Siena",
    airport: "Siena-Ampugnano Airport",
    country: "Italy",
    iata: "SAY",
  },
  {
    city: "Sihanoukville",
    airport: "Sihanoukville International Airport",
    country: "Cambodia",
    iata: "KOS",
  },
  {
    city: "Simferopol",
    airport: "Simferopol International Airport",
    country: "Crimea, Ukraine",
    iata: "SIP",
  },
  {
    city: "Sindhri",
    airport: "Sindhri Airport",
    country: "Pakistan",
    iata: "MPD",
  },
  {
    city: "Singapore",
    airport: "Metropolitan Area",
    country: "Singapore",
    iata: "SIN",
  },
  {
    city: "Singapore",
    airport: "Paya Lebar AIr Base",
    country: "Singapore",
    iata: "QPG",
  },
  {
    city: "Singapore",
    airport: "Seletar Airport",
    country: "Singapore",
    iata: "XSP",
  },
  {
    city: "Singleton",
    airport: "Singleton Airport",
    country: "Australia",
    iata: "SIX",
  },
  {
    city: "Sioux City (IA)",
    airport: "Sioux Gateway Airport",
    country: "USA",
    iata: "SUX",
  },
  {
    city: "Sioux Falls (SD)",
    airport: "Sioux Falls Regional Airport",
    country: "USA",
    iata: "FSD",
  },
  {
    city: "Sishen",
    airport: "Sishen Airport",
    country: "South Africa",
    iata: "SIS",
  },
  {
    city: "Sitka (AK)",
    airport: " Sitka Rocky Gutierrez Airport",
    country: "USA",
    iata: "SIT",
  },
  {
    city: "Sivas",
    airport: "Sivas Nuri Demirağ Airport",
    country: "Turkey",
    iata: "VAS",
  },
  {
    city: "Siwa Oasis",
    airport: "Siwa Oasis North Airport",
    country: "Egypt",
    iata: "SEW",
  },
  {
    city: "Skagway (AK)",
    airport: "Skagway Airport",
    country: "USA",
    iata: "SGY",
  },
  {
    city: "Skardu",
    airport: "Skardu Airport",
    country: "Gilgit–Baltistan, Pakistan",
    iata: "KDU",
  },
  {
    city: "Skiathos (island)",
    airport: "Skiathos Island National Airport",
    country: "Greece",
    iata: "JSI",
  },
  {
    city: "Skopje",
    airport: " Alexander the Great Airport",
    country: "Macedonia",
    iata: "SKP",
  },
  {
    city: "Skrydstrup",
    airport: "Skrydstrup Airport",
    country: "Denmark",
    iata: "SKS",
  },
  {
    city: "Skukuza (Kruger Park)",
    airport: "Skukuza Aerodrome",
    country: "Mpumalanga, South Africa",
    iata: "SZK",
  },
  {
    city: "Sligo",
    airport: "Sligo Airport",
    country: "Ireland",
    iata: "SXL",
  },
  {
    city: "Smithers",
    airport: "Smithers Airport",
    country: "BC, Canada",
    iata: "YYD",
  },
  {
    city: "Sodankylae",
    airport: "Sodankylä Airfield",
    country: "Finland",
    iata: "SOT",
  },
  {
    city: "Soenderborg",
    airport: " Sønderborg Airport",
    country: "Denmark",
    iata: "SGD",
  },
  {
    city: "Soendre Stroemfjord",
    airport: "Kangerlussuaq Airport",
    country: "Greenland",
    iata: "SFJ",
  },
  {
    city: "Sofia",
    airport: "Sofia Airport",
    country: "Bulgaria",
    iata: "SOF",
  },
  {
    city: "Sogndal",
    airport: "Sogndal Airport, Haukåsen",
    country: "Norway",
    iata: "SOG",
  },
  {
    city: "Southampton",
    airport: "Southampton Airport  (Eastleigh)",
    country: "United Kingdoma",
    iata: "SOU",
  },
  {
    city: "South Bend (IN)",
    airport: " South Bend Regional Airport",
    country: "USA",
    iata: "SBN",
  },
  {
    city: "South Indian Lake",
    airport: "South Indian Lake Airport",
    country: "Manitoba, Canada",
    iata: "XSI",
  },
  {
    city: "Split",
    airport: "Split Airport",
    country: "Croatia (Hrvatska)",
    iata: "SPU",
  },
  {
    city: "Spokane (WA)",
    airport: "Spokane International Airport",
    country: "USA",
    iata: "GEG",
  },
  {
    city: "Springbok",
    airport: "Springbok Airport",
    country: "South Africa",
    iata: "SBU",
  },
  {
    city: "Springfield (IL)",
    airport: "Abraham Lincoln Capital Airport",
    country: "USA",
    iata: "SPI",
  },
  {
    city: "Springfield (MO)",
    airport: " Springfield-Branson National Airport",
    country: "USA",
    iata: "SGF",
  },
  {
    city: "Srinagar",
    airport: "Srinagar International Airport",
    country: "JK, India",
    iata: "SXR",
  },
  {
    city: "St. Augustin",
    airport: "Saint-Augustin Airport",
    country: "Quebec, Canada",
    iata: "YIF",
  },
  {
    city: "St. Croix",
    airport: "Henry E. Rohlsen Airport",
    country: "U.S. Virgin Islands",
    iata: "STX",
  },
  {
    city: "St. Etienne",
    airport: "Saint-Étienne–Bouthéon Airport",
    country: "France",
    iata: "EBU",
  },
  {
    city: "St. George (UT)",
    airport: "St. George Municipal Airport",
    country: "USA",
    iata: "SGU",
  },
  {
    city: "St. John's",
    airport: "St. John's International Airport",
    country: "NL, Canada",
    iata: "YYT",
  },
  {
    city: "St. Lucia",
    airport: "Hewanorra International Airport",
    country: "St. Lucia",
    iata: "UVF",
  },
  {
    city: "St. Marteen (island)",
    airport: "Princess Juliana International Airport",
    country: "Netherlands Antilles",
    iata: "SXM",
  },
  {
    city: "St. Martin (island)",
    airport: "Saint Martin Airport Grand-Case",
    country: "Saint Martin, France",
    iata: "SFG",
  },
  {
    city: "St. Petersburg",
    airport: "Metropolitan Area",
    country: "Russia",
    iata: "LED",
  },
  {
    city: "St. Petersburg",
    airport: "Rzhevka Airport (closed)",
    country: "Russia",
    iata: "RVH",
  },
  {
    city: "St. Pierre (St Pierre and Miquelon)",
    airport: "Saint Pierre Airport",
    country: "France/Canada",
    iata: "FSP",
  },
  {
    city: "St. Thomas, Charlotte Amalie",
    airport: "Cyril E. King Airport",
    country: "Virgin Islands",
    iata: "STT",
  },
  {
    city: "State College/Belefonte (PA)",
    airport: "University Park Airport",
    country: "USA",
    iata: "SCE",
  },
  {
    city: "Stavanger",
    airport: "Stavanger Airport",
    country: "Norway",
    iata: "SVG",
  },
  {
    city: "Steamboat Springs (CO)",
    airport: "Yampa Valley Airport",
    country: "USA",
    iata: "HDN",
  },
  {
    city: "Szczecin (German: Stettin)",
    airport: "Szczecin–Goleniów Airport",
    country: "Poland",
    iata: "SZZ",
  },
  {
    city: "Stockholm",
    airport: "Metropolitan Area",
    country: "Sweden",
    iata: "STO",
  },
  {
    city: "Stockholm",
    airport: " Stockholm Arlanda Airport",
    country: "Sweden",
    iata: "ARN",
  },
  {
    city: "Stockholm",
    airport: "Stockholm Bromma Airport",
    country: "Sweden",
    iata: "BMA",
  },
  {
    city: "Stockton (CA)",
    airport: " Stockton Metropolitan Airport",
    country: "USA",
    iata: "SCK",
  },
  {
    city: "Stornoway",
    airport: "Stornoway Airport",
    country: "United Kingdoma",
    iata: "SYY",
  },
  {
    city: "Strasbourg",
    airport: "Strasbourg Entzheim International Airport",
    country: "France",
    iata: "SXB",
  },
  {
    city: "Streaky Bay",
    airport: "Streaky Bay Aerodrome",
    country: "Australia",
    iata: "KBY",
  },
  {
    city: "Stuttgart",
    airport: "Stuttgart Airport",
    country: "Germany",
    iata: "STR",
  },
  {
    city: "Sucre",
    airport: "Juana Azurduy de Padilla International",
    country: "Bolivia",
    iata: "SRE",
  },
  {
    city: "Sui",
    airport: "Sui Airport",
    country: "Balochistan, Pakistan",
    iata: "SUL",
  },
  {
    city: "Sukkur",
    airport: " Sukkur Airport",
    country: "Pakistan",
    iata: "SKZ",
  },
  {
    city: "Sumburgh (Shetland)",
    airport: "Sumburgh Airport",
    country: "United Kingdom",
    iata: "LSI",
  },
  {
    city: "Sun Valley (ID)",
    airport: "Friedman Memorial Airport",
    country: "USA",
    iata: "SUN",
  },
  {
    city: "Sundsvall",
    airport: "Sundsvall-Härnösand Airport",
    country: "Sweden",
    iata: "SDL",
  },
  {
    city: "Sunshine Coast",
    airport: "Sunshine Coast Airport",
    country: "Australia",
    iata: "MCY",
  },
  {
    city: "Surabaya",
    airport: "Juanda International Airport",
    country: "Indonesia",
    iata: "SUB",
  },
  {
    city: "Surat",
    airport: "Surat Airport",
    country: "Gujarat, India",
    iata: "STV",
  },
  {
    city: "Swakopmund",
    airport: "Swakopmund Airport",
    country: "Namibia",
    iata: "SWP",
  },
  {
    city: "Sydney",
    airport: "Metropolitan Area",
    country: "Australia",
    iata: "SYD",
  },
  {
    city: "Sylhet",
    airport: "Osmani International Airport",
    country: "Bangladesh",
    iata: "ZYL",
  },
  {
    city: "Syracuse ",
    airport: "Syracuse Hancock International Airport",
    country: "USA",
    iata: "SYR",
  },
  {
    city: "Taba",
    airport: "Taba Airport",
    country: "Egypt",
    iata: "TCP",
  },
  {
    city: "Tabarka",
    airport: "Tabarka–Ain Draham International Airport",
    country: "Tunisia",
    iata: "TBJ",
  },
  {
    city: "Tabatinga",
    airport: "Tabatinga International Airport",
    country: "Brazil",
    iata: "TBT",
  },
  {
    city: "Tabriz",
    airport: " Tabriz International Airport  (persian)",
    country: "Iran",
    iata: "TBZ",
  },
  {
    city: "Tabuk",
    airport: "Tabuk Regional Airport",
    country: "Saudi Arabia",
    iata: "TUU",
  },
  {
    city: "Tachilek",
    airport: " Tachilek Airport",
    country: "Myanmar",
    iata: "THL",
  },
  {
    city: "Tacloban City",
    airport: "Tacloban Airport",
    country: "Eastern Visayas, Philippines",
    iata: "TAC",
  },
  {
    city: "Tacoma (WA)",
    airport: "Tacoma Narrows Airport",
    country: "USA",
    iata: "TIW",
  },
  {
    city: "Tagbilaran",
    airport: "Tagbilaran Airport",
    country: "Bohol, Philippines",
    iata: "TAG",
  },
  {
    city: "Taif (Ta’if)",
    airport: "Ta'if Airport",
    country: "Saudi Arabia",
    iata: "TIF",
  },
  {
    city: "Tainan City",
    airport: "Tainan Airport",
    country: "Taiwan",
    iata: "TNN",
  },
  {
    city: "Taipei",
    airport: "Metropolitan Area",
    country: "Taiwan",
    iata: "TPE",
  },
  {
    city: "Taipei",
    airport: " Taipei Songshan Airport",
    country: "Taiwan",
    iata: " TSA",
  },
  {
    city: "Taiyuan",
    airport: "Taiyuan Wusu International Airport",
    country: "Shanxi, PR China",
    iata: "TYN",
  },
  {
    city: "Takamatsu",
    airport: "Takamatsu Airport",
    country: "Japan",
    iata: "TAK",
  },
  {
    city: "Talara",
    airport: "Víctor Montes Arias Airport",
    country: "Peru",
    iata: "TYL",
  },
  {
    city: "Talkeetna (AK)",
    airport: "Talkeetna Airport",
    country: "USA",
    iata: "TKA",
  },
  {
    city: "Tallahassee (FL)",
    airport: "Tallahassee Regional Airport",
    country: "USA",
    iata: "TLH",
  },
  {
    city: "Tallinn",
    airport: "Lennart Meri Tallinn Airport",
    country: "Estonia",
    iata: "TLL",
  },
  {
    city: "Tamale",
    airport: "Tamale Airport",
    country: "Ghana",
    iata: "TML",
  },
  {
    city: "Tamanrasset",
    airport: "Tamanrasset Aguenar Airport",
    country: "Algeria",
    iata: "TMR",
  },
  {
    city: "Tamarindo",
    airport: "Tamarindo Airport",
    country: "Costa Rica",
    iata: "TNO",
  },
  {
    city: "Tampa (FL)",
    airport: "Tampa International Airport",
    country: "USA",
    iata: "TPA",
  },
  {
    city: "Tampere",
    airport: "Tampere-Pirkkala Airport",
    country: "Finland",
    iata: "TMP",
  },
  {
    city: "Tampico",
    airport: "Gen. F. Javier Mina International Airport",
    country: "Mexico",
    iata: "TAM",
  },
  {
    city: "Tamworth",
    airport: "Tamworth Regional Airport",
    country: "Australia",
    iata: "TMW",
  },
  {
    city: "Tangier",
    airport: "Tangier Ibn Battouta Airport",
    country: "Morocco",
    iata: "TNG",
  },
  {
    city: "Tanjung Pinang",
    airport: "Raja Haji Fisabilillah International Airport",
    country: " Riau Islands, Indonesia.",
    iata: "TNJ",
  },
  {
    city: "Tarawa",
    airport: "Bonriki International Airport",
    country: "Kiribati",
    iata: "TRW",
  },
  {
    city: "Taree",
    airport: "Taree Airport",
    country: "NSW, Australia",
    iata: "TRO",
  },
  {
    city: "Targovishte",
    airport: "Targovishte Airport (inoperational)",
    country: "Bulgaria",
    iata: "TGV",
  },
  {
    city: "Targu-Mures",
    airport: "Târgu Mureș Transilvania Airport",
    country: "Romania",
    iata: "TGM",
  },
  {
    city: "Tartu",
    airport: "Tartu-Ülenurme Airport",
    country: "Estonia",
    iata: "TAY",
  },
  {
    city: "Tashkent",
    airport: "Tashkent International Airport",
    country: "Uzbekistan",
    iata: "TAS",
  },
  {
    city: "Tauranga",
    airport: "Tauranga City Airport",
    country: "New Zealand",
    iata: "TRG",
  },
  {
    city: "Tawau",
    airport: "Tawau Airport",
    country: "Sabah, Malaysia",
    iata: "TWU",
  },
  {
    city: "Tbilisi",
    airport: "Tbilisi International Airport",
    country: "Georgia",
    iata: "TBS",
  },
  {
    city: "Te Anau",
    airport: "Te Anau Airport",
    country: "New Zealand",
    iata: "TEU",
  },
  {
    city: "Teesside",
    airport: "Durham Tees Valley Airport",
    country: "United Kingdom",
    iata: "MME",
  },
  {
    city: "Tegucigalpa",
    airport: "Toncontín International Airport",
    country: "Honduras",
    iata: "TGU",
  },
  {
    city: "Tehran",
    airport: "Imam Khomeini International Airport",
    country: "Iran",
    iata: "IKA",
  },
  {
    city: "Tehran",
    airport: "Mehrabad International Airport",
    country: "Iran",
    iata: "THR",
  },
  {
    city: "Tehuacán",
    airport: "Tehuacán National Airport",
    country: "Mexico",
    iata: "TCN",
  },
  {
    city: "Tekirdag",
    airport: "Tekirdag Corlu Airport",
    country: "Turkey",
    iata: "TEQ",
  },
  {
    city: "Tel Aviv",
    airport: "Ben Gurion International",
    country: "Israel",
    iata: "TLV",
  },
  {
    city: "Tel Aviv",
    airport: "Sde Dov Airport  (Dov Hoz Airport)",
    country: "Israel",
    iata: "SDV",
  },
  {
    city: "Telluride (CO)",
    airport: "Telluride Airport  (GA)",
    country: "USA",
    iata: "TEX",
  },
  {
    city: "Temora",
    airport: "Temora Airport (GA)",
    country: "NSW, Australia",
    iata: "TEM",
  },
  {
    city: "Temuco",
    airport: "Temuco La Araucania Airport",
    country: "Chile",
    iata: "ZCO",
  },
  {
    city: "Tenerife (island)",
    airport: "Metropolitan Area",
    country: "Canary Islands, Spain",
    iata: "TCI",
  },
  {
    city: "Tengchong",
    airport: "Tengchong Tuofeng Airport",
    country: "Yunnan, China",
    iata: "TCZ",
  },
  {
    city: "Tennant Creek",
    airport: "Tennant Creek Airport",
    country: "NT, Australia",
    iata: "TCA",
  },
  {
    city: "Tepic",
    airport: "Tepic International Airport",
    country: "Nayarit, Mexico",
    iata: "TPQ",
  },
  {
    city: "Terceira (island)",
    airport: "Lajes Field",
    country: "Azores, Portugal",
    iata: "TER",
  },
  {
    city: "Teresina",
    airport: "Teresina Airport",
    country: "Piauí, Brazil",
    iata: "THE",
  },
  {
    city: "Termez (Termes)",
    airport: "Termez Airport",
    country: "Uzbekistan",
    iata: "TMZ",
  },
  {
    city: "Ternopil",
    airport: "Ternopil Airport",
    country: "Ukraine",
    iata: "TNL",
  },
  {
    city: "Terrace",
    airport: "Northwest Regional Airport",
    country: "BC, Canada",
    iata: "YXT",
  },
  {
    city: "Terre Haute (IN)",
    airport: "Terre Haute International Airport  (GA)",
    country: "USA",
    iata: "HUF",
  },
  {
    city: "Teterboro ",
    airport: "Teterboro Airport  (GA)",
    country: "USA",
    iata: "TEB",
  },
  {
    city: "Tetouan (Tétouan)",
    airport: "Sania Ramel Airport",
    country: "Morocco",
    iata: "TTU",
  },
  {
    city: "Texarkana ",
    airport: "Texarkana Regional Airport",
    country: "USA",
    iata: "TXK",
  },
  {
    city: "Tezpur",
    airport: "Tezpur Airport",
    country: "Assam, India",
    iata: "TEZ",
  },
  {
    city: "Tezu",
    airport: "Tezu Airport (no scheduled service)",
    country: "Arunachal Pradesh, India",
    iata: "TEI",
  },
  {
    city: "Thaba'Nchu",
    airport: " Thaba Nchu Airport (not in use)",
    country: "Free State, South Africa",
    iata: "TCU",
  },
  {
    city: "The Pas",
    airport: "The Pas Airport",
    country: "Manitoba, Canada",
    iata: "YQD",
  },
  {
    city: "Thief River Falls (MN)",
    airport: "Thief River Falls Regional Airport",
    country: "USA",
    iata: "TVF",
  },
  {
    city: "Thira (island)",
    airport: "Santorini National Airport",
    country: "Greece",
    iata: "JTR",
  },
  {
    city: "Thiruvananthapuram",
    airport: "Trivandrum International Airport",
    country: "Kerala, India",
    iata: "TRV",
  },
  {
    city: "Thisted",
    airport: "Thisted Airport (GA)",
    country: "Denmark",
    iata: "TED",
  },
  {
    city: "Thompson",
    airport: "Thompson Airport",
    country: "Manitoba, Canada",
    iata: "YTH",
  },
  {
    city: "Thorne Bay (AK)",
    airport: "Thorne Bay Seaplane Base",
    country: "USA",
    iata: "KTB",
  },
  {
    city: "Thunder Bay",
    airport: "Thunder Bay Airport",
    country: "Canada",
    iata: "YQT",
  },
  {
    city: "Tianjin",
    airport: " Tianjin Binhai International Airport  (cn)",
    country: "China",
    iata: "TSN",
  },
  {
    city: "Tijuana",
    airport: "Tijuana International Airport",
    country: "Mexico",
    iata: "TIJ",
  },
  {
    city: "Timaru",
    airport: "Richard Pearse Airport",
    country: "New Zealand",
    iata: "TIU",
  },
  {
    city: "Timbuktu",
    airport: "Timbuktu Airport (services suspended)",
    country: "Mali",
    iata: "TOM",
  },
  {
    city: "Timika",
    airport: "Timika Airport",
    country: "Papua, Indonesia",
    iata: "TIM",
  },
  {
    city: "Timisoara",
    airport: "Timișoara Airport Traian Vuia ",
    country: "Romania",
    iata: "TSR",
  },
  {
    city: "Tingo Marí",
    airport: "Tingo María Airport",
    country: "Peru",
    iata: "TGI",
  },
  {
    city: "Tinian (island)",
    airport: "Tinian International Airport",
    country: "Northern Mariana Islands",
    iata: "TIQ",
  },
  {
    city: "Tioman",
    airport: "Tioman Airport",
    country: "Indonesia",
    iata: "TOD",
  },
  {
    city: "Tirana",
    airport: "Tirana International Airport Nënë Tereza",
    country: "Albania",
    iata: "TIA",
  },
  {
    city: "Tiree (island)",
    airport: "Tiree Airport",
    country: "Scotland, UK",
    iata: "TRE",
  },
  {
    city: "Tiruchirapally",
    airport: " Tiruchirapalli international Airport",
    country: "Tamil Nadu, India",
    iata: "TRZ",
  },
  {
    city: "Tirupati",
    airport: "Tirupati Airport (Renigunta Airport)",
    country: "Andhra Pradesh, India",
    iata: "TIR",
  },
  {
    city: "Titusville (FL)",
    airport: "Space Coast Regional Airport (GA)",
    country: "USA",
    iata: "TIX",
  },
  {
    city: "Tivat",
    airport: "Tivat Airport",
    country: "Montenegro",
    iata: "TIV",
  },
  {
    city: "Tizimín",
    airport: "Cupul National Airport (no airport)",
    country: "Yucatan, Mexico",
    iata: "TZM",
  },
  {
    city: "Toamasina",
    airport: "Toamasina Airport",
    country: "Madagascar",
    iata: "TMM",
  },
  {
    city: "Tobruk",
    airport: "Tobruk International Airport",
    country: "Libya",
    iata: "TOB",
  },
  {
    city: "Tokushima",
    airport: "Tokushima Airport",
    country: "Japan",
    iata: "TKS",
  },
  {
    city: "Tokyo",
    airport: "Metropolitan Area",
    country: "Japan",
    iata: "TYO",
  },
  {
    city: "Tokyo",
    airport: "Haneda Airport International",
    country: "Japan",
    iata: "HND",
  },
  {
    city: "Toledo (OH)",
    airport: "Toledo Express Airport",
    country: "USA",
    iata: "TOL",
  },
  {
    city: "Toluca (near Mexico City)",
    airport: "Toluca International Airport",
    country: "Mexico State, Mexico",
    iata: "TLC",
  },
  {
    city: "Tom Price",
    airport: "Tom Price Airport (GA)",
    country: "WA, Australia",
    iata: "TPR",
  },
  {
    city: "Tomsk",
    airport: "Airport Tomsk  (ru)",
    country: "Russia",
    iata: "TOF",
  },
  {
    city: "Tonghua",
    airport: "Tonghua Sanyuanpu Airport",
    country: "Jilin, China",
    iata: "TNH",
  },
  {
    city: "Tongren",
    airport: "Tongren Fenghuang Airport",
    country: "Guizhou, China",
    iata: "TEN",
  },
  {
    city: "Toowoomba",
    airport: "Toowoomba Airport",
    country: "Queensland, Australia",
    iata: "TWB",
  },
  {
    city: "Topeka (KS)",
    airport: " Topeka Regional Airport  (Forbes Field)",
    country: "USA",
    iata: "FOE",
  },
  {
    city: "Toronto",
    airport: "Metropolitan Area",
    country: "Ontario, Canada",
    iata: "YTO",
  },
  {
    city: "Toronto",
    airport: "Toronto Pearson International Airport",
    country: "Canada",
    iata: "YYZ",
  },
  {
    city: "Toronto",
    airport: "Toronto/Buttonville Municipal Airport",
    country: "Canada",
    iata: "YKZ",
  },
  {
    city: "Toronto",
    airport: "Toronto Island Airport",
    country: "Canada",
    iata: "YTZ",
  },
  {
    city: "Torreón",
    airport: "Torreón International Airport",
    country: "Coahuila, Mexico",
    iata: "TRC",
  },
  {
    city: "Tortuguero",
    airport: "Tortuguero Airport",
    country: "Costa Rica",
    iata: "TTQ",
  },
  {
    city: "Touho",
    airport: "Touho Airport",
    country: "New Caledonia",
    iata: "TOU",
  },
  {
    city: "Toulon",
    airport: "Toulon-Hyères Airport",
    country: "France",
    iata: "TLN",
  },
  {
    city: "Toulouse",
    airport: "Toulouse Blagnac International Airport",
    country: "France",
    iata: "TLS",
  },
  {
    city: "Tours",
    airport: "Tours Val de Loire Airport  (fr)",
    country: "France",
    iata: "TUF",
  },
  {
    city: "Townsville",
    airport: "Townsville Airport",
    country: "QueenslandAustralia",
    iata: "TSV",
  },
  {
    city: "Toyama",
    airport: "Toyama Kitokito Airport",
    country: "Japan",
    iata: "TOY",
  },
  {
    city: "Trabzon",
    airport: "Trabzon Airport",
    country: "Turkey",
    iata: "TZX",
  },
  {
    city: "Trang",
    airport: " Trang Airport",
    country: "Thailand",
    iata: "TST",
  },
  {
    city: "Trapani",
    airport: "Trapani Airport 'Vincenzo Florio'",
    country: "Sicily, Italy",
    iata: "TPS",
  },
  {
    city: "Trat",
    airport: "Trat Airport",
    country: "Thailand",
    iata: "TDX",
  },
  {
    city: "Traverse City ",
    airport: "Cherry Capital Airport",
    country: "USA",
    iata: "TVC",
  },
  {
    city: "Treasure Cay",
    airport: "Treasure Cay Airport",
    country: "Bahamas",
    iata: "TCB",
  },
  {
    city: "Trenton ",
    airport: "Trenton Mercer Airport",
    country: "USA",
    iata: "TTN",
  },
  {
    city: "Treviso",
    airport: "Treviso Airport",
    country: "Italy",
    iata: "TSF",
  },
  {
    city: "Trieste",
    airport: "Trieste – Friuli Venezia Giulia Airport",
    country: "Italy",
    iata: "TRS",
  },
  {
    city: "Tri-Cities (TN/VA)",
    airport: "Tri-Cities Regional Airport",
    country: "USA",
    iata: "TRI",
  },
  {
    city: "Trincomalee",
    airport: "China Bay Airport",
    country: "Sri Lanka",
    iata: "TRR",
  },
  {
    city: "Trinidad",
    airport: "Jorge Henrich Arauz Airport",
    country: "Bolivia",
    iata: "TDD",
  },
  {
    city: "Tripoli",
    airport: "Tripoli International Airport (not in use)",
    country: "Libya",
    iata: "TIP",
  },
  {
    city: "Tromsoe (Tromsø)",
    airport: "Tromsø Airport",
    country: "Norway",
    iata: "TOS",
  },
  {
    city: "Trondheim",
    airport: "Trondheim Airport, Værnes",
    country: "Norway",
    iata: "TRD",
  },
  {
    city: "Troyes",
    airport: "Troyes – Barberey Airport",
    country: "France",
    iata: "QYR",
  },
  {
    city: "Truckee (CA)",
    airport: "Truckee Tahoe Airport  (GA)",
    country: "USA",
    iata: "TKF",
  },
  {
    city: "Trujillo",
    airport: "Carlos Martínez de Pinillos International",
    country: "Peru",
    iata: "TRU",
  },
  {
    city: "Tsumeb",
    airport: "Tsumeb Airport",
    country: "Namibia",
    iata: "TSB",
  },
  {
    city: "Tucson (AZ)",
    airport: "Tucson International Airport",
    country: "USA",
    iata: "TUS",
  },
  {
    city: "Tucuman",
    airport: "Benj Matienzo International Airport",
    country: "Argentina",
    iata: "TUC",
  },
  {
    city: "Tuguegarao",
    airport: "Tuguegarao Airport",
    country: "Cagayan, Philippines",
    iata: "TUG",
  },
  {
    city: "Tulare (CA)",
    airport: "Mefford Field Airport (GA)",
    country: "USA",
    iata: "TLR",
  },
  {
    city: " Tulcán",
    airport: "Luis a Mantilla International Airport",
    country: "Ecuador",
    iata: "TUA",
  },
  {
    city: "Tullahoma (TN)",
    airport: "Tullahoma Regional Airport",
    country: "USA",
    iata: "THA",
  },
  {
    city: "Tupelo (MS)",
    airport: "Tupelo Regional Airport",
    country: "USA",
    iata: "TUP",
  },
  {
    city: "Tulsa (OK)",
    airport: "Tulsa International Airport",
    country: "USA",
    iata: "TUL",
  },
  {
    city: "Tumbes",
    airport: "Pedro Canga Rodríguez Airport",
    country: "Peru",
    iata: "TBP",
  },
  {
    city: "Tunis",
    airport: "Tunis–Carthage International Airport",
    country: "Tunisia",
    iata: "TUN",
  },
  {
    city: "Turbat",
    airport: "Turbat International Airport",
    country: "Pakistan",
    iata: "TUK",
  },
  {
    city: "Turin",
    airport: "Turin International Airport",
    country: "Italy",
    iata: "TRN",
  },
  {
    city: "Turku",
    airport: "Turku Airport",
    country: "Finland",
    iata: "TKU",
  },
  {
    city: "Tuscaloosa ",
    airport: "Tuscaloosa Regional Airport",
    country: "USA",
    iata: "TCL",
  },
  {
    city: "Tuxtla Gutierrez",
    airport: " Tuxtla Gutierrez International Airport",
    country: "Mexico",
    iata: "TGZ",
  },
  {
    city: "Twin Falls (ID)",
    airport: "Magic Valley Regional Airport",
    country: "USA",
    iata: "TWF",
  },
  {
    city: "Tyler ",
    airport: "Tyler Pounds Regional Airport",
    country: "USA",
    iata: "TYR",
  },
  {
    city: "Tyumen",
    airport: "Roshchino International Airport  (ru)",
    country: "Russia",
    iata: "TJM",
  },
  {
    city: "Ua Huka (island)",
    airport: "Ua Huka Airport",
    country: "French Polynesia",
    iata: "UAH",
  },
  {
    city: "Ua Pou (island)",
    airport: "Ua Pou Airport",
    country: "French Polynesia",
    iata: "UAP",
  },
  {
    city: "Ube",
    airport: "Yamaguchi Ube Airport",
    country: "Yamaguchi, Japan",
    iata: "UBJ",
  },
  {
    city: "Uberaba",
    airport: "Uberaba–Mário de Almeida Franco Airport",
    country: "Minas Gerais, Brazil",
    iata: "UBA",
  },
  {
    city: "Uberlandia",
    airport: "Uberlandia Airport César Bombonato",
    country: "Minas Gerais, Brazil",
    iata: "UDI",
  },
  {
    city: "Ubon Ratchathani",
    airport: "Ubon Ratchathani Airport",
    country: "Thailand",
    iata: "UBP",
  },
  {
    city: "Udaipur",
    airport: "Udaipur Dabok Airport",
    country: "Rajasthan, India",
    iata: "UDR",
  },
  {
    city: "Udon Thani",
    airport: "Udon Thani Airport",
    country: "Thailand",
    iata: "UTH",
  },
  {
    city: "Ufa",
    airport: "Ufa International Airport",
    country: "Bashkortostan, Russia",
    iata: "UFA",
  },
  {
    city: "Uherske Hradiste",
    airport: "Kunovice Airport (GA)",
    country: "Czech Republic",
    iata: "UHE",
  },
  {
    city: "Uige",
    airport: "Uíge Airport",
    country: "Angola",
    iata: "UGO",
  },
  {
    city: "Ukhta",
    airport: "Ukhta Airport",
    country: "Komi, Russia",
    iata: "UCT",
  },
  {
    city: "Ukiah (CA)",
    airport: "Ukiah Municipal Airport (GA)",
    country: "USA",
    iata: "UKI",
  },
  {
    city: "Ulaanbaatar",
    airport: "Chinggis Khaan International Airport",
    country: "Mongolia",
    iata: "ULN",
  },
  {
    city: "Ulaangom",
    airport: "Ulaangom Airport",
    country: "Mongolia",
    iata: "ULO",
  },
  {
    city: "Ulanhot",
    airport: "Ulanhot Airport",
    country: "Inner Mongolia, China",
    iata: "HLH",
  },
  {
    city: "Ulan-Ude",
    airport: "Baikal International Airport",
    country: "Russia",
    iata: "UUD",
  },
  {
    city: "Ulei",
    airport: "Ulei Airport",
    country: "Ambrym, Vanuatu",
    iata: "ULB",
  },
  {
    city: "Ulgii (Ölgii)",
    airport: "Ulgii Airport",
    country: "Mongolia",
    iata: "ULG",
  },
  {
    city: "Ulsan",
    airport: "Ulsan Airport",
    country: "South Korea",
    iata: "USN",
  },
  {
    city: "Ulundi",
    airport: "Ulundi Airport",
    country: "South Africa",
    iata: "ULD",
  },
  {
    city: "Ulyanovsk",
    airport: "Ulyanovsk Baratayevka Airport",
    country: "Russia",
    iata: "ULV",
  },
  {
    city: "Ulyanovsk",
    airport: "Ulyanovsk Vostochny Airport (cargo)",
    country: "Russia",
    iata: "ULY",
  },
  {
    city: "Umea (Umeå)",
    airport: "Umeå Airport",
    country: "Sweden",
    iata: "UME",
  },
  {
    city: "Umiat (AK)",
    airport: "Umiat Airport",
    country: "USA",
    iata: "UMT",
  },
  {
    city: "Umiujaq",
    airport: "Umiujaq Airport",
    country: "Canada",
    iata: "YUD",
  },
  {
    city: "Unalakleet (AK)",
    airport: "Unalakleet Airport",
    country: "USA",
    iata: "UNK",
  },
  {
    city: "Union City (TN)",
    airport: "Everett–Stewart Regional Airport  (GA)",
    country: "USA",
    iata: "UCY",
  },
  {
    city: "Union Island",
    airport: "Union Island Airport",
    country: "Saint Vincent and the Grenadines",
    iata: "UNI",
  },
  {
    city: "Unst (Shetland Island)",
    airport: "Baltasound Airport",
    country: "United Kingdom",
    iata: "UNT",
  },
  {
    city: "Upala",
    airport: "Upala Airport",
    country: "Costa Rica",
    iata: "UPL",
  },
  {
    city: "Upernavik",
    airport: "Upernavik Heliport",
    country: "Greenland",
    iata: "JUV",
  },
  {
    city: "Upington",
    airport: "Upington Airport",
    country: "South Africa",
    iata: "UTN",
  },
  {
    city: "Upolu Point (HI)",
    airport: "Upolu Airport",
    country: "USA",
    iata: "UPP",
  },
  {
    city: "Uranium City",
    airport: "Uranium City Airport",
    country: "Saskatchewan, Canada",
    iata: "YBE",
  },
  {
    city: "Uray",
    airport: "Uray Airport",
    country: "Russia",
    iata: "URJ",
  },
  {
    city: "Urgench",
    airport: "Urgench International Airport",
    country: "Uzbekistan",
    iata: "UGC",
  },
  {
    city: "Uriman",
    airport: "(no airport)",
    country: "Venezuela",
    iata: "URM",
  },
  {
    city: "Urmia (Urmieh)",
    airport: "Urmia Airport",
    country: "Iran",
    iata: "OMH",
  },
  {
    city: "Uruapan",
    airport: "Uruapan International Airport",
    country: "Mexico",
    iata: "UPN",
  },
  {
    city: "Urubupunga",
    airport: "Ernesto Pochler Airport",
    country: "Brazil",
    iata: "URB",
  },
  {
    city: "Uruguaiana",
    airport: "Ruben Berta International Airport",
    country: "Brazil",
    iata: "URG",
  },
  {
    city: "Urumqi",
    airport: "Urumqi International Airport  (cn)",
    country: "Xinjiang, China",
    iata: "URC",
  },
  {
    city: "Uruzgan",
    airport: "(no airport)",
    country: "Afghanistan",
    iata: "URZ",
  },
  {
    city: "Usak",
    airport: "Usak Airport",
    country: "Turkey",
    iata: "USQ",
  },
  {
    city: "Ushuaia",
    airport: "Ushuaia Malvinas Argentinas Airport",
    country: "Tierra del Fuego, Argentina",
    iata: "USH",
  },
  {
    city: "Ust-Ilimsk",
    airport: "(in a state of disrepair)",
    country: "Irkutsk Oblast, Russia",
    iata: "UIK",
  },
  {
    city: "Ust-Kut",
    airport: "Ust-Kut Airport",
    country: "Irkutsk Oblast, Russia",
    iata: "UKX",
  },
  {
    city: "Ust-Kuyga",
    airport: "Ust-Kuyga Airport",
    country: "Yakutia, Russia",
    iata: "UKG",
  },
  {
    city: "Utica ",
    airport: "Oneida County Airport (GA)",
    country: "USA",
    iata: "UCA",
  },
  {
    city: "Utila (island)",
    airport: "Útila Airport",
    country: "Honduras",
    iata: "UII",
  },
  {
    city: "Uvalde ",
    airport: "Garner Field (GA)",
    country: "USA",
    iata: "UVA",
  },
  {
    city: "Uummannaq",
    airport: "Uummannaq Heliport",
    country: "Greenland",
    iata: "UMD",
  },
  {
    city: "Uyo",
    airport: "Victor Attah international Airport",
    country: "Nigeria",
    iata: "QUO",
  },
  {
    city: "Uyuni",
    airport: "Uyuni Airport",
    country: "Potosí, Bolivia",
    iata: "UYU",
  },
  {
    city: "Uzhgorod",
    airport: "Uzhhorod International Airport",
    country: "Ukraine",
    iata: "UDJ",
  },
  {
    city: "Uzice",
    airport: "Užice-Ponikve Airport",
    country: "Serbia",
    iata: "UZC",
  },
  {
    city: "Vaasa",
    airport: " Vaasa Airport",
    country: "Finland",
    iata: "VAA",
  },
  {
    city: "Vadsø",
    airport: "Vadsø Airport",
    country: "Norway",
    iata: "VDS",
  },
  {
    city: "Vaexjoe (Växjö)",
    airport: "Växjö Småland Airport",
    country: "Sweden",
    iata: "VXO",
  },
  {
    city: "Vail (CO)",
    airport: "Eagle County Regional Airport",
    country: "USA",
    iata: "EGE",
  },
  {
    city: "Val d'Or",
    airport: "Val-d'Or Airport",
    country: "Quebec, Canada",
    iata: "YVO",
  },
  {
    city: "Valdez (AK)",
    airport: " Valdez Airport (Pioneer Field)",
    country: "USA",
    iata: "VDZ",
  },
  {
    city: "Valdosta (GA)",
    airport: " Valdosta Regional Airport",
    country: "USA",
    iata: "VLD",
  },
  {
    city: "Valence",
    airport: "Valence-Chabeuil Airport (GA)",
    country: "France",
    iata: "VAF",
  },
  {
    city: "Valencia",
    airport: "Valencia Airport",
    country: "Spain",
    iata: "VLC",
  },
  {
    city: "Valencia",
    airport: "Arturo Michelena International Airport",
    country: "Venezuela",
    iata: "VLN",
  },
  {
    city: "Valera",
    airport: "Antonio Nicolás Briceño Airport",
    country: "Venezuela",
    iata: "VLV",
  },
  {
    city: "Valladolid",
    airport: "Valladolid Airport",
    country: "Spain",
    iata: "VLL",
  },
  {
    city: "Valle de la Pascua",
    airport: "Valle de la Pascua Airport",
    country: "Venezuela",
    iata: "VDP",
  },
  {
    city: "Valledupar",
    airport: "Alfonso López Pumarejo Airport",
    country: "Colombia",
    iata: "VUP",
  },
  {
    city: "Valparaiso, (IN)",
    airport: "Porter County Regional Airport (GA)",
    country: "USA",
    iata: "VPZ",
  },
  {
    city: "Valparaiso (Viña del Mar)",
    airport: "Rodelillo Airfield",
    country: "Chile",
    iata: "VAP",
  },
  {
    city: "Valverde (Canary Islands)",
    airport: "El Hierro Airport",
    country: "Spain",
    iata: "VDE",
  },
  {
    city: "Van",
    airport: "Van Ferit Melen Airport",
    country: "Turkey",
    iata: "VAN",
  },
  {
    city: "Vancouver",
    airport: "Vancouver International Airport",
    country: "Canada",
    iata: "YVR",
  },
  {
    city: "Van Nuys (Los Angeles)",
    airport: "Van Nuys Airport  (GA)",
    country: "USA",
    iata: "VNY",
  },
  {
    city: "Varadero",
    airport: "Juan Gualberto Gómez Airport",
    country: "Cuba",
    iata: "VRA",
  },
  {
    city: "Varanasi",
    airport: "Lal Bahadur Shastri Airport",
    country: "Uttar Pradesh, India",
    iata: "VNS",
  },
  {
    city: "Vardo (Vardø)",
    airport: "Vardø Airport, Svartnes",
    country: "Norway",
    iata: "VAW",
  },
  {
    city: "Varginha",
    airport: "Varginha Airport",
    country: "Brazil",
    iata: "VAG",
  },
  {
    city: "Varkaus",
    airport: "Varkaus Airport (terminal is closed)",
    country: "Finland",
    iata: "VRK",
  },
  {
    city: "Varna",
    airport: "Varna Airport",
    country: "Bulgaria",
    iata: "VAR",
  },
  {
    city: "Vasteras (Västerås)",
    airport: "Stockholm-Västerås Airport",
    country: "Sweden",
    iata: "VST",
  },
  {
    city: "Vatry",
    airport: "Châlons Vatry Airport  (Paris Vatry)",
    country: "France",
    iata: "XCR",
  },
  {
    city: "Velikiye Luki (Welikije Luki)",
    airport: "Velikiye Luki Airport",
    country: "Russia",
    iata: "VLU",
  },
  {
    city: "Venice",
    airport: "Venice Marco Polo Airport",
    country: "Italy",
    iata: "VCE",
  },
  {
    city: "Ventspils",
    airport: "Ventspils Airport",
    country: "Latvia",
    iata: "VNT",
  },
  {
    city: "Veracruz",
    airport: "Heriberto Jara International Airport",
    country: "Mexico",
    iata: "VER",
  },
  {
    city: "Vernal (UT)",
    airport: "Vernal Regional Airport (GA)",
    country: "USA",
    iata: "VEL",
  },
  {
    city: "Vero Beach/Ft. Pierce (FL)",
    airport: "Vero Beach Municipal Airport (GA)",
    country: "USA",
    iata: "VRB",
  },
  {
    city: "Verona",
    airport: "Verona Villafranca Airport",
    country: "Italy",
    iata: "VRN",
  },
  {
    city: "Vestmannaeyjar",
    airport: "Vestmannaeyjar Airport",
    country: "Iceland",
    iata: "VEY",
  },
  {
    city: "Vicenza",
    airport: "Vicenza Airport (closed 2008)",
    country: "Italy",
    iata: "VIC",
  },
  {
    city: "Vichadero",
    airport: "Vichadero Airport",
    country: "Uruguay",
    iata: "VCH",
  },
  {
    city: "Victoria",
    airport: "Victoria International Airport",
    country: "BC, Canada",
    iata: "YYJ",
  },
  {
    city: "Victoria ",
    airport: "Victoria Regional Airport",
    country: "USA",
    iata: "VCT",
  },
  {
    city: "Victoria Falls",
    airport: "Victoria Falls Airport",
    country: "Zimbabwe",
    iata: "VFA",
  },
  {
    city: "Victorville (CA)",
    airport: "Southern California Logistics Airport",
    country: "USA",
    iata: "VCV",
  },
  {
    city: "Vidin",
    airport: "Vidin Airfield (inactive)",
    country: "Bulgaria",
    iata: "VID",
  },
  {
    city: "Viedma",
    airport: "Edgardo Castello Airport",
    country: "Río Negro, Argentina",
    iata: "VDM",
  },
  {
    city: "Vienna (Wien, capital city)",
    airport: "Vienna International Airport (Wien-Schwechat)",
    country: "Austria",
    iata: "VIE",
  },
  {
    city: "Vientiane",
    airport: "Wattay International Airport",
    country: "Lao PDR",
    iata: "VTE",
  },
  {
    city: " Vieques (island)",
    airport: "Antonio Rivera Rodriguez Airport",
    country: "Puerto Rico",
    iata: "VQS",
  },
  {
    city: "Vigo",
    airport: "Vigo-Peinador Airport",
    country: "Galicia, Spain",
    iata: "VGO",
  },
  {
    city: "Vijayawada",
    airport: "Vijayawada Airport",
    country: "Andhra Pradesh, India",
    iata: "VGA",
  },
  {
    city: "Vilankulo",
    airport: "Vilankulo Airport",
    country: "Mozambique",
    iata: "VNX",
  },
  {
    city: "Vila Real",
    airport: "Vila Real Airport",
    country: "Portugal",
    iata: "VRL",
  },
  {
    city: "Villa Gesell",
    airport: "Villa Gesell Airport",
    country: "Argentina",
    iata: "VLG",
  },
  {
    city: "Villahermosa",
    airport: "Carlos Rovirosa Pérez Intl Airport",
    country: "Mexico",
    iata: "VSA",
  },
  {
    city: "Vilnius (Wilna)",
    airport: "Vilnius International Airport",
    country: "Lithuania",
    iata: "VNO",
  },
  {
    city: "Vinh",
    airport: "Vinh Airport",
    country: "Vietnam",
    iata: "VII",
  },
  {
    city: "Virac",
    airport: "Virac Airport",
    country: "Catanduanes, Philippines",
    iata: "VRC",
  },
  {
    city: "Virgin Gorda",
    airport: "Virgin Gorda Airport",
    country: "Virgin Islands (UK)",
    iata: "VIJ",
  },
  {
    city: "Visakhapatnam",
    airport: "Visakhapatnam Airport",
    country: "Andhra Pradesh, India",
    iata: "VTZ",
  },
  {
    city: "Visalia (CA)",
    airport: "Visalia Municipal Airport (GA)",
    country: "USA",
    iata: "VIS",
  },
  {
    city: "Visby",
    airport: "Visby Airport",
    country: "Sweden",
    iata: "VBY",
  },
  {
    city: "Vitoria",
    airport: "Eurico de Aguiar Salles Airport",
    country: "Espírito Santo, Brazil",
    iata: "VIX",
  },
  {
    city: "Vitória da Conquista",
    airport: "Vitória da Conquista Airport",
    country: "Bahia, Brazil",
    iata: "VDC",
  },
  {
    city: "Vitoria-Gasteiz",
    airport: "Vitoria Airport (no scheduled service)",
    country: "Spain",
    iata: "VIT",
  },
  {
    city: "Vladivostok",
    airport: "Vladivostok International Airport",
    country: "Russia",
    iata: "VVO",
  },
  {
    city: "Volgograd",
    airport: "Volgograd Airport",
    country: "Russia",
    iata: "VOG",
  },
  {
    city: " Volos",
    airport: "Nea Anchialos National Airport",
    country: "Greece",
    iata: "VOL",
  },
  {
    city: "Voronezh",
    airport: "Airport Voronezh (ru)",
    country: "Russia",
    iata: "VOZ",
  },
  {
    city: "Vryheid",
    airport: "Vryheid Airstrip (GA)",
    country: "KwaZulu-Natal, South Africa",
    iata: "VYD",
  },
  {
    city: "Wabush",
    airport: "Wabush Airport",
    country: "NL, Canada",
    iata: "YWK",
  },
  {
    city: "Waco ",
    airport: "Waco Regional Airport",
    country: "USA",
    iata: "ACT",
  },
  {
    city: "Wadi Halfa",
    airport: "Wadi Halfa Airport",
    country: "Sudan",
    iata: "WHF",
  },
  {
    city: "Wagga Wagga",
    airport: "Wagga Wagga Airport",
    country: "NSW, Australia",
    iata: "WGA",
  },
  {
    city: "Waingapu",
    airport: "Waingapu Airport",
    country: "Sumba, Indonesia",
    iata: "WGP",
  },
  {
    city: "Wakkanai",
    airport: " Wakkanai Airport",
    country: "Hokkaido, Japan",
    iata: "WKJ",
  },
  {
    city: "Walla Walla (WA)",
    airport: "Walla Walla Regional Airport",
    country: "USA",
    iata: "ALW",
  },
  {
    city: "Wallis (island)",
    airport: "Hihifo Airport",
    country: "Wallis and Futuna Islands",
    iata: "WLS",
  },
  {
    city: "Walvis Bay",
    airport: "Walvis Bay Airport",
    country: "Erongo, Namibia",
    iata: "WVB",
  },
  {
    city: "Wanaka",
    airport: "Wanaka Airport (no scheduled service)",
    country: "New Zealand",
    iata: "WKA",
  },
  {
    city: "Wang-an",
    airport: "Wang-an Airport",
    country: "Penghu Islands, Taiwan",
    iata: "WOT",
  },
  {
    city: " Wanzhou (district)",
    airport: "Wanzhou Wuqiao Airport",
    country: "Chongqing, China",
    iata: "WXN",
  },
  {
    city: "Warrnambool",
    airport: "Warrnambool Airport (no scheduled service)",
    country: "Victoria, Australia",
    iata: "WMB",
  },
  {
    city: "Warsaw",
    airport: "Warsaw Chopin Airport",
    country: "Poland",
    iata: "WAW",
  },
  {
    city: "Warsaw",
    airport: "Warsaw–Modlin Mazovia Airport",
    country: "Poland",
    iata: "WMI",
  },
  {
    city: "Washington D.C.",
    airport: "Metropolitan Area",
    country: "USA",
    iata: "WAS",
  },
  {
    city: "Washington D.C.",
    airport: "Washington Dulles International Airport",
    country: "USA",
    iata: "IAD",
  },
  {
    city: "Washington D.C.",
    airport: "Ronald Reagan National Airport",
    country: "USA",
    iata: "DCA",
  },
  {
    city: "Waterford",
    airport: "Waterford Airport",
    country: "Ireland",
    iata: "WAT",
  },
  {
    city: "Waterloo (IA)",
    airport: "Waterloo Regional Airport",
    country: "USA",
    iata: "ALO",
  },
  {
    city: "Woolwich",
    airport: "Region of Waterloo International Airport",
    country: "Ontario, Canada",
    iata: "YKF",
  },
  {
    city: "Watertown ",
    airport: "Watertown International Airport",
    country: "USA",
    iata: "ART",
  },
  {
    city: "Watertown (SD)",
    airport: "Watertown Regional Airport",
    country: "USA",
    iata: "ATY",
  },
  {
    city: "Wausau/Stevens Point (WI)",
    airport: "Central Wisconsin Airport",
    country: "USA",
    iata: "CWA",
  },
  {
    city: "Waynesburg (PA)",
    airport: "Greene County Airport (GA)",
    country: "USA",
    iata: "WAY",
  },
  {
    city: "Weifang",
    airport: "Weifang Airport",
    country: "Shandong, China",
    iata: "WEF",
  },
  {
    city: "Weihai",
    airport: "Weihai International Airport  (cn)",
    country: "Shandong, China",
    iata: "WEH",
  },
  {
    city: "Weipa",
    airport: "Weipa Airport",
    country: "Queensland, Australia",
    iata: "WEI",
  },
  {
    city: "Welkom",
    airport: "Welkom Airport (GA)",
    country: "Free State, South Africa",
    iata: "WEL",
  },
  {
    city: "Wellington",
    airport: "Wellington International Airport",
    country: "New Zealand",
    iata: "WLG",
  },
  {
    city: "Wenatchee (WA)",
    airport: "Pangborn Memorial Airport  (GA)",
    country: "USA",
    iata: "EAT",
  },
  {
    city: "Weno (island)",
    airport: "Chuuk International Airport",
    country: "Micronesia",
    iata: "TKK",
  },
  {
    city: "Wenzhou",
    airport: "Wenzhou Longwan International   (cn)",
    country: "Zhejiang, China",
    iata: "WNZ",
  },
  {
    city: "Westerland",
    airport: "Sylt Airport  (de)",
    country: "Germany",
    iata: "GWT",
  },
  {
    city: "West Palm Beach (FL)",
    airport: "Palm Beach International Airport",
    country: "USA",
    iata: "PBI",
  },
  {
    city: "Westport",
    airport: "Westport Airport",
    country: "New Zealand",
    iata: "WSZ",
  },
  {
    city: "West Yellowstone (MT)",
    airport: "Yellowstone Airport",
    country: "USA",
    iata: "WYS",
  },
  {
    city: "Whakatane",
    airport: "Whakatane Airport",
    country: "New Zealand",
    iata: "WHK",
  },
  {
    city: "Whale Cove",
    airport: "Whale Cove Airport",
    country: "Nunavut, Canada",
    iata: "YXN",
  },
  {
    city: "Whanganui",
    airport: "Wanganui Airport",
    country: "New Zealand",
    iata: "WAG",
  },
  {
    city: "Whangarei",
    airport: "Whangarei Airport",
    country: "New Zealand",
    iata: "WRE",
  },
  {
    city: "White Plains ",
    airport: "Westchester County Airport",
    country: "USA",
    iata: "HPN",
  },
  {
    city: "Whitehorse",
    airport: "Erik Nielsen Whitehorse Airport",
    country: "Yukon, Canada",
    iata: "YXY",
  },
  {
    city: "Whiteriver (AZ)",
    airport: "Whiteriver Airport (GA)",
    country: "USA",
    iata: "WTR",
  },
  {
    city: "Whyalla",
    airport: "Whyalla Airport",
    country: "SA, Australia",
    iata: "WYA",
  },
  {
    city: "Wichita Falls ",
    airport: "Wichita Falls Municipal Airport",
    country: "USA",
    iata: "SPS",
  },
  {
    city: "Wichita (KS)",
    airport: "Dwight D. Eisenhower National Airport",
    country: "USA",
    iata: "ICT",
  },
  {
    city: "Wick",
    airport: "Wick Airport",
    country: "Scotland, UK",
    iata: "WIC",
  },
  {
    city: "Wiesbaden",
    airport: "Air Base",
    country: "Hessen, Germany",
    iata: "WIE",
  },
  {
    city: "Wilkes Barre-Scranton (PA)",
    airport: "Wilkes-Barre/Scranton Airport",
    country: "USA",
    iata: "AVP",
  },
  {
    city: "Williams Lake",
    airport: "Williams Lake Airport",
    country: "BC, Canada",
    iata: "YWL",
  },
  {
    city: "Williamsport (PA)",
    airport: "Williamsport Regional Airport",
    country: "USA",
    iata: "IPT",
  },
  {
    city: "Williston (ND)",
    airport: "Sloulin Field Airport",
    country: "USA",
    iata: "ISN",
  },
  {
    city: "Wilmington (NC)",
    airport: "Wilmington International Airport",
    country: "USA",
    iata: "ILM",
  },
  {
    city: "Wiluna",
    airport: "Wiluna Airport",
    country: "WA, Australia",
    iata: "WUN",
  },
  {
    city: "Winchester (VA)",
    airport: "Winchester Regional Airport",
    country: "USA",
    iata: "WGO",
  },
  {
    city: "Windhoek",
    airport: "Metropolitan Area",
    country: "Namibia",
    iata: "WDH",
  },
  {
    city: "Windhoek",
    airport: "Eros Airport",
    country: "Namibia",
    iata: "ERS",
  },
  {
    city: "Windsor",
    airport: "Windsor Airport",
    country: "Ontario, Canada",
    iata: "YQG",
  },
  {
    city: " Winfield (KS)",
    airport: "Strother Field",
    country: "USA",
    iata: "WLD",
  },
  {
    city: "Winnemucca (NV)",
    airport: "Winnemucca Municipal Airport",
    country: "USA",
    iata: "WMC",
  },
  {
    city: "Winnipeg",
    airport: "James Armstrong Richardson International",
    country: "Manitoba, Canada",
    iata: "YWG",
  },
  {
    city: "Wolf Point (MT)",
    airport: "L. M. Clayton Airport (GA)",
    country: "USA",
    iata: "OLF",
  },
  {
    city: "Wollongong",
    airport: "Illawarra Regional Airport",
    country: "NSW, Australia",
    iata: "WOL",
  },
  {
    city: "Wonju",
    airport: "Wonju Airport",
    country: "Gangwon, South Korea",
    iata: "WJU",
  },
  {
    city: "Woodward (OK)",
    airport: "West Woodward Airport (GA)",
    country: "USA",
    iata: "WWR",
  },
  {
    city: "Worcester (MA)",
    airport: "Worcester Regional Airport",
    country: "USA",
    iata: "ORH",
  },
  {
    city: "Worland (WY)",
    airport: "Worland Municipal Airport (GA)",
    country: "USA",
    iata: "WRL",
  },
  {
    city: "Wrangell (AK)",
    airport: "Wrangell Airport",
    country: "USA",
    iata: "WRG",
  },
  {
    city: "Wrigley",
    airport: "Wrigley Airport (GA)",
    country: "NWT, Canada",
    iata: "YWY",
  },
  {
    city: "Wroclaw (Wrocław)",
    airport: "Wrocław - Copernicus Airport",
    country: "Poland",
    iata: "WRO",
  },
  {
    city: "Wuhai",
    airport: "Wuhai Airport",
    country: "Inner Mongolia, China",
    iata: "WUA",
  },
  {
    city: "Wuhan",
    airport: "Wuhan Tianhe International Airport  (cn)",
    country: "Hubei, China",
    iata: "WUH",
  },
  {
    city: "Wuxi, Suzhou",
    airport: "Sunan Shuofang International Airport",
    country: "Jiangsu, China",
    iata: "WUX",
  },
  {
    city: "Wuzhou",
    airport: "Wuzhou Changzhoudao Airport",
    country: "Guangxi, China",
    iata: "WUZ",
  },
  {
    city: "Wyndham",
    airport: "Wyndham Airport",
    country: "WA, Australia",
    iata: "WYN",
  },
  {
    city: "Xangongo",
    airport: "Xangongo Airport (airstrip)",
    country: "Cunene, Angola",
    iata: "XGN",
  },
  {
    city: "Xiamen",
    airport: "Xiamen Gaoqi International Airport  (cn)",
    country: "Fujian, China",
    iata: "XMN",
  },
  {
    city: "Xiangyang",
    airport: "Xiangyang Airport",
    country: "Hubei, China",
    iata: "XFN",
  },
  {
    city: "Xi'an - Xianyang",
    airport: "Xi'an Xianyang International Airport",
    country: "Shaanxi, China",
    iata: "XIY",
  },
  {
    city: "Xieng Khouang (Phonsavan)",
    airport: "Xieng Khouang Airport",
    country: "Laos",
    iata: "XKH",
  },
  {
    city: " Xilinhot",
    airport: "Xilinhot Airport",
    country: "Inner Mongolia, China",
    iata: "XIL",
  },
  {
    city: "Xinguara",
    airport: "Xinguara Airstrip",
    country: "Pará, Brazil",
    iata: "XIG",
  },
  {
    city: "Xingtai",
    airport: "Xingtai Dalian Airport",
    country: "Hebei, China",
    iata: "XNT",
  },
  {
    city: "Xining",
    airport: " Xining Caojiabu Airport",
    country: "Qinghai, China",
    iata: "XNN",
  },
  {
    city: "Xuzhou",
    airport: "Xuzhou Guanyin Airport",
    country: "Jiangsu, China",
    iata: "XUZ",
  },
  {
    city: "Yakima (WA)",
    airport: "Yakima Air Terminal",
    country: "USA",
    iata: "YKM",
  },
  {
    city: "Yakutat (AK)",
    airport: "Yakutat Airport",
    country: "USA",
    iata: "YAK",
  },
  {
    city: "Yakutsk",
    airport: "Yakutsk Airport",
    country: "Russia",
    iata: "YKS",
  },
  {
    city: "Yamagata",
    airport: "Yamagata Airport",
    country: "Japan",
    iata: "GAJ",
  },
  {
    city: "Yamoussoukro",
    airport: "Yamoussoukro Airport",
    country: "Côte d'Ivoire",
    iata: "ASK",
  },
  {
    city: "Yanbu (Yenbo)",
    airport: "Yanbu Airport",
    country: "Saudi Arabia",
    iata: "YNB",
  },
  {
    city: "Yancheng",
    airport: "Yancheng Nanyang Airport  (cn)",
    country: "Jiangsu, China",
    iata: "YNZ",
  },
  {
    city: "Yangzhou",
    airport: "Yangzhou Taizhou Airport",
    country: "Jiangsu, China",
    iata: "YTY",
  },
  {
    city: "Yanji",
    airport: "Yanji Chaoyangchuan Airport",
    country: "Jilin, China",
    iata: "YNJ",
  },
  {
    city: "Yankton (SD)",
    airport: " Chan Gurney Municipal Airport (GA)",
    country: "USA",
    iata: "YKN",
  },
  {
    city: "Yantai",
    airport: "Yantai Laishan International Airport",
    country: " Shandong, China",
    iata: "YNT",
  },
  {
    city: "Yaounde",
    airport: "Metropolitan Area",
    country: "Cameroon",
    iata: "YAO",
  },
  {
    city: "Yaounde",
    airport: "Yaoundé Nsimalen International Airport",
    country: "Cameroon",
    iata: "NSI",
  },
  {
    city: "Yap (island)",
    airport: "Yap International Airport",
    country: "Micronesia",
    iata: "YAP",
  },
  {
    city: "Yarmouth",
    airport: "Yarmouth International Airport",
    country: "Nova Scotia, Canada",
    iata: "YQI",
  },
  {
    city: "Yasawa (island)",
    airport: "Yasawa Island Airport",
    country: " Fiji",
    iata: "YAS",
  },
  {
    city: "Yellowknife",
    airport: "Yellowknife Airport",
    country: "NWT, Canada",
    iata: "YZF",
  },
  {
    city: "Yenisehir (Bursa)",
    airport: "Bursa Yenişehir Airport",
    country: "Turkey",
    iata: "YEI",
  },
  {
    city: "Yibin",
    airport: "Yibin Caiba Airport",
    country: " Sichuan, China",
    iata: "YBP",
  },
  {
    city: "Yichang",
    airport: "Yichang Sanxia Airport",
    country: "Hubei, China",
    iata: "YIH",
  },
  {
    city: "Yichun",
    airport: "Yichun Mingyueshan Airport",
    country: "Jiangxi, China",
    iata: "YIC",
  },
  {
    city: "Yinchuan",
    airport: "Yinchuan Hedong International Airport",
    country: "Ningxia, China",
    iata: "INC",
  },
  {
    city: "Yining",
    airport: "Yining Airport",
    country: "Xinjiang, China",
    iata: "YIN",
  },
  {
    city: "Yiwu",
    airport: " Yiwu Airport",
    country: "Zhejiang, China",
    iata: "YIW",
  },
  {
    city: "Yogyakarta",
    airport: "Adisucipto International Airport",
    country: "Java, Indonesia",
    iata: "JOG",
  },
  {
    city: "Yokohama",
    airport: "Tokyo Haneda Airport",
    country: "Japan",
    iata: "YOK",
  },
  {
    city: "Yola",
    airport: "Yola Airport",
    country: "Nigeria",
    iata: "YOL",
  },
  {
    city: "Yonago",
    airport: "Yonago Kitaro Airport",
    country: "Tottori, Japan",
    iata: "YGJ",
  },
  {
    city: "Youngstown (OH)",
    airport: "Youngstown-Warren Regional Airport",
    country: "USA",
    iata: "YNG",
  },
  {
    city: "Yuma (AZ)",
    airport: "Yuma International Airport",
    country: "USA",
    iata: "YUM",
  },
  {
    city: "Yuncheng",
    airport: "Yuncheng Guangong Airport  (cn)",
    country: "Shanxi, China",
    iata: "YCU",
  },
  {
    city: "Yurimaguas",
    airport: "Yurimaguas Airport",
    country: " Loreto, Peru",
    iata: "YMS",
  },
  {
    city: "Yushu",
    airport: "Yushu Batang Airport",
    country: "Qinghai, China",
    iata: "YUS",
  },
  {
    city: "Yuzno-Sakhalinsk",
    airport: "Yuzno-Sakhalinsk Airport",
    country: "Sakhalin, Russia",
    iata: "UUS",
  },
  {
    city: "Zabljak",
    airport: "Žabljak Airport (GA)",
    country: "Montenegro",
    iata: "ZBK",
  },
  {
    city: "Zacatecas",
    airport: "Leobardo C. Ruiz International Airport",
    country: "Mexico",
    iata: "ZCL",
  },
  {
    city: "Zadar",
    airport: "Zadar Airport",
    country: "Croatia (Hrvatska)",
    iata: "ZAD",
  },
  {
    city: "Zagreb",
    airport: "Zagreb International Airport",
    country: "Croatia (Hrvatska)",
    iata: "ZAG",
  },
  {
    city: "Zahedan",
    airport: "Zahedan Airport",
    country: "Iran",
    iata: "ZAH",
  },
  {
    city: "Zakynthos (island)",
    airport: "Zakynthos International Airport",
    country: "Greece",
    iata: "ZTH",
  },
  {
    city: "Zamboanga City",
    airport: "Zamboanga International Airport",
    country: "Mindanao, Philippines",
    iata: "ZAM",
  },
  {
    city: "Zanzibar City",
    airport: "Zanzibar Karume International Airport",
    country: "Tanzania",
    iata: "ZNZ",
  },
  {
    city: "Zaqatala",
    airport: "Zaqatala International Airport",
    country: "Azerbaijan",
    iata: "ZTU",
  },
  {
    city: "Zaragoza",
    airport: "Zaragoza Airport",
    country: "Spain",
    iata: "ZAZ",
  },
  {
    city: "Zaranj",
    airport: "Zaranj Airport",
    country: "Afghanistan",
    iata: "ZAJ",
  },
  {
    city: "Zaria",
    airport: "Zaria Airport",
    country: "Kaduna, Nigeria",
    iata: "ZAR",
  },
  {
    city: "Zhangjiakou",
    airport: "Zhangjiakou Ningyuan Airport",
    country: "Hebei, China",
    iata: "ZQZ",
  },
  {
    city: " Zhangye",
    airport: "Zhangye Ganzhou Airport",
    country: "Gansu, China",
    iata: "YZY",
  },
  {
    city: " Zhanjiang",
    airport: "Zhanjiang Airport",
    country: "Guangdong, China",
    iata: "ZHA",
  },
  {
    city: "Zhaotong",
    airport: "Zhaotong Airport",
    country: "Yunnan, China",
    iata: "ZAT",
  },
  {
    city: "Zhob",
    airport: "Zhob Airport",
    country: "Balochistan, Pakistan",
    iata: "PZH",
  },
  {
    city: "Zhongwei",
    airport: "Zhongwei Xiangshan Airport",
    country: "Ningxia, China",
    iata: "ZHY",
  },
  {
    city: "Zhoushan",
    airport: "Zhoushan Putuoshan Airport",
    country: "Zhejiang, China",
    iata: "HSN",
  },
  {
    city: "Zhuhai",
    airport: "Zhuhai Airport",
    country: "Guangdong, China",
    iata: "ZUH",
  },
  {
    city: "Zhytomyr",
    airport: "Zhytomyr Airport (no airline service)",
    country: "Ukraine",
    iata: "ZTR",
  },
  {
    city: "Ziguinchor",
    airport: "Ziguinchor Airport",
    country: "Senegal",
    iata: "ZIG",
  },
  {
    city: "Zinder",
    airport: "Zinder Regional Airport",
    country: "Niger",
    iata: "ZND",
  },
  {
    city: "Ziro (Zero)",
    airport: "Ziro Airport",
    country: "Arunachal Pradesh, India",
    iata: "ZER",
  },
  {
    city: "Zouerate",
    airport: "Tazadit Airport",
    country: "Mauritania",
    iata: "OUZ",
  },
  {
    city: "Zunyi",
    airport: "Zunyi Xinzhou Airport",
    country: "Guizhou, China",
    iata: "ZYI",
  },
  {
    city: "Zurich (Zürich)",
    airport: "Zürich Airport (Kloten)",
    country: "Switzerland",
    iata: "ZRH",
  },
];
